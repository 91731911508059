import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

export const loginUser = createAsyncThunk(
  "login",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_V2}login`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const signUpInfo = createAsyncThunk(
  "signUpInfo",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL_V2}signup`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const updateUserChannels = createAsyncThunk(
  "updateUserChannels",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_V2}user/channels`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "getAllUsers",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL_V2}users`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const verifyUser = createAsyncThunk(
  "verifyUser",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_V2}verify`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);
