import { Divider, Grid, Skeleton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { recommendedVideos as getRecommendedVideos } from "../redux/actions/videoActions";
import { InfoRounded } from "@mui/icons-material";

const RecommendedVideoDisplay = ({ id, hoursAgo }) => {
  const dispatch = useDispatch();

  const { recommendedVideos, loading } = useSelector((state) => ({
    recommendedVideos: state.video.recommendedVideos.data,
    loading: state.video.recommendedVideos.loading,
  }));

  useEffect(() => {
    dispatch(getRecommendedVideos({ id, hoursAgo }));
  }, [dispatch, id, hoursAgo]);

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          sx={{
            overflowX: "auto",
          }}
        >
          {loading &&
            Array.from(Array(6)).map((_, index) => (
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={index}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box
                    height={200}
                    width={300}
                    overflow="hidden"
                    borderRadius={2}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="100%"
                      width="100%"
                    />
                  </Box>
                  <Skeleton />
                  <Skeleton />
                </Box>
              </Grid>
            ))}
          {!loading &&
            recommendedVideos?.map((video) => (
              <Link
                to={`https://www.youtube.com${video?.url}`}
                style={{
                  textDecoration: "none",
                  color: "unset",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  backgroundColor="background.card"
                  sx={{
                    display: "flex",
                    overflow: "hidden",
                    gap: 1,
                    justifyContent: "space-between",
                    borderRadius: 2,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                      alignItems: "center",
                    }}
                    p={2}
                  >
                    <img
                      style={{
                        height: 165,
                        width: 300,
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: 5,
                      }}
                      src={video?.thumbnail}
                      alt="video / Video Name"
                    />
                    <Box minWidth={0} pt={1.5} color="text.primary">
                      <Typography
                        maxWidth={300}
                        fontSize="18px"
                        fontWeight="bold"
                        noWrap
                        sx={{ textOverflow: "ellipsis" }}
                      >
                        {video?.title}
                      </Typography>
                    </Box>
                    {video?.count && (
                      <Box display="flex" flexDirection="row" gap={2}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          noWrap
                          sx={{ textOverflow: "ellipsis" }}
                        >
                          <b>Count: </b>
                          {video?.count}
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          noWrap
                          sx={{ textOverflow: "ellipsis" }}
                        >
                          <b>Average position: </b>
                          {1 + video?.averagePosition}
                          {video?.positions && (
                            <Tooltip title={video?.positions?.join(", ")}>
                              <InfoRounded
                                sx={{
                                  mt: 1,
                                  ml: 1,
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            </Tooltip>
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Link>
            ))}
        </Box>
      </Box>
      <Box width="100%" display="flex" flexWrap="wrap" gap={3}></Box>
    </Box>
  );
};

export default RecommendedVideoDisplay;
