import { Skeleton, TablePagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterBar from "../components/FilterBar";
import useVideoSelectFilters from "../constants/videoSelectFilters";
import useFilters from "../hooks/useFilters";
import { videoList } from "../redux/actions/videoActions";
import getImageUrl from "../utils/getImageUrl";

const VideoSelect = ({ handleSelectVideo }) => {
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [page, setPage] = useState(0);

  const filters = useVideoSelectFilters();

  const { activeFilters, inactiveFilters, activeValues, setActiveValues } =
    useFilters(filters);

  const { videos, videosLoading } = useSelector((state) => ({
    videos: state.video.videoList.videos,
    videosLoading: state.video.videoList.loading,
  }));

  useEffect(() => {
    dispatch(
      videoList({
        page,
        rowsPerPage,
        complete: true,
        ...activeValues,
      })
    );
  }, [dispatch, page, rowsPerPage, activeValues, filters]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <FilterBar
        activeFilters={activeFilters}
        inactiveFilters={inactiveFilters}
        activeValues={activeValues}
        setActiveValues={setActiveValues}
      />
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
        maxWidth={1500}
        margin="auto"
        my={4}
      >
        {videosLoading &&
          Array(12)
            .fill(0)
            .map((_, i) => (
              <Skeleton
                key={i}
                variant="rounded"
                sx={{
                  minWidth: 300,
                  flexGrow: 1,
                  height: 250,
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              />
            ))}
        {!videosLoading &&
          videos?.videos.map((video) => (
            <Box
              onClick={(e) => handleSelectVideo(video)}
              to={`/videos/${video?.video_id}`}
              style={{
                textDecoration: "none",
                color: "unset",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                cursor: "pointer",
                maxWidth: 500,
              }}
            >
              <Box
                backgroundColor="background.card"
                sx={{
                  display: "flex",
                  overflow: "hidden",
                  gap: 1,
                  justifyContent: "space-between",
                  borderRadius: 2,
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    alignItems: "center",
                  }}
                  flexGrow={1}
                  p={2}
                >
                  <Box
                    width="100%"
                    height={0}
                    paddingBottom="56.25%"
                    borderRadius={2}
                    overflow="hidden"
                  >
                    <img
                      style={{
                        width: "100%",
                      }}
                      src={getImageUrl(video?.thumbnail)}
                      alt="video / Video Name"
                    />
                  </Box>
                  <Box width="100%" pt={1.5} color="text.primary">
                    <Typography
                      maxWidth={300}
                      fontSize="18px"
                      fontWeight="bold"
                      noWrap
                      sx={{ textOverflow: "ellipsis" }}
                    >
                      {video?.title}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{ textOverflow: "ellipsis" }}
                      maxWidth={300}
                    >
                      {video?.description || "No description"}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{ textOverflow: "ellipsis" }}
                      maxWidth={300}
                    >
                      <b>Views:</b> {video?.views.toLocaleString()}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{ textOverflow: "ellipsis" }}
                      maxWidth={300}
                    >
                      <b>Published at:</b>{" "}
                      {new Date(video?.published_at).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      <Box display="flex" justifyContent="center">
        {true && (
          <TablePagination
            sx={{ margin: "auto" }}
            rowsPerPageOptions={[12]}
            count={videos?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            slotProps={{
              select: {
                "aria-label": "rows per page",
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </Box>
  );
};

export default VideoSelect;
