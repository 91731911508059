import { configureStore } from "@reduxjs/toolkit";
import apiKeySlice from "./apikeySlice";
import channelSlice from "./channelSlice";
import filterSlice from "./filterSlice";
import generateVoiceSlice from "./generateVoiceSlice";
import userSlice from "./userSlice";
import videoSlice from "./videoSlice";

const store = configureStore({
  reducer: {
    channel: channelSlice,
    video: videoSlice,
    user: userSlice,
    apikey: apiKeySlice,
    voice: generateVoiceSlice,
    filters: filterSlice,
  },
});

export default store;
