import {
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  subDays,
  subHours,
  subMonths,
} from "date-fns";

export const previousWeek = {
  startDate: subDays(new Date(), 14),
  endDate: subDays(new Date(), 7),
};
export const previous30days = {
  startDate: subDays(new Date(), 60),
  endDate: subDays(new Date(), 30),
};
export const previous60days = {
  startDate: subDays(new Date(), 120),
  endDate: subDays(new Date(), 60),
};
export const previousMonth = {
  startDate: startOfMonth(subMonths(new Date(), 2)),
  endDate: endOfMonth(subMonths(new Date(), 2)),
};
export const previous3MonthsPeriod = {
  startDate: startOfMonth(subMonths(new Date(), 3)),
  endDate: endOfMonth(subMonths(new Date(), 1)),
};
export const previous6Months = {
  startDate: endOfMonth(subMonths(new Date(), 12)),
  endDate: startOfMonth(subMonths(new Date(), 6)),
};

export const lastWeek = {
  startDate: subDays(new Date(), 7),
  endDate: new Date(),
};
export const last30Days = {
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
};
export const last60Days = {
  startDate: subDays(new Date(), 60),
  endDate: new Date(),
};
export const lastMonth = {
  startDate: startOfMonth(subMonths(new Date(), 1)),
  endDate: endOfMonth(subMonths(new Date(), 1)),
};
export const last3Months = {
  startDate: startOfMonth(subMonths(new Date(), 3)),
  endDate: endOfMonth(subMonths(new Date(), 1)),
};
export const last6Months = {
  startDate: startOfMonth(subMonths(new Date(), 6)),
  endDate: endOfMonth(subMonths(new Date(), 1)),
};

export const today = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
};

export const yesterday = {
  startDate: startOfDay(subDays(new Date(), 1)),
  endDate: endOfDay(subDays(new Date(), 1)),
};

export const sameDayLastWeek = {
  startDate: startOfDay(subDays(new Date(), 7)),
  endDate: endOfDay(subDays(new Date(), 7)),
};

export const yesterdayLastWeek = {
  startDate: startOfDay(subDays(new Date(), 8)),
  endDate: endOfDay(subDays(new Date(), 8)),
};

export const sameDay2WeeksAgo = {
  startDate: startOfDay(subDays(new Date(), 14)),
  endDate: endOfDay(subDays(new Date(), 14)),
};

export const lastHour = {
  startDate: subHours(new Date(), 1),
  endDate: new Date(),
};

export const last2Hours = {
  startDate: subHours(new Date(), 2),
  endDate: new Date(),
};

export const last5Hours = {
  startDate: subHours(new Date(), 5),
  endDate: new Date(),
};

export const last12Hours = {
  startDate: subHours(new Date(), 12),
  endDate: new Date(),
};

export const lastDay = {
  startDate: subDays(new Date(), 1),
  endDate: new Date(),
};

const timeRanges = {
  previousWeek,
  previous30days,
  previous60days,
  previousMonth,
  previous3MonthsPeriod,
  previous6Months,
  lastWeek,
  last30Days,
  last60Days,
  lastMonth,
  last3Months,
  last6Months,
  today,
  yesterday,
  sameDayLastWeek,
  sameDay2WeeksAgo,
  lastHour,
  last2Hours,
  last5Hours,
  last12Hours,
  lastDay,
};

export default timeRanges;
