import { createSlice } from "@reduxjs/toolkit";
import { generateVoice, listVoice } from "./actions/generateVoiceActions";

const voiceSlice = createSlice({
  name: "voice",
  initialState: {
    generateVoice: {
      loading: false,
      voice: null,
    },
    list: {
      loading: false,
      data: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateVoice.pending, (state) => {
      state.generateVoice.loading = true;
    });
    builder.addCase(generateVoice.fulfilled, (state, action) => {
      state.generateVoice.loading = false;
      state.generateVoice.voice = action.payload;
    });
    builder.addCase(generateVoice.rejected, (state) => {
      state.generateVoice.loading = false;
    });
    builder.addCase(listVoice.pending, (state) => {
      state.list.loading = true;
    });
    builder.addCase(listVoice.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.data = action.payload;
    });
    builder.addCase(listVoice.rejected, (state) => {
      state.list.loading = false;
    });
  },
});

export default voiceSlice.reducer;