import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { videoTableHeaders } from "../components/VideoRowDisplay";
import { allChannels } from "../redux/actions/channelActions";

const staticFilters = [
  {
    label: "Channel",
    value: "channelId",
    type: "select",
    priority: 1,
    required: true,
    defaultValue: "",
    options: [
      {
        value: "",
        label: "All",
      },
    ],
  },
  {
    label: "Include word in title",
    value: "includeTitle",
    type: "text",
  },
  {
    required: true,
    label: "Sort by",
    value: "orderBy",
    type: "select",
    options: videoTableHeaders
      .filter((h) => h.sortable)
      .map((h) => ({
        value: h.id,
        label: h.label,
      })),
  },
  {
    required: { orderBy: true },
    label: "Sort direction",
    value: "sortDirection",
    type: "select",
    options: [
      { value: "desc", label: "↓" },
      { value: "asc", label: "↑" },
    ],
  },
];

const useVideoSelectFilters = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(staticFilters);

  const { channels } = useSelector((state) => ({
    channels: state.channel.allChannels.channels,
  }));

  useEffect(() => {
    dispatch(allChannels());
  }, [dispatch]);

  useEffect(() => {
    if (channels) {
      const newFilters = [...filters];
      const channelFilter = newFilters.find((f) => f.value === "channelId");
      channelFilter.options = [
        {
          value: "",
          label: "All",
        },
        ...channels.map((c) => ({
          value: c.channel_id,
          label: c.name,
        })),
      ];
      setFilters(newFilters);
    }
  }, [channels]); // eslint-disable-line react-hooks/exhaustive-deps

  return filters;
};

export default useVideoSelectFilters;
