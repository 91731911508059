import { videoTableHeaders } from "../components/VideoRowDisplay";

const videoFilters = [
  {
    required: true,
    label: "Type",
    value: "videoType",
    type: "select",
    options: [
      { value: "videos", label: "videos" },
      { value: "shorts", label: "shorts" },
    ],
  },
  {
    required: true,
    label: "Sort by",
    value: "orderBy",
    type: "select",
    options: videoTableHeaders
      .filter((h) => h.sortable)
      .map((h) => ({
        value: h.id,
        label: h.label,
      })),
  },
  {
    required: { orderBy: true },
    label: "Sort direction",
    value: "sortDirection",
    type: "select",
    options: [
      { value: "desc", label: "↓" },
      { value: "asc", label: "↑" },
    ],
  },
  {
    label: "Exclude dead videos",
    value: "excludeDeadVideos",
    type: "boolean",
  },
  {
    label: "Include word in title",
    value: "includeTitle",
    type: "text",
  },
  {
    label: "Include word in transcript",
    value: "includeTranscript",
    type: "text",
  },
];

export default videoFilters;
