import { TablePagination } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterBar from "../components/FilterBar";
import VideoRowDisplay from "../components/VideoRowDisplay";
import videoFilters from "../constants/videosFilters";
import useFilters from "../hooks/useFilters";
import { videoList } from "../redux/actions/videoActions";

const Videos = ({ channelId, channelIdLoading, showSavedFilters = true }) => {
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { activeFilters, inactiveFilters, activeValues, setActiveValues } =
    useFilters(videoFilters);

  const { videos, videosLoading } = useSelector((state) => ({
    videos: state.video.videoList.videos,
    videosLoading: state.video.videoList.loading,
  }));

  const loading = channelIdLoading || videosLoading;

  useEffect(() => {
    dispatch(
      videoList({
        page,
        rowsPerPage,
        channelId,
        ...activeValues,
      })
    );
  }, [dispatch, page, rowsPerPage, activeValues, channelId]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box alignItems="center">
      <FilterBar
        activeFilters={activeFilters}
        inactiveFilters={inactiveFilters}
        activeValues={activeValues}
        setActiveValues={setActiveValues}
        type={showSavedFilters ? "videos" : null}
      />
      <VideoRowDisplay
        videos={videos?.videos}
        loading={loading}
        rowsPerPage={rowsPerPage}
      />
      <Box display="flex" justifyContent="center">
        {!loading && (
          <TablePagination
            sx={{ margin: "auto" }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            count={videos?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            slotProps={{
              select: {
                "aria-label": "rows per page",
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </Box>
  );
};

export default Videos;
