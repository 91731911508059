import { differenceInMinutes } from "date-fns";
import timeRanges from "../constants/timeRanges";

export const sanitizeFilters = (filters) => {
  const sanitizedFilters = Object.keys(filters).reduce((acc, key) => {
    if (!key.toLowerCase().includes("range")) {
      acc[key] = filters[key];
    } else {
      // get key from timeRanges
      const test = timeRanges;
      const timeRangeKey = Object.keys(test).find((rangeKey) => {
        return (
          Math.abs(
            differenceInMinutes(
              test[rangeKey].startDate,
              filters[key][0].startDate
            )
          ) < 10 &&
          Math.abs(
            differenceInMinutes(test[rangeKey].endDate, filters[key][0].endDate)
          ) < 10
        );
      });

      if (timeRangeKey) acc[key] = timeRangeKey;
      else {
        acc[key] = [
          {
            startDate: new Date(filters[key][0].startDate),
            endDate: new Date(filters[key][0].endDate),
          },
        ];
      }
    }
    return acc;
  }, {});
  return sanitizedFilters;
};

export const parseFilters = (filters) => {
  const parsedFilters = Object.keys(filters).reduce((acc, key) => {
    if (!key.toLowerCase().includes("range")) {
      acc[key] = parseInt(filters[key]) || filters[key];
    } else {
      if (typeof filters[key] !== "object") {
        acc[key] = [timeRanges[filters[key]]];
      } else {
        acc[key] = [
          {
            startDate: new Date(filters[key][0].startDate),
            endDate: new Date(filters[key][0].endDate),
          },
        ];
      }
    }
    return acc;
  }, {});
  return parsedFilters;
};
