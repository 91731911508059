import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { getApiUse } from "../redux/actions/apikeyActions";

const Api = () => {
  const dispatch = useDispatch();
  const [daysBack, setDaysBack] = React.useState(0);

  const { apikey } = useSelector((state) => ({
    apikey: state.apikey.use.data,
  }));

  const data = useMemo(
    () =>
      apikey?.byCreatedAt?.map((item) => ({
        name:
          item?.created_at.split("T")[1].split(":")[0] +
          ":" +
          item?.created_at.split("T")[1].split(":")[1],
        value: item?._sum?.cost,
      })) || [],
    [apikey]
  );

  const data2 = useMemo(
    () =>
      apikey?.byKey?.map((item) => ({
        name: item?.name,
        value: item?._sum?.cost,
      })) || [],
    [apikey]
  );

  useEffect(() => {
    dispatch(
      getApiUse({
        daysBack,
      })
    );
  }, [dispatch, daysBack]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="row" gap={2} alignItems="center">
        <Typography variant="h4">API use</Typography>
        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Button onClick={() => setDaysBack(daysBack + 1)}>←</Button>
          {daysBack === -1 && "Tomorrow"}
          {daysBack === 0 && "Today"}
          {daysBack === 1 && "Yesterday"}
          {daysBack > 1 && `${daysBack} days ago`}
          <Button
            onClick={() => setDaysBack(daysBack - 1)}
            disabled={daysBack === -1}
          >
            →
          </Button>
        </Box>
      </Box>
      <Typography variant="h5">Total points consumed</Typography>
      <Typography>
        {apikey?.totalConsumed} out of {apikey?.totalPointsAvailable} available{" "}
        (
        {Math.round(
          (apikey?.totalConsumed / apikey?.totalPointsAvailable) * 100
        )}
        % of available)
      </Typography>
      <Typography variant="h5">By time</Typography>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data}>
          <Bar dataKey="value" fill="#8884d8" />
          <YAxis dataKey="value" />
          <XAxis dataKey="name" />
        </ComposedChart>
      </ResponsiveContainer>
      <Typography variant="h5">By key</Typography>
      {data2?.map((item) => (
        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Typography color="text.secondary" sx={{ whiteSpace: "nowrap" }}>
            {item?.name}
          </Typography>
          <Box
            flexGrow={1}
            width="100%"
            height="10px"
            bgcolor="background.card"
            borderRadius="5px"
            overflow="hidden"
          >
            <Box
              width={`${item?.value / 100}%`}
              height="10px"
              bgcolor="primary.main"
            />
          </Box>
        </Box>
      ))}
      <Typography color="text.secondary">
        ... and {apikey?.unusedKeys?.length} more unused keys
      </Typography>
    </Box>
  );
};

export default Api;
