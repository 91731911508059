import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import ChangeIcon from "./ChangeIcon";
import ChangelogStats from "./ChangelogStats";
import Label from "./Label";
import LoadMoreButton from "./LoadMoreButton";
import Multiselect from "./Multiselect";

const Changelog = ({
  data,
  width,
  showVideoLink,
  changelogLength,
  setChangelogLength,
  setFilterTwoWeeks,
  filterTwoWeeks,
  loading,
}) => {
  const changelogTypes = useMemo(
    () => data && [...new Set(data?.map((change) => change.action))],
    [data]
  );

  const [selectedChangelogTypes, setSelectedChangelogTypes] = useState([]);

  useEffect(() => {
    setSelectedChangelogTypes(changelogTypes || []);
  }, [changelogTypes]);

  return (
    <Box width={width} textAlign="center">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="h6">
          Changelog{" "}
          <Label variant="filled" color={"error"}>
            {data?.length}
          </Label>
        </Typography>
        {changelogTypes && (
          <Box display="flex" flexDirection="row" alignItems="center">
            {setFilterTwoWeeks && (
              <FormControl sx={{ height: 30, width: 300, marginRight: 2 }}>
                <InputLabel
                  sx={{
                    height: 30,
                    mt: "-10px",
                    "&.Mui-focused": {
                      mt: 0,
                    },
                    "&.MuiInputLabel-shrink": {
                      mt: 0,
                    },
                  }}
                >
                  Created at
                </InputLabel>
                <Select
                  value={filterTwoWeeks}
                  onChange={(e) => setFilterTwoWeeks(e.target.value)}
                  sx={{
                    height: 30,
                    width: 300,
                    marginRight: 2,
                    // move dropdown box up
                    "& .MuiSelect-selectMenu": {
                      mt: "-10px",
                    },
                    "& .MuiSelect-select": {
                      py: 0.5,
                    },
                  }}
                  label="Created at"
                >
                  <MenuItem value={null}>Any time</MenuItem>
                  <MenuItem value="after">
                    After 2 weeks from video release
                  </MenuItem>
                  <MenuItem value="before">
                    Before 2 weeks from video release
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            <Multiselect
              sx={{ height: 30 }}
              title="Type"
              values={changelogTypes}
              setSelectedValues={setSelectedChangelogTypes}
              selectedValues={selectedChangelogTypes}
            />
          </Box>
        )}
      </Box>
      {loading &&
        Array(4)
          .fill(0)
          .map(() => (
            <Box
              sx={{
                height: 60,
                width: "100%",
                borderRadius: 2,
                mt: 1,
                overflow: "hidden",
              }}
            >
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </Box>
          ))}
      {data
        ?.slice()
        .filter(
          (change) =>
            !selectedChangelogTypes.length ||
            selectedChangelogTypes.includes(change.action)
        )
        .slice(0, changelogLength)
        .map((change) => (
          <Box
            sx={{
              height: 60,
              width: "100%",
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "background.card",
              borderRadius: 2,
            }}
          >
            <ChangeIcon action={change.action} />
            <ChangelogStats change={change} showVideoLink={showVideoLink} />
          </Box>
        ))}
      {(data?.filter(
        (change) =>
          !selectedChangelogTypes.length ||
          selectedChangelogTypes.includes(change.action)
      ).length > changelogLength ||
        !data) && (
        <LoadMoreButton
          onClick={() => setChangelogLength(changelogLength + 10)}
        />
      )}
    </Box>
  );
};

export default Changelog;
