import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

export const addApiKey = createAsyncThunk("apikey/add", async (params) => {
  const res = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL}apiKey/add`,
    params
  );
  return res.data;
});

export const getCount = createAsyncThunk("apikey/add", async (params) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}apiKey/count`,
    params
  );
  return res.data;
});

export const getApiUse = createAsyncThunk("apikey/use", async (params) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL_V2}apiKeys`,
    { params }
  );
  return res.data;
});
