import React, { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Label from "./Label";

const CustomPromptModal = ({
  modalOpen,
  setModalOpen,
  setClassification,
  error,
  result,
  generateTopicsLoading,
  channelData,
  setError,
  setResult,
  regenerateChannelTopics,
  classification,
  setSnackbarOpen,
}) => {
  const [prompt, setPrompt] = useState(
    "Generate 5 separate words to describe the story. Format the response like this: 'word 1|word 2|word 3|...'"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "900px",
          maxWidth: "90%",
          padding: 5,
          paddingTop: 3,
          overflow: "scroll",
        }}
        backgroundColor="background.paper"
      >
        <Close
          sx={{ float: "right", cursor: "pointer" }}
          onClick={() => setModalOpen(false)}
        />
        <Typography sx={{ mt: 1, mb: 2 }}>
          Type in the classification name: (e.g. "Topics", "Themes","Tags"...)
        </Typography>
        <TextField
          value={classification}
          onChange={(e) => setClassification(e.target.value)}
          sx={{ width: "100%" }}
          multiline
        ></TextField>
        <Typography sx={{ mt: 1, mb: 2 }}>
          Type in the prompt. You need to add what you want the response to be.
          It can be for example:
          <br />
          "Format the response like this: 'word 1|word 2|word 3|...'" or "Answer
          with yes or no" or "Answer with high, nomal, low or none"
        </Typography>
        <TextField
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          sx={{ width: "100%" }}
          rows={3}
          multiline
        ></TextField>
        <Typography sx={{ mt: 1, mb: 2 }}>
          Here's how the prompt will look like:
        </Typography>
        <Typography
          sx={{
            border: "1px solid",
            borderColor: "divider",
            padding: 2,
            borderRadius: 1,
          }}
        >
          <b>
            <i>VIDEO TRANSCRIPT</i>
          </b>
          <br />
          -----------------------
          <br />
          {prompt}
          <br />
          -----------------------
        </Typography>
        {error && (
          <Typography sx={{ mt: 1, mb: 2 }} color="error">
            {error}
          </Typography>
        )}
        {result && (
          <Typography sx={{ mt: 1, mb: 2 }}>
            Test result:
            <br />
            {result.map((r) => (
              <>
                <b>{r?.title}</b>:{" "}
                {r?.properties?.split("|").map((topic) => (
                  <Label
                    key={topic}
                    variant="filled"
                    sx={{ marginRight: 1, marginTop: 1 }}
                  >
                    {topic}
                  </Label>
                ))}
                <br />
              </>
            ))}
          </Typography>
        )}
        {generateTopicsLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={3}
          >
            <CircularProgress sx={{ marginRight: 1 }} />
            <Typography>
              Generating topics for {channelData?.channel?.name}
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="end" marginTop={1}>
            <Button
              sx={{
                color: "text.primary",
                borderColor: "divider",
              }}
              onClick={() =>
                dispatch(
                  regenerateChannelTopics({
                    channelId: channelData?.channel?.channel_id,
                    prompt: prompt,
                    test: true,
                    classification,
                  })
                )
                  .then(unwrapResult)
                  .then((result) => {
                    if (result.error) {
                      setError(result.error);
                    } else {
                      setResult(result);
                    }
                  })
              }
            >
              Test
            </Button>
            <Button
              sx={{
                color: "text.primary",
                borderColor: "divider",
              }}
              variant="outlined"
              onClick={() =>
                dispatch(
                  regenerateChannelTopics({
                    channelId: channelData?.channel?.channel_id,
                    prompt,
                    classification,
                  })
                )
                  .catch((err) => {
                    console.log(err);
                    console.log("TESTTESTTEST");
                  })
                  .then(unwrapResult)
                  .then((result) => {
                    if (result.error) {
                      setError(result.error);
                    } else {
                      setSnackbarOpen(true);
                      setModalOpen(false);
                      navigate(
                        `/channels/${channelData?.channel?.channel_id}/insight/${result}`
                      );
                    }
                  })
              }
            >
              Generate
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CustomPromptModal;
