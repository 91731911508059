import { Add, Close } from "@mui/icons-material";
import { Box, IconButton, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InsightCard from "../components/InsightCard";
import {
  channelInfo,
  channelInsights,
  regenerateChannelTopics,
} from "../redux/actions/channelActions";
import CustomPromptModal from "../components/CustomPromptModal";
import Skeleton from "react-loading-skeleton";

const ChannelInsightsDashboard = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [result, setResult] = useState(null);
  const [classification, setClassification] = useState("Topics");
  const [error, setError] = useState(null);

  const { generateTopicsLoading, channelData } = useSelector((state) => ({
    generateTopicsLoading: state.channel.channelTopicInsights.loading,
    channelData: state.channel.channelInfo.data,
  }));

  useEffect(() => {
    dispatch(channelInsights({ id }));
    dispatch(channelInfo({ id }));
  }, [dispatch, id]);

  const { data, loading } = useSelector((state) => state.channel.insights);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h4">
        {channelData?.channel?.name} insights
      </Typography>
      <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2} mt={2}>
        {loading ? (
          Array(3)
            .fill(0)
            .map((_, index) => (
              <Skeleton height={200} width={300} key={index} />
            ))
        ) : (
          <>
            {data?.map((insight) => (
              <InsightCard insightData={insight} />
            ))}
            <Box
              height={200}
              width={300}
              borderRadius={1}
              bgcolor="background.card"
              p={3}
              color="text.primary"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setModalOpen(true)}
            >
              <Add sx={{ width: 40, height: 40 }} />
            </Box>
          </>
        )}
      </Box>
      <CustomPromptModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        setClassification={setClassification}
        error={error}
        result={result}
        generateTopicsLoading={generateTopicsLoading}
        channelData={channelData}
        setError={setError}
        setResult={setResult}
        regenerateChannelTopics={regenerateChannelTopics}
        classification={classification}
        setSnackbarOpen={setSnackbarOpen}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message="Updated topics for the channel with custom prompt"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default ChannelInsightsDashboard;
