import { InfoRounded } from "@mui/icons-material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const InsightCard = ({ insightData, width, height, ...props }) => {
  return (
    <Link
      to={insightData?.url}
      onClick={props.onClick}
      style={{ textDecoration: "none" }}
      width={width}
      height={height}
    >
      <Box
        borderRadius={1}
        bgcolor="background.card"
        p={3}
        color="text.primary"
        height={200}
        width={300}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={1}
          mb={2}
        >
          <NotificationsActiveIcon />
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
            color="text.primary"
          >
            {insightData?.type}
          </Typography>
          {insightData?.prompt && (
            <Tooltip
              title={`Prompt used to generate this classification: ${insightData?.prompt}`}
            >
              <InfoRounded style={{ height: 15 }} />
            </Tooltip>
          )}
        </Box>
        <Typography
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
          variant="h6"
          lineHeight={1}
          mb={1}
        >
          <b>{insightData?.title}</b>
        </Typography>
        <Typography
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
          color="text.secondary"
        >
          {insightData?.subtitle}
        </Typography>

        {/* {JSON.stringify(insightData)} */}
      </Box>
    </Link>
  );
};

export default InsightCard;
