import { createSlice } from "@reduxjs/toolkit";
import {
  allRecommendedVideos,
  allVideos,
  compareChannelViews,
  compareVideo,
  getClassifications,
  publishDatePerformanceInsights,
  recommendedVideos,
  searchVideos,
  videoChartData,
  videoInfo,
  videoList,
} from "./actions/videoActions";

const videoSlice = createSlice({
  name: "video",
  initialState: {
    allVideos: {
      loading: false,
      actions: null,
    },
    videoList: {
      loading: false,
      actions: null,
    },
    videoInfo: {
      data: null,
      loading: false,
    },
    chartData: {
      data: null,
      loading: false,
    },
    compareVideo: {
      data: null,
      loading: false,
    },
    compareChannelViews: {
      data: null,
      loading: false,
    },
    insights: {
      data: null,
      loading: false,
    },
    searchResults: {
      data: null,
      loading: false,
    },
    recommendedVideos: {
      data: null,
      loading: false,
    },
    allRecommendedVideos: {
      data: null,
      loading: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allVideos.pending, (state) => {
      state.allVideos.loading = true;
    });
    builder.addCase(allVideos.fulfilled, (state, action) => {
      state.allVideos.loading = false;
      state.allVideos.videos = action.payload;
    });
    builder.addCase(allVideos.rejected, (state) => {
      state.allVideos.loading = false;
    });
    builder.addCase(videoList.pending, (state) => {
      state.videoList.loading = true;
    });
    builder.addCase(videoList.fulfilled, (state, action) => {
      state.videoList.loading = false;
      state.videoList.videos = action.payload;
    });
    builder.addCase(videoList.rejected, (state) => {
      state.videoList.loading = false;
    });
    builder.addCase(videoInfo.pending, (state) => {
      state.videoInfo.loading = true;
      state.videoInfo.data = null;
    });
    builder.addCase(videoInfo.fulfilled, (state, action) => {
      state.videoInfo.loading = false;
      state.videoInfo.data = action.payload;
    });
    builder.addCase(videoInfo.rejected, (state) => {
      state.videoInfo.loading = false;
    });
    builder.addCase(videoChartData.pending, (state) => {
      state.chartData.loading = true;
      state.chartData.data = null;
    });
    builder.addCase(videoChartData.fulfilled, (state, action) => {
      state.chartData.loading = false;
      state.chartData.data = action.payload;
    });
    builder.addCase(videoChartData.rejected, (state) => {
      state.chartData.loading = false;
    });
    builder.addCase(compareVideo.pending, (state) => {
      state.compareVideo.loading = true;
      state.compareVideo.data = null;
    });
    builder.addCase(compareVideo.fulfilled, (state, action) => {
      state.compareVideo.loading = false;
      state.compareVideo.data = action.payload;
    });
    builder.addCase(compareVideo.rejected, (state) => {
      state.compareVideo.loading = false;
    });
    builder.addCase(compareChannelViews.pending, (state) => {
      state.compareChannelViews.loading = true;
      state.compareChannelViews.data = null;
    });
    builder.addCase(compareChannelViews.fulfilled, (state, action) => {
      state.compareChannelViews.loading = false;
      state.compareChannelViews.data = action.payload;
    });
    builder.addCase(compareChannelViews.rejected, (state) => {
      state.compareChannelViews.loading = false;
    });
    builder.addCase(publishDatePerformanceInsights.pending, (state) => {
      state.insights.loading = true;
      state.insights.data = null;
    });
    builder.addCase(
      publishDatePerformanceInsights.fulfilled,
      (state, action) => {
        state.insights.loading = false;
        state.insights.data = action.payload;
      }
    );
    builder.addCase(publishDatePerformanceInsights.rejected, (state) => {
      state.insights.loading = true;
    });
    builder.addCase(getClassifications.pending, (state) => {
      state.classifications = null;
    });
    builder.addCase(getClassifications.fulfilled, (state, action) => {
      state.classifications = action.payload;
    });
    builder.addCase(searchVideos.pending, (state) => {
      state.searchResults.loading = true;
      state.searchResults.data = null;
    });
    builder.addCase(searchVideos.fulfilled, (state, action) => {
      state.searchResults.loading = false;
      state.searchResults.data = action.payload;
    });
    builder.addCase(searchVideos.rejected, (state) => {
      state.searchResults.loading = false;
    });
    builder.addCase(recommendedVideos.pending, (state) => {
      state.recommendedVideos.loading = true;
      state.recommendedVideos.data = null;
    });
    builder.addCase(recommendedVideos.fulfilled, (state, action) => {
      state.recommendedVideos.loading = false;
      state.recommendedVideos.data = action.payload;
    });
    builder.addCase(recommendedVideos.rejected, (state) => {
      state.recommendedVideos.loading = false;
    });
    builder.addCase(allRecommendedVideos.pending, (state) => {
      state.allRecommendedVideos.loading = true;
      state.allRecommendedVideos.data = null;
    });
    builder.addCase(allRecommendedVideos.fulfilled, (state, action) => {
      state.allRecommendedVideos.loading = false;
      state.allRecommendedVideos.data = action.payload;
    });
    builder.addCase(allRecommendedVideos.rejected, (state) => {
      state.allRecommendedVideos.loading = false;
    });
  },
});

export default videoSlice.reducer;
