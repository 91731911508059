import { ApiOutlined, Verified } from "@mui/icons-material";
import InsightsIcon from "@mui/icons-material/Insights";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
export const mainMenuItems = [
  {
    Icon: SubscriptionsIcon,
    name: "Channels",
    url: "/channels",
    pathRegex: /channels/,
  },
  {
    Icon: VideoLibraryIcon,
    name: "Videos",
    url: "/videos",
    pathRegex: /videos/,
  },
  {
    Icon: InsightsIcon,
    name: "Insights",
    url: "/insights",
    pathRegex: /insights/,
  },
  {
    condition: () => localStorage.getItem("user") !== "admin@zvoid.com",
    Icon: ApiOutlined,
    name: "API",
    url: "/api",
    pathRegex: /api/,
  },
  {
    condition: () => localStorage.getItem("user") !== "admin@zvoid.com",
    Icon: Verified,
    name: "Verify users",
    url: "/verify",
    pathRegex: /verify/,
  },
  // {
  //   Icon: GraphicEqIcon,
  //   name: "Generate voice",
  //   url: "/generate-voice",
  //   pathRegex: /generate-voice/,
  // },
];
