import { differenceInMinutes } from "date-fns";
import { useCallback, useMemo } from "react";

export const useVideoData = ({
  range,
  chartData,
  selectedGraph,
  compareVideoData,
  selectedCompare,
  compareChannelViews,
  startDate,
  movingAverage,
}) => {
  const getDataChart = useCallback((chartData) => chartData?.chart.data, []);

  const xData = useMemo(() => chartData?.chart.labels, [chartData]);

  const cumulativeYDataRegular = useMemo(
    () => chartData?.chart.cumulative_chart_data,
    [chartData]
  );

  const cumulativeYDataAveraged = useMemo(
    () => chartData?.chart.averaged_cumulative_chart_data,
    [chartData]
  );

  const yData = useMemo(
    () => getDataChart(chartData),
    [chartData, getDataChart]
  );

  const compareYData = useMemo(() => {
    const skippedIntervalsSinceStart =
      Math.max(differenceInMinutes(range?.[0].startDate, startDate), 0) / 10;

    if (
      ["average_video_compare", "same_day_video_compare"].includes(
        selectedCompare
      )
    ) {
      return getDataChart(compareChannelViews)?.slice(
        skippedIntervalsSinceStart
      );
    } else if (selectedCompare === "video_compare") {
      return compareVideoData?.chart.data?.slice(skippedIntervalsSinceStart);
    } else if (selectedCompare === "moving_average") {
      if (!xData) return;
      const result = [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ];

      const count = movingAverage / 10;

      for (
        let i = count;
        i < getDataChart(chartData, selectedGraph).length;
        i++
      ) {
        let sum = 0;
        for (let j = 0; j < count; j++) {
          sum += parseInt(getDataChart(chartData, selectedGraph)[i - j]);
        }
        result.push(`${Math.round(sum / count)}`);
      }
      return result;
    }
  }, [
    chartData,
    xData,
    compareVideoData,
    compareChannelViews,
    range,
    selectedGraph,
    selectedCompare,
    getDataChart,
    startDate,
    movingAverage,
  ]);

  const lines = chartData?.chart?.lines;

  return {
    xData,
    yData,
    compareYData,
    lines,
    cumulativeYDataAveraged,
    cumulativeYDataRegular,
  };
};
