import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { AreaChart } from "@tremor/react";
import React from "react";
import JsxParser from "react-jsx-parser";
import { useDispatch, useSelector } from "react-redux";
import {
  Area,
  Bar,
  BarChart,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { insightsClassifications } from "../redux/actions/channelActions";

const GPTInsights = () => {
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    dispatch(
      insightsClassifications({
        query: e.target.value,
      })
    );
  };

  const { queryResult, queryResultLoading } = useSelector((state) => ({
    queryResult: state.channel.gptInsights.data,
    queryResultLoading: state.channel.gptInsights.loading,
  }));

  return (
    <Box px={2} display="flex" flexDirection="column" gap={2}>
      <TextField
        fullWidth
        variant="outlined"
        multiline
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit(e);
          }
        }}
        placeholder={`Give me a graph of the views of the video with id "bMwXo-Wgs9M" from the moment it was released until now`}
      />
      {queryResultLoading && <p>Loading...</p>}
      {!queryResultLoading && queryResult && (
        <JsxParser
          renderError={(error) => {
            return <div>{error.toString()}</div>;
          }}
          bindings={{
            tickFormatter: (value) => {
              const date = new Date(value);
              return date.toTimeString().split(" ")[0].slice(0, -3);
            },
          }}
          components={{
            Bar,
            BarChart,
            Area,
            AreaChart,
            Legend,
            Line,
            LineChart,
            ResponsiveContainer,
            Tooltip,
            XAxis,
            YAxis,
            Box,
            Button,
            Table,
            TableBody,
            TableCell,
            TableContainer,
            TableHead,
            TableRow,
            Typography,
          }}
          jsx={queryResult}
        />
      )}
    </Box>
  );
};

export default GPTInsights;
