import { createSlice } from "@reduxjs/toolkit";
import {
  allChannels,
  channelChangelogs,
  channelChartData,
  channelCompare,
  channelCompareShorts,
  channelCompareVideos,
  channelDebugTopicInsights,
  channelInfo,
  channelInsights,
  channelTopicInsights,
  channelsChartData,
  getOwnChannelRecommendations,
  getRecommendedChannels,
  getRecommendedVideos,
  getRequestedChannels,
  insightsClassifications,
  listChannels,
  maxChannels,
  regenerateChannelTopics,
} from "./actions/channelActions";

const channelSlice = createSlice({
  name: "channel",
  initialState: {
    allChannels: {
      loading: false,
      channels: null,
      max: null,
    },
    channelInfo: {
      loading: false,
      data: null,
    },
    changelogs: {
      loading: false,
      data: null,
    },
    channelChartData: {
      loading: false,
      data: null,
    },
    channelWordInsight: {
      loading: false,
      data: null,
    },
    channelDebugClassificationInsight: {
      loading: false,
      data: null,
    },
    channelTopicInsights: {
      loading: false,
      data: null,
    },
    channelCompare: {
      loading: false,
      data: null,
    },
    channelCompareShorts: {
      loading: false,
      data: null,
    },
    channelCompareVideos: {
      loading: false,
      data: null,
    },
    insights: {
      loading: false,
      data: null,
    },
    requestedChannels: {
      loading: false,
      data: null,
    },
    gptInsights: {
      loading: false,
      data: null,
    },
    channelsChartData: {
      loading: false,
      data: null,
    },
    recommendedVideos: {
      data: null,
      loading: false,
    },
    recommendedChannels: {
      data: null,
      loading: false,
    },
    ownChannelRecommendations: {
      data: null,
      loading: false,
    },
  },
  reducers: {
    clearChannelCompare: (state) => {
      state.channelCompare.loading = false;
      state.channelCompare.data = null;
      state.channelCompareShorts.loading = false;
      state.channelCompareShorts.data = null;
      state.channelCompareVideos.loading = false;
      state.channelCompareVideos.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allChannels.pending, (state) => {
      state.allChannels.loading = true;
    });
    builder.addCase(allChannels.fulfilled, (state, action) => {
      state.allChannels.loading = false;
      state.allChannels.channels = action.payload;
    });
    builder.addCase(allChannels.rejected, (state) => {
      state.allChannels.loading = false;
    });
    builder.addCase(listChannels.pending, (state) => {
      state.allChannels.loading = true;
    });
    builder.addCase(listChannels.fulfilled, (state, action) => {
      state.allChannels.loading = false;
      state.allChannels.channels = action.payload;
    });
    builder.addCase(listChannels.rejected, (state) => {
      state.allChannels.loading = false;
    });
    builder.addCase(channelInfo.pending, (state) => {
      state.channelInfo.loading = true;
      state.channelInfo.data = null;
    });
    builder.addCase(channelInfo.fulfilled, (state, action) => {
      state.channelInfo.loading = false;
      state.channelInfo.data = action.payload;
    });
    builder.addCase(channelInfo.rejected, (state) => {
      state.channelInfo.loading = false;
    });
    builder.addCase(channelChartData.pending, (state) => {
      state.channelChartData.loading = true;
      state.channelChartData.data = null;
    });
    builder.addCase(channelChartData.fulfilled, (state, action) => {
      state.channelChartData.loading = false;
      state.channelChartData.data = action.payload;
    });
    builder.addCase(channelChartData.rejected, (state) => {
      state.channelChartData.loading = false;
    });
    builder.addCase(channelTopicInsights.pending, (state) => {
      state.channelTopicInsights.loading = true;
      state.channelTopicInsights.data = null;
    });
    builder.addCase(channelTopicInsights.fulfilled, (state, action) => {
      state.channelTopicInsights.loading = false;
      state.channelTopicInsights.data = action.payload;
    });
    builder.addCase(channelTopicInsights.rejected, (state) => {
      state.channelTopicInsights.loading = false;
    });
    builder.addCase(channelDebugTopicInsights.pending, (state) => {
      state.channelDebugClassificationInsight.loading = true;
      state.channelDebugClassificationInsight.data = null;
    });
    builder.addCase(channelDebugTopicInsights.fulfilled, (state, action) => {
      state.channelDebugClassificationInsight.loading = false;
      state.channelDebugClassificationInsight.data = action.payload;
    });
    builder.addCase(channelDebugTopicInsights.rejected, (state) => {
      state.channelDebugClassificationInsight.loading = false;
    });
    builder.addCase(regenerateChannelTopics.pending, (state) => {
      state.channelTopicInsights.loading = true;
    });
    builder.addCase(regenerateChannelTopics.fulfilled, (state, action) => {
      state.channelTopicInsights.loading = false;
      state.channelTopicInsights.data = action.payload;
    });
    builder.addCase(regenerateChannelTopics.rejected, (state) => {
      state.channelTopicInsights.loading = false;
    });
    builder.addCase(channelChangelogs.pending, (state) => {
      state.changelogs.loading = true;
      state.changelogs.data = null;
    });
    builder.addCase(channelChangelogs.fulfilled, (state, action) => {
      state.changelogs.loading = false;
      state.changelogs.data = action.payload;
    });
    builder.addCase(channelChangelogs.rejected, (state) => {
      state.changelogs.loading = false;
    });
    builder.addCase(maxChannels.pending, (state) => {
      state.allChannels.loading = true;
      state.allChannels.max = null;
    });
    builder.addCase(maxChannels.fulfilled, (state, action) => {
      state.allChannels.loading = false;
      state.allChannels.max = action.payload;
    });
    builder.addCase(maxChannels.rejected, (state) => {
      state.allChannels.loading = false;
    });
    builder.addCase(channelCompare.pending, (state) => {
      state.channelCompare.loading = true;
      state.channelCompare.data = null;
    });
    builder.addCase(channelCompare.fulfilled, (state, action) => {
      state.channelCompare.loading = false;
      state.channelCompare.data = action.payload.data;
    });
    builder.addCase(channelCompare.rejected, (state) => {
      state.channelCompare.loading = false;
    });
    builder.addCase(channelInsights.pending, (state) => {
      state.insights.loading = true;
      state.insights.data = null;
    });
    builder.addCase(channelInsights.fulfilled, (state, action) => {
      state.insights.loading = false;
      state.insights.data = action.payload;
    });
    builder.addCase(channelInsights.rejected, (state) => {
      state.insights.loading = false;
    });
    builder.addCase(channelCompareShorts.pending, (state) => {
      state.channelCompareShorts.loading = true;
      state.channelCompareShorts.data = null;
    });
    builder.addCase(channelCompareShorts.fulfilled, (state, action) => {
      state.channelCompareShorts.loading = false;
      state.channelCompareShorts.data = action.payload.data;
    });
    builder.addCase(channelCompareShorts.rejected, (state) => {
      state.channelCompareShorts.loading = false;
    });
    builder.addCase(channelCompareVideos.pending, (state) => {
      state.channelCompareVideos.loading = true;
      state.channelCompareVideos.data = null;
    });
    builder.addCase(channelCompareVideos.fulfilled, (state, action) => {
      state.channelCompareVideos.loading = false;
      state.channelCompareVideos.data = action.payload.data;
    });
    builder.addCase(channelCompareVideos.rejected, (state) => {
      state.channelCompareVideos.loading = false;
    });
    builder.addCase(getRequestedChannels.pending, (state) => {
      state.requestedChannels.loading = true;
      state.requestedChannels.data = null;
    });
    builder.addCase(getRequestedChannels.fulfilled, (state, action) => {
      state.requestedChannels.loading = false;
      state.requestedChannels.data = action.payload;
    });
    builder.addCase(getRequestedChannels.rejected, (state) => {
      state.requestedChannels.loading = false;
    });
    builder.addCase(insightsClassifications.pending, (state) => {
      state.gptInsights.loading = true;
      state.gptInsights.data = null;
    });
    builder.addCase(insightsClassifications.fulfilled, (state, action) => {
      state.gptInsights.loading = false;
      state.gptInsights.data = action.payload;
    });
    builder.addCase(insightsClassifications.rejected, (state) => {
      state.gptInsights.loading = false;
    });
    builder.addCase(channelsChartData.pending, (state) => {
      state.channelsChartData.loading = true;
      state.channelsChartData.data = null;
    });
    builder.addCase(channelsChartData.fulfilled, (state, action) => {
      state.channelsChartData.loading = false;
      state.channelsChartData.data = action.payload;
    });
    builder.addCase(channelsChartData.rejected, (state) => {
      state.channelsChartData.loading = false;
    });
    builder.addCase(getRecommendedVideos.pending, (state) => {
      state.recommendedVideos.loading = true;
      state.recommendedVideos.data = null;
    });
    builder.addCase(getRecommendedVideos.fulfilled, (state, action) => {
      state.recommendedVideos.loading = false;
      state.recommendedVideos.data = action.payload;
    });
    builder.addCase(getRecommendedVideos.rejected, (state) => {
      state.recommendedVideos.loading = false;
    });
    builder.addCase(getRecommendedChannels.pending, (state) => {
      state.recommendedChannels.loading = true;
      state.recommendedChannels.data = null;
    });
    builder.addCase(getRecommendedChannels.fulfilled, (state, action) => {
      state.recommendedChannels.loading = false;
      state.recommendedChannels.data = action.payload;
    });
    builder.addCase(getRecommendedChannels.rejected, (state) => {
      state.recommendedChannels.loading = false;
    });
    builder.addCase(getOwnChannelRecommendations.pending, (state) => {
      state.ownChannelRecommendations.loading = true;
      state.ownChannelRecommendations.data = null;
    });
    builder.addCase(getOwnChannelRecommendations.fulfilled, (state, action) => {
      state.ownChannelRecommendations.loading = false;
      state.ownChannelRecommendations.data = action.payload;
    });
    builder.addCase(getOwnChannelRecommendations.rejected, (state) => {
      state.ownChannelRecommendations.loading = false;
    });
  },
});

export default channelSlice.reducer;
export const { clearChannelCompare } = channelSlice.actions;
