import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import withPageTitle from "../components/withPageTitle";
import { Channels, Videos } from "../pages";
import Api from "../pages/Api";
import Channel from "../pages/Channel";
import ChannelInsightsDashboard from "../pages/ChannelInsightsDashboard";
import ChannelInsights from "../pages/ChannelTopicInsights";
import ChooseChannel from "../pages/ChooseChannel";
import DebugWordInsights from "../pages/DebugWordInsights";
import GenerateVoice from "../pages/GenerateVoice";
import Insights from "../pages/Insights";
import Verify from "../pages/Verify";
import Video from "../pages/Video";

const Navigation = () => {
  const navigate = useNavigate();
  if (!localStorage.getItem("accessToken")) {
    navigate("/login");
  }
  return (
    <Routes>
      <Route path="/choose-channel" element={<ChooseChannel />} />
      <Route path="/verify" element={<Verify />} />
      <Route
        path="/channels/:id"
        element={withPageTitle("Channel analytics", Channel)}
      />
      <Route
        path="/videos/:id"
        element={withPageTitle("Video analytics", Video)}
      />
      <Route path="/channels" element={<Channels />} />
      <Route path="/generate-voice" element={<GenerateVoice />} />
      <Route path="/channels/:id/wordInsight" element={<ChannelInsights />} />
      <Route
        path="/channels/:id/insights"
        element={<ChannelInsightsDashboard />}
      />
      <Route
        path="/channels/:id/insight/:classification"
        element={<ChannelInsights />}
      />
      <Route
        path="/channels/:id/wordInsight/:word"
        element={<DebugWordInsights />}
      />
      <Route
        path="/channels/:id/insight/:classification/:word"
        element={<DebugWordInsights topic />}
      />
      <Route path="/videos" element={withPageTitle("Videos", Videos)} />
      <Route path="/insights" element={<Insights />} />
      <Route path="/api" element={<Api />} />
      {/* <Route path="/settings" element={withPageTitle("Settings", Settings)} /> */}
      <Route path="*" element={<Navigate to="/channels" replace />} />
    </Routes>
  );
};

export default Navigation;
