import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

export const allChannels = createAsyncThunk("channels/all", async (params) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL_V2}channels`,
    { params }
  );
  return res.data;
});

export const maxChannels = createAsyncThunk("channels/max", async (params) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL_V2}channels/max`,
    { params }
  );
  return res.data;
});

export const channelInfo = createAsyncThunk(
  "channels/getInfo",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/${params.id}/info`,
      { params }
    );
    return res.data;
  }
);

export const channelChangelogs = createAsyncThunk(
  "channels/getChangelogs",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/${params.id}/changelogs`,
      { params }
    );
    return res.data;
  }
);

export const channelChartData = createAsyncThunk(
  "channels/getChartData",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/${params.id}/chartData`,
      { params }
    );
    return res.data;
  }
);

export const getChannelId = createAsyncThunk("channels/add", async (params) => {
  const res = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL_V2}channels`,
    params
  );
  return res.data;
});

export const channelWordInsight = createAsyncThunk(
  "channels/wordInsight",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}insights/channel/words`,
      { params }
    );
    return res.data;
  }
);

export const channelTopicInsights = createAsyncThunk(
  "channels/topicInsights",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}insights/channel/topics`,
      { params }
    );
    return res.data;
  }
);

export const channelInsights = createAsyncThunk(
  "channels/insights",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/insights`,
      { params }
    );
    return res.data;
  }
);

export const channelDebugTopicInsights = createAsyncThunk(
  "channels/debugTopicInsights",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}insights/channel/topics`,
      { params }
    );
    return res.data;
  }
);

export const applyTopics = createAsyncThunk(
  "channels/applyTopics",
  async (params) => {
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL_V2}channels/applyTopics`,
      params
    );
    return res.data;
  }
);

export const regenerateChannelTopics = createAsyncThunk(
  "channels/regenerateTopics",
  async (params) => {
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL_V2}channels/addTopics`,
      params
    );
    return res.data;
  }
);

export const listChannels = createAsyncThunk(
  "channels/listChannels",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/list`,
      { params }
    );
    return res.data;
  }
);

export const addChannel = createAsyncThunk("channels/add", async (params) => {
  const res = await axiosInstance.post(
    `${process.env.REACT_APP_API_URL_V2}channel`,
    params
  );
  return res.data;
});

export const requestChannel = createAsyncThunk(
  "channels/request",
  async (params) => {
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL_V2}channels/request`,
      params
    );
    return res.data;
  }
);

export const getRequestedChannels = createAsyncThunk(
  "channels/getRequested",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/requested`,
      { params }
    );
    return res.data;
  }
);

export const channelCompare = createAsyncThunk(
  "channels/compare",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/${params.id}/chartData`,
      { params }
    );
    return res.data;
  }
);

export const channelCompareShorts = createAsyncThunk(
  "channels/compareShortsAndVideos",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/${params.id}/chartData`,
      { params }
    );
    return res.data;
  }
);

export const channelCompareVideos = createAsyncThunk(
  "channels/compareVideos",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/${params.id}/chartData`,
      { params }
    );
    return res.data;
  }
);

// insights/classifications
export const insightsClassifications = createAsyncThunk(
  "insights/gpt",
  async (params) => {
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL_V2}insights/gpt`,
      params
    );
    return res.data;
  }
);

export const channelsChartData = createAsyncThunk(
  "channels/getChannelsChartData",
  async (params) => {
    try {
      const {
        channels,
        // cancel token
        abortController,
        ...rest
      } = params;

      // const res = await Promise.all(
      //   channels.map((channel) =>
      const res = axiosInstance
        .get(`${process.env.REACT_APP_API_URL_V2}channels/chartData`, {
          params: rest,
          signal: abortController.signal,
        })
        .then((res) => res.data);
      //   )
      // );

      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
    }
  }
);

//  /api/v2/channels/:id/recommended
export const getRecommendedVideos = createAsyncThunk(
  "channels/getRecommendedVideos",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/${params.id}/recommended`,
      { params }
    );
    return res.data;
  }
);

export const getRecommendedChannels = createAsyncThunk(
  "channels/getRecommendedChannels",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/${params.id}/recommended/channels`,
      { params }
    );
    return res.data;
  }
);

export const getOwnChannelRecommendations = createAsyncThunk(
  "channels/getOwnChannelRecommendations",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}channels/${params.id}/recommended/own`,
      { params }
    );
    return res.data;
  }
);
