import {
  Equalizer,
  TrendingUp,
  Psychology,
  VideoLibrary,
} from "@mui/icons-material";

export const insights = [
  {
    key: "performance",
    label: "Videos performance Insights",
    icon: TrendingUp,
    description: "See how your video is performing over time.",
  },
  {
    key: "data_insight",
    label: "Videos data Insights",
    icon: Equalizer,
    description: "See how your videos are performing over time.",
  },
  {
    key: "channel_compare",
    label: "Channel Compare Insights",
    icon: VideoLibrary,
    description: "Compare your channel with other channels.",
  },
  {
    key: "gpt_insight",
    label: "GPT-4 Insights",
    icon: Psychology,
    description: "See how your videos are performing over time.",
    beta: true,
  },
];
