import { useTheme } from "@emotion/react";
import { RemoveRedEye } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Bar,
  BarChart,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const DebugWordInsights = ({ debugWord, location }) => {
  const theme = useTheme();

  const data = debugWord;
  const [tooltipData, setTooltipData] = useState(null);

  const chartData = data?.data?.[0]?.percentages
    .map((percentage) => ({
      name: new Date(percentage.video.published_at).valueOf(),
      label: percentage.data,
    }))
    .sort((a, b) => new Date(a.name) - new Date(b.name));

  const barChartChartData = data?.data?.[0]?.percentages
    .map((percentage, i) => ({
      name:
        percentage.video.title.length > 20
          ? `${percentage.video.title.substring(0, 20)}...`
          : percentage.video.title,
      label: percentage.data,
    }))
    .sort((a, b) => a.label - b.label);

  const handleMouseMove = (e) => {
    setTooltipData({
      x: e?.activeLabel,
      y: e?.activePayload?.[0]?.value?.toLocaleString(),
    });
  };

  return (
    <Box>
      {data?.data?.map((insight) => (
        <Box>
          <Box display="flex" alignItems="center" flexDir="row">
            <Typography marginBottom={2} variant="h4" marginRight={2}>
              {insight.word} {`(${data.displayName} classification)`}
            </Typography>
            <Link to={location}>
              <Typography
                sx={{
                  color: "text.primary",
                  textDecoration: "underline",
                }}
              >
                <b>View on insights</b>
                <RemoveRedEye
                  sx={{
                    height: 20,
                    marginLeft: 1,
                    verticalAlign: "text-bottom",
                  }}
                />
              </Typography>
            </Link>
          </Box>
          <Typography>
            Median views: {insight.medianViews.toLocaleString()}
          </Typography>
          <Typography>
            Average views: {insight.averageViews.toLocaleString()}
          </Typography>
          <Typography>
            Median views increase:{" "}
            {insight.medianPercentageIncrease.toLocaleString()}%
          </Typography>
          <Typography>
            Average views increase:{" "}
            {insight.percentageIncrease.toLocaleString()}%
          </Typography>
          <Box mt={2}>
            <Typography mb={2} variant="h6">
              Performance over time
            </Typography>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={chartData} onMouseMove={handleMouseMove}>
                <XAxis
                  dataKey="name"
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  tickFormatter={(tick) =>
                    format(new Date(tick), "dd-MM-yyy HH:mm")
                  }
                />
                <YAxis
                  tickFormatter={(tick) => `${tick.toFixed(0)}%`}
                  domain={["dataMin", "dataMax"]}
                />
                <Line type="monotone" dataKey="label" stroke="#8884d8" />
                <ReferenceLine x={tooltipData?.x}>
                  <Label
                    color={theme === "dark" ? "white" : "black"}
                    value={`${tooltipData?.y}%`}
                    offset={0}
                    position="middle"
                  />
                </ReferenceLine>
              </LineChart>
            </ResponsiveContainer>
            <Box mt={4} />
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={barChartChartData} onMouseMove={handleMouseMove}>
                <XAxis
                  dataKey="name"
                  minTickGap={-200}
                  axisLine={false}
                  fontSize={10}
                />
                <YAxis />
                <Bar dataKey="label" fill="#8884d8" />
                <Line type="monotone" dataKey="label" stroke="#8884d8" />
                <ReferenceLine
                  style={{ fill: "red" }}
                  y={insight.medianPercentageIncrease}
                  stroke="red"
                  label={
                    <Label
                      value={`Median views increase (${insight.medianPercentageIncrease.toLocaleString()})`}
                      fill={theme.palette.mode === "dark" ? "white" : "black"}
                    />
                  }
                />
                <ReferenceLine
                  style={{ fill: "red" }}
                  y={insight.percentageIncrease}
                  stroke="red"
                  label={
                    <Label
                      value={`Average views increase (${insight.percentageIncrease.toLocaleString()})`}
                      fill={theme.palette.mode === "dark" ? "white" : "black"}
                    />
                  }
                />
                <ReferenceLine x={tooltipData?.x}>
                  <Label
                    color={theme === "dark" ? "white" : "black"}
                    value={`${tooltipData?.y}%`}
                    offset={0}
                    position="middle"
                  />
                </ReferenceLine>
              </BarChart>
            </ResponsiveContainer>
          </Box>
          <Typography marginTop={2} variant="h6">
            Videos that contain the word
          </Typography>
          {insight.percentages.map((percentage) => (
            <>
              <Typography>
                <Box border="1px solid" borderColor="divider" p={2} mt={2}>
                  <Typography>
                    Video:{" "}
                    <Link
                      style={{
                        color:
                          theme.palette.mode === "dark" ? "white" : "black",
                      }}
                      to={`/videos/${percentage.video.id}`}
                    >
                      {percentage.video.title}
                    </Link>
                  </Typography>
                  <Typography>
                    Video views: {percentage.video.views.toLocaleString()}
                  </Typography>
                  <Typography>
                    Percentage: {percentage.data.toLocaleString()}%
                  </Typography>
                  Videos surrounding it:{" "}
                  <Box border="1px solid" borderColor="divider" p={2} mt={2}>
                    {percentage.surroundingVideos.map((video) => (
                      <Typography>
                        {video.title} - {video.views.toLocaleString()} views
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Typography>
            </>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default DebugWordInsights;
