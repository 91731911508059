import axiosInstance from "../../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const generateVoice = createAsyncThunk(
  "voice/generate",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_V2}voices/generate`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const listVoice = createAsyncThunk(
  "voice/list",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL_V2}voices/list`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);
