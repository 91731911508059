import { Close, InfoRounded, InsertLink } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Box,
  Button,
  Modal,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createVideoTitleRecommendations,
  videoInfo,
} from "../redux/actions/videoActions";
import Label from "./Label";

const VideoHeader = ({ videoData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [transcriptModalOpen, setTranscriptModalOpen] = React.useState(false);

  const handleClose = () => {
    setTranscriptModalOpen(false);
  };

  const handleOpen = () => {
    setTranscriptModalOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
        }}
      >
        {videoData && (
          <>
            <iframe
              width="356"
              height="200"
              src={`https://www.youtube.com/embed/${videoData?.video?.video_id}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="h6">
                  {videoData?.video?.title}
                </Typography>
                <Label color="success" text="Success">
                  Published at{" "}
                  {new Date(videoData?.video?.published_at).toLocaleString()}
                </Label>
                {videoData?.video?.status === "unlisted" && (
                  <Label sx={{ marginLeft: 1 }} color="warning" text="Unlisted">
                    <InsertLink sx={{ width: 20, marginRight: 0.5 }} />
                    Unlisted
                  </Label>
                )}
              </Box>
              <Typography sx={{ color: "gray", marginTop: 1 }}>
                Views: {parseInt(videoData?.video?.views).toLocaleString()}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "gray", marginTop: 1 }}>
                  Average percentage increase:{" "}
                  {videoData?.average_percentage_increase?.toFixed(2)}%
                </Typography>
                <Tooltip
                  title="The average percentage increase in views relative to the 10
                    videos surrounding this video"
                >
                  <InfoRounded sx={{ fontSize: 14 }} />
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "gray", marginTop: 1 }}>
                  Median percentage increase:{" "}
                  {videoData?.median_percentage_increase?.toFixed(2)}%
                </Typography>
                <Tooltip
                  title="The median percentage increase in views relative to the 10
                    videos surrounding this video"
                >
                  <InfoRounded sx={{ fontSize: 14 }} />
                </Tooltip>
              </Box>
              {videoData?.classifications?.map((c) => (
                <Typography
                  sx={{ color: "gray", marginTop: 1 }}
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                >
                  <Typography
                    maxWidth={200}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {c.classification_display_name}
                  </Typography>
                  :
                  <Box ml={1}>
                    {/* maximum 5 */}
                    {c.properties
                      ?.split("|")
                      .map((topic) => (
                        <Label
                          key={topic}
                          variant="filled"
                          sx={{ marginRight: 1, marginTop: 1 }}
                        >
                          {topic}
                        </Label>
                      ))
                      .slice(0, 5)}
                  </Box>
                  {c?.properties?.split("|").length > 5 && (
                    <Tooltip
                      title={c?.properties?.split("|").slice(5).join(", ")}
                    >
                      <Typography
                        sx={{
                          color: "gray",
                          marginTop: 1,
                          marginLeft: 1,
                          fontSize: 12,
                        }}
                      >
                        {c?.properties?.split("|").length - 5} more
                      </Typography>
                    </Tooltip>
                  )}
                </Typography>
              ))}
              <Modal open={transcriptModalOpen} onClose={handleClose}>
                <Box
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    height: "90%",
                    border: "2px solid #000",
                    boxShadow: 24,
                  }}
                  p={4}
                  backgroundColor="background.card"
                >
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" component="h2">
                      Video transcript
                    </Typography>
                    <Button onClick={handleClose}>
                      <Close sx={{ color: "text.primary" }} />
                    </Button>
                  </Box>
                  <Box overflow="auto" height="100%">
                    <Typography sx={{ mt: 2, pb: 2, mb: 2 }}>
                      {videoData?.video?.transcript}
                    </Typography>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </>
        )}
        {!videoData && (
          <Skeleton
            width={356}
            height={200}
            style={{ borderRadius: 8, marginRight: 16 }}
          />
        )}
        {!videoData && (
          <Box>
            <Skeleton width={300} height={25} style={{ marginBottom: 10 }} />
            <Skeleton width={50} height={25} />
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={0.5}>
        {videoData?.video?.transcript &&
          videoData?.video?.transcript !== "empty" && (
            <>
              <Button
                sx={{
                  textTransform: "none",
                  color: "text.primary",
                }}
                onClick={() =>
                  dispatch(
                    createVideoTitleRecommendations({
                      videoId: videoData?.video?.video_id,
                    })
                  )
                    .then(unwrapResult)
                    .then(dispatch(videoInfo({ id: videoData?.video?.id })))
                }
              >
                <b>
                  Get title <br /> recommendations
                </b>
              </Button>
              <Button
                onClick={handleOpen}
                sx={{
                  textTransform: "none",
                  color: "text.primary",
                }}
              >
                <b>View transcript</b>
              </Button>
            </>
          )}
        <Button
          target="_blank"
          onClick={() => navigate(`/channels/${videoData?.video?.channel_id}`)}
          sx={{
            textTransform: "none",
            color: "text.primary",
          }}
        >
          <b>View channel</b>
        </Button>
        <Button
          target="_blank"
          href={`https://youtube.com/video/${videoData?.video?.video_id}`}
          sx={{
            textTransform: "none",
            color: "text.primary",
          }}
        >
          <b>View video</b>
          <LaunchIcon sx={{ marginLeft: 1, width: 16 }} />
        </Button>
      </Box>
    </Box>
  );
};

export default VideoHeader;
