import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generateVoice,
  listVoice,
} from "../redux/actions/generateVoiceActions";

const GenerateVoice = () => {
  const dispatch = useDispatch();
  const [voice, setVoice] = React.useState(null);
  const [text, setText] = React.useState(null);

  const { list, listLoading, generatedVoice, generatedVoiceLoading } =
    useSelector((state) => {
      return {
        list: state.voice.list.data,
        listLoading: state.voice.list.loading,
        generatedVoice: state.voice.generateVoice.voice,
        generatedVoiceLoading: state.voice.generateVoice.loading,
      };
    });

  useEffect(() => {
    dispatch(listVoice());
  }, [dispatch]);

  useEffect(() => {
    if (list && !voice) {
      setVoice(list?.voices?.voices[0]?.voice_id);
    }
  }, [list]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">Generate Voice</Typography>
      <Typography>Select voice</Typography>
      {!listLoading && (
        <Select value={voice} onChange={(e) => setVoice(e.target.value)}>
          {list?.voices?.voices.map((voice) => (
            <MenuItem value={voice?.voice_id}>{voice?.name}</MenuItem>
          ))}
        </Select>
      )}
      <TextField
        label="Text"
        onChange={(e) => setText(e.target.value)}
        value={text}
        multiline
        minRows={5}
        sx={{
          width: "100%",
        }}
      />
      {!generatedVoiceLoading && !generatedVoice && (
        <Button
          sx={{
            textTransform: "none",
            color: "text.primary",
          }}
          variant="contained"
          onClick={() => dispatch(generateVoice({ voice, text }))}
        >
          Generate
        </Button>
      )}
      {generatedVoiceLoading && <Typography>Generating...</Typography>}
      {generatedVoice && (
        <a
          href={`${process.env.REACT_APP_API_URL_V2}audio/${generatedVoice?.fileName}`}
          download
        >
          <Button
            sx={{
              textTransform: "none",
              color: "text.primary",
            }}
            variant="contained"
          >
            Download audio
          </Button>
        </a>
      )}
    </Box>
  );
};

export default GenerateVoice;
