import { useMemo } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import groupLines from "../utils/groupLines";
import getVideoLines from "../utils/videoLines";

const useChannelData = ({ channelData, videos, rawChangelogs }) => {
  const xData = useMemo(() => channelData?.labels, [channelData]);

  const yData = useMemo(() => channelData?.data, [channelData]);

  const lines = useMemo(() => videos && getVideoLines({ videos }), [videos]);

  const changelogs = useMemo(() => rawChangelogs, [rawChangelogs]);

  return {
    xData,
    yData,
    lines,
    changelogs,
  };
};

export default useChannelData;
