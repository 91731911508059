import { createSlice } from "@reduxjs/toolkit";
import { setSession } from "../utils/jwt";
import { getAllUsers, loginUser, signUpInfo } from "./actions/userActions";

const userSlice = createSlice({
  name: "user",
  initialState: {
    login: {
      loading: false,
      loggedIn: false,
      user: null,
    },
    signUpInfo: {
      loading: false,
      data: null,
    },
    allUsers: {
      loading: false,
      data: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.login.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.login.loading = false;
      state.login.user = action.payload.user;
      state.login.loggedIn = true;
      localStorage.setItem("user", action.payload.user.email);
      setSession(action.payload.token);
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.login.loading = false;
    });
    builder.addCase(signUpInfo.pending, (state) => {
      state.signUpInfo.loading = true;
    });
    builder.addCase(signUpInfo.fulfilled, (state, action) => {
      state.signUpInfo.loading = false;
      state.signUpInfo.data = action.payload;
    });
    builder.addCase(signUpInfo.rejected, (state) => {
      state.signUpInfo.loading = false;
    });
    builder.addCase(getAllUsers.pending, (state) => {
      state.allUsers.loading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers.loading = false;
      state.allUsers.data = action.payload;
    });
    builder.addCase(getAllUsers.rejected, (state) => {
      state.allUsers.loading = false;
    });
  },
});

export default userSlice.reducer;
