import LaunchIcon from "@mui/icons-material/Launch";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Container from "@mui/material/Container";
import {
  Box,
  Button,
  IconButton,
  ListItemAvatar,
  Modal,
  Popover,
  Skeleton,
} from "@mui/material";
import { searchVideos } from "../redux/actions/videoActions";
import { DateRangePicker } from "react-date-range";
import { addMinutes } from "date-fns";
import { AutoGraph, GraphicEq } from "@mui/icons-material";

const CenteredContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "1200px",
  width: "100%",
  margin: "0 auto",
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  boxShadow: "none",
  //   borderBottom: "1px solid #e0e0e0",
}));

const SearchWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  padding: 20,
  flexGrow: 1,
  // backgroundColor: alpha(theme.palette.primary.main, 0.1),
  // "&:hover": {
  //   backgroundColor: alpha(theme.palette.primary.main, 0.2),
  // },
}));

const GoButtonWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  top: 0,
  right: 28,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: 50,
    width: "100%",
  },
}));

const SearchPage = () => {
  const dispatch = useDispatch();
  const anchorRef = React.useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState(null);
  const [openedDateRange, setOpenedDateRange] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const { data, loading } = useSelector((state) => ({
    data: state.video.searchResults.data,
    loading: state.video.searchResults.loading,
  }));

  const handleSearch = () => {
    dispatch(
      searchVideos({
        textFilter: searchTerm,
        from: range[0]?.startDate?.toISOString(),
        to: range[0]?.endDate?.toISOString(),
      })
    );
  };

  return (
    <CenteredContainer>
      <Modal open={selected !== null} onClose={() => setSelected(null)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "80%",
            height: "80%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              minHeight: "50px",
              overflow: "auto",
              flexGrow: 1,
              marginBottom: "20px",
              whiteSpace: "nowrap",
            }}
          >
            {selected?.title}
          </Typography>
          <Typography variant="caption" sx={{ flexGrow: 1, overflow: "auto" }}>
            {selected?.transcript}
          </Typography>
        </div>
      </Modal>{" "}
      <Popover
        open={openedDateRange}
        onClose={() => setOpenedDateRange(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorRef.current}
      >
        <DateRangePicker
          onChange={(item) => {
            let range = [];
            if (item?.range1?.endDate?.getHours() === 0) {
              range = [
                {
                  startDate: item.range1.startDate,
                  endDate: addMinutes(item.range1.endDate, 1439),
                },
              ];
            } else if (item?.range1) {
              range = [
                {
                  startDate: item.range1.startDate,
                  endDate: item.range1.endDate,
                },
              ];
            } else {
              range = [
                {
                  startDate: item.selection.startDate,
                  endDate: item.selection.endDate,
                },
              ];
            }

            setRange(range);
            setOpenedDateRange(false);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={range}
          direction="horizontal"
        />
      </Popover>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          marginTop: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SearchWrapper>
          <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              value={searchTerm}
            />
            <GoButtonWrapper>
              <Button variant="contained" onClick={handleSearch} size="small">
                Go
              </Button>
            </GoButtonWrapper>
          </Paper>
        </SearchWrapper>
        <Box sx={{ marginTop: "28px" }}>
          <Button
            variant="contained"
            onClick={() => setOpenedDateRange(true)}
            size="small"
            ref={anchorRef}
            sx={{
              minWidth: "220px",
              textTransform: "none",
            }}
          >
            {range[0]?.endDate?.toLocaleDateString() &&
            range[0]?.startDate?.toLocaleDateString()
              ? range[0]?.startDate?.toLocaleDateString() +
                " - " +
                range[0]?.endDate?.toLocaleDateString()
              : "Click to change date range"}
          </Button>
        </Box>
      </Box>
      {loading &&
        Array(10)
          .fill(0)
          .map((_, idx) => (
            <Skeleton
              key={idx}
              variant="rectangular"
              width="100%"
              height={100}
              style={{ marginTop: "20px" }}
            />
          ))}
      {data && data.length > 0 && (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {data?.map((result, idx) => (
            <Paper elevation={3} style={{ marginTop: "20px", width: "100%" }}>
              <ListItem
                key={idx}
                button
                onClick={() => setSelected(result)}
                sx={{ padding: "10px" }}
              >
                {/* image */}
                <ListItemAvatar style={{ marginRight: 20 }}>
                  <img
                    src={result.thumbnail_url}
                    alt={result.title}
                    style={{ width: "100px" }}
                  />
                </ListItemAvatar>
                <ListItemText primary={result.title} />
                <ListItem
                  sx={{
                    textAlign: "right",
                    marginRight: "50px",
                    paddingRight: "50px",
                    whiteSpace: "nowrap",
                    marginLeft: "auto",
                  }}
                >
                  <AutoGraph sx={{ marginRight: "10px" }} />
                  {result.views.toLocaleString()}
                </ListItem>
                <ListItemText
                  sx={{
                    textAlign: "right",
                    marginRight: "50px",
                    paddingRight: "50px",
                    whiteSpace: "nowrap",
                  }}
                  primary={new Date(result.published_at).toDateString()}
                />
                <ListItemText
                  sx={{
                    // align to the right
                    textAlign: "right",
                    marginRight: "50px",
                    whiteSpace: "nowrap",
                    paddingRight: "50px",
                  }}
                  primary={(result.score * 100).toFixed(2) + "% match"}
                />
                {/* link to video */}
                <IconButton
                  onClick={() => {
                    window.open(
                      `https://www.youtube.com/watch?v=${result.video_id}`,
                      "_blank"
                    );
                  }}
                >
                  <LaunchIcon />
                </IconButton>
              </ListItem>
            </Paper>
          ))}
        </List>
      )}
    </CenteredContainer>
  );
};

export default SearchPage;
