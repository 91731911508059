import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyUrl from "../components/CopyUrl";
import FilterBar from "../components/FilterBar";
import GroupedViewsInsight from "../components/GroupedViewsInsight";
import InsightPickers from "../components/InsightPickers";
import PerformanceInsight from "../components/PerformanceInsight";
import SingleViewsInsight from "../components/SingleViewsInsight";
import { insights as insightsConstant } from "../constants/insights";
import useInsightsFilters from "../constants/useInsightsFilters";
import useFilters from "../hooks/useFilters";
import { publishDatePerformanceInsights as getInsights } from "../redux/actions/videoActions";
import GPTInsights from "../components/GPTInsights";
import ChannelCompareInsights from "../components/ChannelCompareInsights";

const getInitialInsight = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const insight = params.get("insight");

  return insight;
};

const Insights = () => {
  const dispatch = useDispatch();
  const [insight, setInsight] = useState(
    getInitialInsight() || insightsConstant[1].key
  );
  const [currentPromise, setCurrentPromise] = useState({});
  const [offset, setOffset] = useState(0);
  const insightsFilters = useInsightsFilters(insight);

  const { loading, insights } = useSelector((state) => ({
    loading: state.video.insights.loading,
    insights: state.video.insights.data,
    channels: state.channel.allChannels.channels,
  }));

  const { activeFilters, inactiveFilters, activeValues, setActiveValues } =
    useFilters(insightsFilters);

  useEffect(() => {
    if (currentPromise?.abort) {
      currentPromise.abort();
    }

    const promise = dispatch(
      getInsights({
        insight,
        ...activeValues,
        offset,
        timeZoneOffset: Math.abs(new Date().getTimezoneOffset()),
        timezoneOffsetSymbol:
          Math.abs(new Date().getTimezoneOffset()) >= 0 ? "+" : "-",
      })
    );
    setCurrentPromise(promise);
  }, [dispatch, insight, activeValues, offset]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <InsightPickers setInsight={setInsight} insight={insight} />
        <CopyUrl />
      </Box>
      {insight === insightsConstant[0].key ||
        (insight === insightsConstant[1].key && (
          <FilterBar
            activeFilters={activeFilters}
            inactiveFilters={inactiveFilters}
            activeValues={activeValues}
            setActiveValues={setActiveValues}
            type="insights"
            ignoredValues={["channelId"]}
          />
        ))}
      {["publish_day", "release_time"].includes(activeValues.groupBy) && (
        <Typography sx={{ fontSize: 10, color: "gray" }}>
          Time zone: {Intl.DateTimeFormat().resolvedOptions().timeZone}
        </Typography>
      )}
      {insight === insightsConstant[0].key && (
        <PerformanceInsight insights={insights} loading={loading} />
      )}

      {insight === insightsConstant[1].key && !activeValues.groupBy && (
        <SingleViewsInsight
          data={activeValues?.data}
          insights={insights}
          loading={loading}
        />
      )}
      {insight === insightsConstant[1].key && activeValues.groupBy && (
        <GroupedViewsInsight
          insights={insights}
          loading={loading}
          setOffset={setOffset}
          offset={offset}
          showOffset={
            activeValues?.data === "views" &&
            activeValues?.groupBy === "time_ranges"
          }
        />
      )}

      {insight === insightsConstant[2].key && <ChannelCompareInsights />}

      {insight === insightsConstant[3].key && <GPTInsights />}
    </Box>
  );
};

export default Insights;
