const getVideoLines = ({ videos }) => {
  if (!videos) return [];
  return videos.map((video) => ({
    created_at: new Date(video.published_at).valueOf(),
    old_value: {
      title: video.title,
      thumbnail: video.thumbnail,
    },
    new_value: {
      title: video.title,
      thumbnail: video.thumbnail,
    },
    action: "video",
    videoId: video?.video_id,
  }));
};

export default getVideoLines;
