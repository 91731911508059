import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "",
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data === "Not verified"
    ) {
      window.location.href = "/verify";
      return;
    } else if (
      error.response.status === 401 &&
      error.response.data === "Channels not selected"
    ) {
      window.location.href = "/choose-channel";
      return;
    } else if (error.response.status === 401 || error.response.status === 500) {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
      return;
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
