import { Box, IconButton, Skeleton, Tooltip, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import nFormatter from "../utils/numberFormatter";
import VideoDisplay from "./VideoDisplay";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const GroupedViewsInsight = ({
  insights,
  loading,
  showOffset,
  setOffset,
  offset,
}) => {
  const theme = useTheme();

  const [selected, setSelected] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);

  const handleSelected = (value) => {
    if (selected === value) {
      setSelected(null);
    } else {
      setSelected(value);
    }
  };

  const handleMouseMove = (e) => {
    setTooltipData({
      x: e?.activeLabel,
      y: e?.activePayload?.[0]?.value?.toLocaleString(),
    });
  };

  const data = useMemo(() => {
    const yData = insights?.dailyViews;
    return insights?.keys?.map((x, i) => ({
      name: x,
      label: parseInt(yData?.[i]),
      fill: x === selected ? "#4049d8" : "#a4a9ea",
    }));
  }, [insights, selected]);

  return (
    <Box width="100%">
      {loading && (
        <Box width="100%" height={600} overflow="hidden" borderRadius={2}>
          <Skeleton width="100%" height="100%" variant="rectangular" />
        </Box>
      )}
      {!loading && (
        <Box
          sx={{
            padding: 4,
            paddingRight: 0,
            borderRadius: 4,
            backgroundColor: "background.card",
            position: "relative",
          }}
        >
          {showOffset && (
            <>
              <IconButton
                onClick={() => setOffset((prev) => prev + 1)}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  left: 10,
                  backgroundColor: "background.default",
                  boxShadow: 2,
                }}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                onClick={() => offset > 0 && setOffset((prev) => prev - 1)}
                sx={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  backgroundColor: "background.default",
                  boxShadow: 2,
                }}
              >
                <NavigateNextIcon />
              </IconButton>
            </>
          )}
          <ResponsiveContainer width="100%" height={600}>
            <ComposedChart data={data} onMouseMove={handleMouseMove}>
              <XAxis
                tickLine={false}
                axisLine={false}
                domain={["auto", "dataMax"]}
                tick={{
                  fontSize: "10px",
                  width: "50px",
                  wordWrap: "break-word",
                }}
                dataKey="name"
                style={{ userSelect: "none" }}
              />
              <YAxis
                tickLine={false}
                tickCount={10}
                axisLine={false}
                type="number"
                domain={["auto", "auto"]}
                tickFormatter={(tick) => nFormatter(tick)}
                tick={{ fontSize: "12px" }}
                orientation="right"
                style={{ userSelect: "none" }}
              />
              <CartesianGrid vertical={false} stroke="rgb(235, 235, 235)" />
              <Bar
                radius={[15, 15, 0, 0]}
                barSize={30}
                style={{ cursor: "pointer" }}
                dataKey="label"
                onClick={(e) => {
                  return handleSelected(e.name);
                }}
              />
              <ReferenceLine x={tooltipData?.x} strokeDasharray="3 3">
                <Label
                  content={({ viewBox: { x, y } }) => {
                    return (
                      <>
                        <text
                          x={x}
                          y={150}
                          fill={
                            theme.palette.mode === "dark" ? "white" : "black"
                          }
                          fontWeight={600}
                          fontSize={16}
                          textAnchor="middle"
                        >
                          {tooltipData?.y}
                        </text>
                        <text
                          x={x}
                          y={170}
                          fill={
                            theme.palette.mode === "dark" ? "white" : "black"
                          }
                          fontSize={16}
                          textAnchor="middle"
                        >
                          {tooltipData?.x}
                        </text>
                      </>
                    );
                  }}
                />
              </ReferenceLine>
              <Tooltip />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      )}
      {selected !== null &&
        insights?.videos?.[insights?.keys?.indexOf(selected)] && (
          <Box marginTop={2} marginBottom={2}>
            <VideoDisplay
              limited
              videos={insights?.videos?.[insights?.keys?.indexOf(selected)]}
              loading={loading}
            />
          </Box>
        )}
    </Box>
  );
};

export default GroupedViewsInsight;
