import CommentIcon from "@mui/icons-material/Comment";
import ImageIcon from "@mui/icons-material/Image";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import TitleIcon from "@mui/icons-material/Title";
import React from "react";

import { Box } from "@mui/system";

const getIcon = (action, small) => {
  switch (action) {
    case "subscriber_count":
      return (
        <PersonAddAlt1Icon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    case "views":
    case "view_count":
      return (
        <PlayCircleIcon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    case "video_count":
      return (
        <RemoveRedEyeIcon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    case "comments":
      return (
        <CommentIcon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    case "likes":
      return (
        <ThumbUpIcon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    case "title":
      return (
        <TitleIcon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    case "thumbnail":
      return (
        <ImageIcon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    case "thumbnail_title":
      return (
        <RestartAltIcon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    case "video":
      return (
        <PlayCircleIcon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    case "status":
      return (
        <LockResetIcon
          width={small ? 12 : undefined}
          sx={{ color: "#606060" }}
          fontSize="medium"
        />
      );
    default:
      break;
  }
};
const ChangeIcon = ({ action, small }) => {
  if (small) return getIcon(action, small);

  return (
    <Box
      width={35}
      height={35}
      paddingTop={0.75}
      sx={{ backgroundColor: "#e9e9e9", borderRadius: 10 }}
    >
      {getIcon(action)}
    </Box>
  );
};

export default ChangeIcon;
