import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const getActiveValuesFromLocation = (location, filters) => {
  const queryStartIndex = location.search.indexOf("?");
  if (queryStartIndex === -1) {
    return {};
  }
  const queryString = location.search.slice(queryStartIndex + 1);
  const queryParams = queryString.split("&");
  const queryParamsObject = {};
  for (let i = 0; i < queryParams.length; i++) {
    const [param, value] = queryParams[i].split("=");
    if (filters.find((f) => f.value === param))
      queryParamsObject[param] = decodeURIComponent(value);
  }

  for (const [key, value] of queryParams.entries()) {
    queryParams[key] = value;
  }
  return queryParamsObject;
};

const getInitialActiveValues = (location, filters) => {
  const activeValuesFromLocation = getActiveValuesFromLocation(
    location,
    filters
  );
  // get default values from filters
  const defaultValues = filters.reduce((acc, filter) => {
    if (filter.required || filter.defaultValue) {
      acc[filter.value] = getFilterDefaultValue(filter);
    }
    return acc;
  }, {});
  return {
    ...defaultValues,
    ...activeValuesFromLocation,
  };
};

const getFilterDefaultValue = (filter) => {
  if (filter.defaultValue) return filter.defaultValue;
  if (filter.type === "dateRange") {
    return (
      filter.defaultValue || [
        {
          startDate: subDays(new Date(), 1),
          endDate: new Date(),
        },
      ]
    );
  }

  if (filter.type === "select") {
    return filter.defaultValue || filter?.options?.[0]?.value;
  }
  if (filter.type === "boolean") {
    return filter.defaultValue || true;
  }
  return "";
};

const updateUrl = (activeValues, inactiveFilters) => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  inactiveFilters.forEach((f) => {
    searchParams.delete(f.value);
  });
  Object.keys(activeValues).forEach((key) => {
    if (
      activeValues[key] !== undefined &&
      typeof activeValues[key] !== "object"
    ) {
      searchParams.set(key, activeValues[key]);
    } else {
      searchParams.delete(key);
    }
  });
  url.search = searchParams.toString();
  window.history.pushState({}, "", url);
};

const useFilters = (filters = []) => {
  const location = useLocation();
  const [activeFilters, setActiveFilters] = useState([]);
  const [inactiveFilters, setInactiveFilters] = useState([]);
  const [activeValues, setActiveValues] = useState(
    getInitialActiveValues(location, filters)
  );

  useEffect(() => {
    const required = filters.filter((f) => f.required);
    required.forEach((f) => {
      const inActiveValues = activeValues[f.value] !== undefined;
      const isRequired =
        f.required === true ||
        Object.keys(f.required).every(
          (key) =>
            activeValues[key] === f.required[key] ||
            (activeValues[key] && f.required[key] === true)
        );
      if (!inActiveValues && isRequired) {
        setActiveValues((prev) => ({
          ...prev,
          [f.value]: getFilterDefaultValue(f),
        }));
      }

      if (inActiveValues && !isRequired) {
        setActiveValues((prev) => {
          const newActiveValues = { ...prev };
          delete newActiveValues[f.value];
          return newActiveValues;
        });
      }
    });

    const disabled = filters.filter((f) => f.disabled);
    disabled.forEach((f) => {
      const inActiveValues = activeValues[f.value] !== undefined;
      const isDisabled =
        f.disabled === true ||
        Object.keys(f.disabled).every(
          (key) =>
            activeValues[key] === f.disabled[key] ||
            (activeValues[key] && f.disabled[key] === true)
        );
      if (inActiveValues && isDisabled) {
        setActiveValues((prev) => {
          const newActiveValues = { ...prev };
          delete newActiveValues[f.value];
          return newActiveValues;
        });
      }
    });

    const activeFilters = Object.entries(activeValues)
      .filter(([key, value]) => {
        return value !== undefined;
      })
      .filter(([key]) => filters.find((f) => f.value === key))
      .map(([key]) => {
        return filters.find((f) => f.value === key);
      })
      .sort((a, b) => {
        const priorityA =
          filters.find((f) => f.value === a.value).priority || 100;
        const priorityB =
          filters.find((f) => f.value === b.value).priority || 100;
        return priorityA - priorityB;
      });

    const filtersToRemove = filters.filter(
      (f) => !activeFilters.find((af) => af.value === f.value)
    );

    const inactiveFilters = filters
      .filter((f) => activeValues[f.value] === undefined && !f.required)
      .map((f) => ({
        ...f,
        disabled:
          f.disabled &&
          Object.keys(f.disabled).some(
            (key) =>
              activeValues[key] === f.disabled[key] ||
              (activeValues[key] && f.disabled[key] === true)
          ),
      }));
    setActiveFilters(activeFilters);
    setInactiveFilters(inactiveFilters);
    updateUrl(activeValues, filtersToRemove);
  }, [activeValues, filters]);

  return {
    activeFilters,
    inactiveFilters,
    activeValues,
    setActiveValues,
  };
};

export default useFilters;
