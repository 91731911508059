import { createSlice } from "@reduxjs/toolkit";
import { allFilters } from "./actions/filterActions";

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    allFilters: {
      loading: false,
      data: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allFilters.pending, (state) => {
      state.allFilters.loading = true;
    });
    builder.addCase(allFilters.fulfilled, (state, action) => {
      state.allFilters.loading = false;
      state.allFilters.data = action.payload;
    });
    builder.addCase(allFilters.rejected, (state) => {
      state.allFilters.loading = false;
    });
  },
});

export default filterSlice.reducer;
