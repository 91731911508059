import { InfoRounded } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { formatDistanceToNow } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import getImageUrl from "../utils/getImageUrl";

const SingleVideoRowDisplay = ({ video }) => {
  return (
    <Link
      style={{ textDecoration: "none", color: "unset" }}
      to={`/videos/${video?.video_id}`}
    >
      <Box
        backgroundColor="background.card"
        sx={{
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          padding: 1,
          borderRadius: 2,
          height: 110,
          width: "100%",
        }}
      >
        <Box
          minHeight={100}
          minWidth={185}
          sx={{
            background: `url(${getImageUrl(video?.thumbnail)})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: 2,
          }}
        ></Box>
        <Box color="text.primary" minWidth={0} pl={2} pr={2} flexGrow={1}>
          <Typography
            fontSize="18px"
            fontWeight="bold"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {video?.title}
          </Typography>
          <Typography
            fontSize="15px"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {video.description}
          </Typography>
        </Box>
        {/* {video?.last_24_h_views ? ( */}
        <>
          <Box
            textAlign="center"
            display="flex"
            flexDirection="row"
            color="text.primary"
          >
            <Box
              justifyContent="center"
              display="flex"
              flexDirection="column"
              width="100px"
            >
              <Typography>
                <b>{video?.title_changes}</b>
              </Typography>
            </Box>
            <Box
              justifyContent="center"
              display="flex"
              flexDirection="column"
              width="100px"
            >
              <Typography>
                <b>{video?.thumbnail_changes}</b>
              </Typography>
            </Box>
            <Box
              justifyContent="center"
              display="flex"
              flexDirection="column"
              width="100px"
            >
              <Tooltip
                title={
                  formatDistanceToNow(new Date(video?.published_at)) + " ago"
                }
              >
                <Typography>
                  <b>{new Date(video?.published_at).toLocaleString()}</b>
                  <InfoRounded sx={{ fontSize: 12, marginLeft: 1 }} />
                </Typography>
              </Tooltip>
            </Box>
            <Box
              justifyContent="center"
              display="flex"
              flexDirection="column"
              width="100px"
            >
              {video?.views ? (
                <Typography>
                  {Number(video?.views)?.toLocaleString()}
                </Typography>
              ) : (
                <Typography>0</Typography>
              )}
            </Box>
            <Box
              justifyContent="center"
              display="flex"
              flexDirection="column"
              width="100px"
            >
              {video?.last_24_h_views ? (
                <Typography>
                  {video?.last_24_h_views?.toLocaleString()}
                </Typography>
              ) : (
                <Typography>0</Typography>
              )}
            </Box>
            <Box
              justifyContent="center"
              width="100px"
              display="flex"
              flexDirection="column"
            >
              {video?.last_1_h_views ? (
                <Typography>
                  {video?.last_1_h_views?.toLocaleString()}
                </Typography>
              ) : (
                <Typography>0</Typography>
              )}
            </Box>
            <Box
              justifyContent="center"
              width="100px"
              display="flex"
              flexDirection="column"
            >
              {video?.last_24_h_change ? (
                <Typography>{video?.last_24_h_change?.toFixed(2)}%</Typography>
              ) : (
                <Typography>0%</Typography>
              )}
            </Box>
            <Box
              justifyContent="center"
              width="100px"
              display="flex"
              flexDirection="column"
            >
              {video?.last_1_w_change ? (
                <Typography>{video?.last_1_w_change?.toFixed(2)}%</Typography>
              ) : (
                <Typography>0%</Typography>
              )}
            </Box>
            <Box
              justifyContent="center"
              width="100px"
              display="flex"
              flexDirection="column"
            >
              {video?.likes_to_views_ratio ? (
                <Typography>
                  {(video?.likes_to_views_ratio * 100).toFixed(2)}%
                </Typography>
              ) : (
                <Typography>0%</Typography>
              )}
            </Box>
            <Box
              justifyContent="center"
              width="100px"
              display="flex"
              flexDirection="column"
            >
              {video?.comments_to_views_ratio ? (
                <Typography>
                  {(video?.comments_to_views_ratio * 100).toFixed(2)}%
                </Typography>
              ) : (
                <Typography>0%</Typography>
              )}
            </Box>
          </Box>
        </>
      </Box>
    </Link>
  );
};

export default SingleVideoRowDisplay;
