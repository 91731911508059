import { ChevronLeft, ChevronRight, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Changelog from "../components/Changelog";
import Chart from "../components/Chart";
import CommentsDisplay from "../components/CommentsDisplay";
import FilterBar from "../components/FilterBar";
import InsightCard from "../components/InsightCard";
import Label from "../components/Label";
import RecommendationsModal from "../components/RecommendationsModal";
import RecommendedVideoDisplay from "../components/RecommendedVideoDisplay";
import SingleVideoDisplay from "../components/SingleVideoDisplay";
import VideoDisplay from "../components/VideoDisplay";
import VideoHeader from "../components/VideoHeader";
import VideoSelect from "../components/VideoSelect";
import { GRAPHS } from "../constants/graphTypes";
import videoFilters from "../constants/videoFilters";
import useFilters from "../hooks/useFilters";
import { useVideoData } from "../hooks/useVideoData";
import {
  compareChannelViews as getCompareChannelViews,
  compareVideo as getCompareVideo,
  videoChartData,
  videoInfo,
} from "../redux/actions/videoActions";

const selected = {
  backgroundColor: "#4952da",
  color: "white",
};

const notSelected = {
  backgroundColor: "background.paper",
  borderTopColor: "transparent",
};

const Video = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    videoData,
    compareVideoData,
    loading,
    compareVideoDataLoading,
    compareChannelViews,
    compareChannelViewsLoading,
    chartData,
    chartLoading,
  } = useSelector((state) => ({
    videoData: state.video.videoInfo.data,
    loading: state.video.videoInfo.loading,
    chartData: state.video.chartData.data,
    chartLoading: state.video.chartData.loading,
    compareVideoData: state.video.compareVideo.data,
    compareVideoDataLoading: state.video.compareVideo.loading,
    compareChannelViews: state.video.compareChannelViews.data,
    compareChannelViewsLoading: state.video.compareChannelViews.loading,
  }));

  const [selectedGraph, setSelectedGraph] = useState(GRAPHS[0].id);
  const [open, setOpen] = useState(null);
  const [videoCompare, setVideoCompare] = useState(null);
  const [hoursAgo, setHoursAgo] = useState(0);
  const [recomendationsModalOpen, setRecommendationsModalOpen] =
    useState(false);

  const { activeFilters, inactiveFilters, activeValues, setActiveValues } =
    useFilters(videoFilters);

  const { interval = 10, range } = activeValues;

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(videoInfo({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(videoChartData({ id, interval, range, type: selectedGraph }));
  }, [dispatch, id, interval, range, selectedGraph]);

  useEffect(() => {
    if (videoCompare) {
      const params = {
        id: videoCompare.video_id,
        channel_id: videoCompare.channel_id,
        range,
        interval,
        type: selectedGraph,
        videoId: id,
      };
      dispatch(getCompareVideo(params));
    }
  }, [dispatch, videoCompare, range, interval, selectedGraph, id]);

  const handleRangeChange = (ranges) => {
    setActiveValues((prev) => ({
      ...prev,
      range: ranges,
    }));
  };

  const handleSelectCompareVideo = (video) => {
    setVideoCompare(video);
    setOpen(false);
  };

  useEffect(() => {
    if (
      ["average_video_compare", "same_day_video_compare"].includes(
        activeValues?.compare
      )
    ) {
      dispatch(
        getCompareChannelViews({
          steps: videoData?.chart?.view_chart_data?.length,
          channelId: videoData?.video?.channel_id,
          interval,
          day:
            activeValues?.compare === "same_day_video_compare"
              ? new Date(videoData?.video?.published_at).getDay()
              : undefined,
          videoId: id,
          range,
        })
      );
    }
  }, [
    dispatch,
    interval,
    activeValues?.compare,
    videoData?.chart?.view_chart_data?.length,
    videoData?.video?.channel_id,
    videoData?.video?.published_at,
    id,
    range,
  ]);

  useEffect(() => {
    if (activeValues?.compare === "video_compare") {
      setOpen("videoCompare");
    }
  }, [dispatch, activeValues?.compare]);

  const {
    xData,
    yData,
    compareYData,
    lines,
    cumulativeYDataAveraged,
    cumulativeYDataRegular,
  } = useVideoData({
    range,
    chartData,
    selectedGraph,
    compareVideoData,
    selectedCompare: activeValues?.compare,
    compareChannelViews,
    startDate: new Date(chartData?.min_chart_date),
    movingAverage: activeValues?.movingAverage,
  });

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <VideoHeader videoData={videoData} />
      <Divider orientation="horizontal" flexItem />
      <Box display="flex" flexDirection="row" gap={3}>
        {videoData?.recommended_titles && (
          <InsightCard
            insightData={{
              type: "Recommendation",
              title: "Title recommendations",
              subtitle: "Titles that might make this video perform better",
            }}
            onClick={() => setOpen("titleRecommendation")}
          />
        )}
      </Box>
      <FilterBar
        activeFilters={activeFilters}
        setActiveValues={setActiveValues}
        activeValues={activeValues}
        inactiveFilters={inactiveFilters}
        type="video"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <Box
          style={{
            textAlign: "right",
            alignContent: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Modal open={open} onClose={handleClose}>
            <>
              {open === "videoCompare" && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    height: "85%",
                    padding: 5,
                    paddingTop: 3,
                    overflow: "scroll",
                  }}
                  backgroundColor="background.paper"
                >
                  <Typography variant="h5" mb={2} fontWeight="bold">
                    Select video to compare
                  </Typography>
                  <VideoSelect handleSelectVideo={handleSelectCompareVideo} />
                </Box>
              )}
              {open === "titleRecommendation" && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: 5,
                    paddingTop: 3,
                    overflow: "scroll",
                  }}
                  backgroundColor="background.paper"
                >
                  <Typography variant="h4" mb={2}>
                    Title Recommendations
                  </Typography>
                  {videoData?.recommended_titles?.properties
                    ?.split("|")
                    .map((title) => (
                      <Typography mt={2}>{title}</Typography>
                    ))}
                </Box>
              )}
            </>
          </Modal>
        </Box>
      </Box>
      {activeValues?.compare === "video_compare" && (
        <Box display="flex" flexDirection="row" justifyContent={"center"}>
          {videoCompare ? (
            <SingleVideoDisplay video={videoCompare} />
          ) : (
            <Skeleton variant="rounded" width={300} height={50} />
          )}
        </Box>
      )}
      <Box display="flex">
        {GRAPHS.map((graph) => (
          <>
            <Box
              onClick={() => setSelectedGraph(graph.id)}
              p={3}
              sx={{
                cursor: "pointer",
                width: 0,
                flex: "1 1 0",
                textAlign: "center",
                fontWeight: "bold",
                borderRadius: 2,
                position: "relative",
                overflow: "hidden",
                ...(selectedGraph === graph.id ? selected : notSelected),
              }}
            >
              {graph.name}
            </Box>
          </>
        ))}
      </Box>
      <Chart
        loading={
          chartLoading || compareVideoDataLoading || compareChannelViewsLoading
        }
        x={xData}
        y={yData}
        cumulativeY={
          activeValues?.viewNonCumulative === "regular"
            ? cumulativeYDataRegular
            : cumulativeYDataAveraged
        }
        compareY={compareYData}
        lines={lines}
        setRange={handleRangeChange}
        cumulative={!activeValues?.viewNonCumulative}
        interval={interval}
        showTotal={activeValues?.showTotal}
      />
      <Typography marginTop="10px" height="50px" variant="h6" fontWeight="bold">
        Recent channel videos
      </Typography>
      <VideoDisplay
        loading={loading}
        hideChannelFilter={true}
        videos={videoData?.nearby_videos}
      />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Typography
            marginTop="18px"
            height="50px"
            variant="h6"
            fontWeight="bold"
          >
            Recommended videos
          </Typography>
          <IconButton onClick={() => setRecommendationsModalOpen(true)}>
            <InfoOutlined />
          </IconButton>
        </Box>

        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <IconButton
            onClick={() => {
              if (hoursAgo <= 24) setHoursAgo(hoursAgo - 1);
              else setHoursAgo(hoursAgo - 24);
            }}
            disabled={hoursAgo === 0}
          >
            <ChevronLeft />
          </IconButton>
          {hoursAgo < 1
            ? "Just released"
            : hoursAgo === 1
            ? "1 hour after release"
            : hoursAgo < 24
            ? `${hoursAgo} hours after release`
            : hoursAgo === 24
            ? "1 day after release"
            : `${Math.floor(hoursAgo / 24)} days after release`}
          <IconButton
            onClick={() => {
              if (hoursAgo < 24) setHoursAgo(hoursAgo + 1);
              else setHoursAgo(hoursAgo + 24);
            }}
            disabled={
              videoData?.published_at + hoursAgo * 3600 * 1000 > Date.now()
            }
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      <RecommendationsModal
        open={recomendationsModalOpen}
        setOpen={setRecommendationsModalOpen}
        id={id}
      />
      <RecommendedVideoDisplay
        id={id}
        loading={loading}
        hideChannelFilter={true}
        videos={videoData?.nearby_videos}
        hoursAgo={hoursAgo}
      />
      <Box display="flex" flexDirection="column" gap={2}>
        <Changelog loading={loading} data={lines} />
        <Typography marginTop="10px" height="50px" variant="h6">
          Comments{" "}
          <Label variant="filled" color={"error"}>
            {videoData?.comments_fetch?.length}
          </Label>
        </Typography>
        <CommentsDisplay comments={videoData?.comments_fetch} />
      </Box>
    </Box>
  );
};

export default Video;
