import { Skeleton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import SingleVideoRowDisplay from "./SingleVideoRowDisplay";

export const videoTableHeaders = [
  { label: "thumbnail changes count", id: "thumbnail_changes", type: "" },
  { label: "title changes count", id: "title_changes", type: "" },
  { label: "release date", id: "published_at", type: "", sortable: true },
  { label: "total views", id: "views", type: "", sortable: true },
  { label: "daily views", id: "last_24_h_views", type: "", sortable: true },
  { label: "hourly views", id: "last_1_h_views", type: "", sortable: true },
  { label: "24h % increase", id: "last_24_h_change", type: "", sortable: true },
  { label: "week % increase", id: "last_1_w_change", type: "", sortable: true },
  {
    label: "likes / Views",
    id: "likes_to_views_ratio",
    type: "",
    sortable: true,
  },
  {
    label: "comments / Views",
    id: "comments_to_views_ratio",
    type: "",
    sortable: true,
  },
];

const VideoRowDisplay = ({ videos, loading, rowsPerPage }) => {
  return (
    <Box>
      {/* <Box
        position="relative"
        display="flex"
        gap={1}
        alignItems="center"
        flexWrap="wrap"
        border="1px solid"
        borderColor="divider"
        borderRadius={2}
        padding={1}
        minHeight={24}
      >
        <Box
          onClick={() => setEditingVideoLength(true)}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            backgroundColor: "divider",
            borderRadius: 1,
            paddingLeft: 1,
            paddingRight: 1,
            alignItems: "center",
            fontSize: 13,
            height: 25,
            cursor: "pointer",
          }}
        >
          {editingVideoLength ? (
            <Select
              sx={{ height: 20, fontSize: 13, padding: 0 }}
              onBlur={(e) => {
                setEditingVideoLength(false);
                handleChangeVideoType(e.target.value || "videos");
              }}
              onChange={(e) => {
                setEditingVideoLength(false);
                handleChangeVideoType(e.target.value);
              }}
              defaultOpen
            >
              {["videos", "shorts"].map((value) => (
                <MenuItem value={value}>{value}</MenuItem>
              ))}
            </Select>
          ) : (
            videoType
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            backgroundColor: "divider",
            borderRadius: 1,
            paddingLeft: 1,
            paddingRight: 1,
            alignItems: "center",
            fontSize: 13,
            height: 25,
            cursor: "pointer",
          }}
        >
          Sort by:
          {editingSortBy ? (
            <>
              <Select
                sx={{ height: 20, fontSize: 13, padding: 0, marginLeft: 1 }}
                onBlur={(e) => {
                  setEditingSortBy(false);
                  handleChangeOrderBy(e.target.value || "published_at");
                }}
                onChange={(e) => {
                  setEditingSortBy(false);
                  handleChangeOrderBy(e.target.value);
                }}
                value={orderBy}
              >
                {videoTableHeaders.map((header) => (
                  <MenuItem value={header.id}>{header.label}</MenuItem>
                ))}
              </Select>
            </>
          ) : (
            <Box onClick={() => setEditingSortBy(true)} marginLeft={1}>
              {videoTableHeaders.find((header) => header.id === orderBy).label}
            </Box>
          )}
          {sortDirection === "asc" ? (
            <Tooltip title="Sort by ascending order">
              <Box
                onClick={() => setSortDirection("desc")}
                sx={{ cursor: "pointer" }}
              >
                <ArrowUpward
                  sx={{
                    height: 18,
                    width: 18,
                    marginTop: 0.6,
                    marginLeft: 1,
                  }}
                />
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title="Sort by descending order">
              <Box
                onClick={() => setSortDirection("asc")}
                sx={{ cursor: "pointer" }}
              >
                <ArrowDownward
                  sx={{
                    height: 18,
                    width: 18,
                    marginTop: 0.6,
                    marginLeft: 1,
                  }}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
        {min24hViews && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              backgroundColor: "divider",
              borderRadius: 1,
              paddingLeft: 1,
              paddingRight: 1,
              alignItems: "center",
              fontSize: 13,
              height: 25,
            }}
          >
            <Tooltip title="Videos with < 1000 views per day" placement="top">
              <Box>Exclude dead videos</Box>
            </Tooltip>
            <IconButton
              size="small"
              sx={{ height: 25, width: 15, border: "none", marginLeft: 1 }}
              onClick={() => setMin24hViews(null)}
            >
              <Close sx={{ width: 20 }} />
            </IconButton>
          </Box>
        )}
        {title !== null && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              backgroundColor: "divider",
              borderRadius: 1,
              paddingLeft: 1,
              paddingRight: 1,
              alignItems: "center",
              fontSize: 13,
              height: 25,
            }}
            onClick={() => setEditingTitle(true)}
          >
            Include in title or transcript:
            {editingTitle ? (
              <TextField
                sx={{ marginLeft: 1 }}
                onBlur={(e) => {
                  console.log(e);
                  if (!e.relatedTarget) {
                    setEditingTitle(false);
                  }
                  setTitle(e.target.value);
                }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                size="small"
                inputRef={(input) => input && input.focus()}
                autoFocus={true}
                focused={true}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setTitle(e.target.value);
                    setEditingTitle(false);
                  }
                }}
                inputProps={{
                  style: {
                    padding: 0,
                    paddingLeft: 5,
                    height: "25px",
                    fontSize: 13,
                  },
                }}
              />
            ) : (
              <Box sx={{ cursor: "pointer", marginLeft: 1 }}>{title}</Box>
            )}
            <IconButton
              size="small"
              sx={{ height: 25, width: 15, border: "none", marginLeft: 1 }}
              onClick={() => setTitle(null)}
            >
              <Close sx={{ width: 20 }} />
            </IconButton>
          </Box>
        )}
        {(!min24hViews || title === null) && (
          <Select
            sx={{
              position: "absolute",
              right: 5,
              height: 20,
              width: 30,
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              ".MuiSvgIcon-root ": {
                fill: "lightgray !important",
              },
              ".MuiSelect-iconOpen": {
                transform: "scale(0.9) !important",
              },
            }}
            onChange={(e) => {
              e.preventDefault();
              if (e.target.value === "excludeDeadVideos") {
                setMin24hViews(1000);
              } else if (e.target.value === "includeWordInTitle") {
                setTitle("");
                setEditingTitle(true);
              }
            }}
            value={""}
            size="small"
            IconComponent={FilterAlt}
          >
            {!min24hViews && (
              <MenuItem value="excludeDeadVideos">Exclude dead videos</MenuItem>
            )}
            {title === null && (
              <MenuItem value="includeWordInTitle">
                Include in title or transcript
              </MenuItem>
            )}
          </Select>
        )}
      </Box> */}
      <Box mt={2} display="flex" flexDirection="column" gap={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            width={150}
          >
            <Typography
              fontWeight="bold"
              noWrap
              sx={{ textOverflow: "ellipsis" }}
            ></Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            minWidth={0}
            flexGrow={1}
          >
            <Typography
              fontWeight="bold"
              noWrap
              sx={{ textOverflow: "ellipsis" }}
            ></Typography>
          </Box>
          <Box textAlign="center" display="flex" flexDirection="row">
            {videoTableHeaders.map((header) => (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="end"
                width="100px"
              >
                <Tooltip title={header.label} placement={"top"}>
                  <Typography
                    fontSize={12}
                    sx={{ fontWeight: "bold" }}
                    color="text.secondary"
                    noWrap
                  >
                    {header.label}
                  </Typography>
                </Tooltip>
              </Box>
            ))}
          </Box>
        </Box>
        {loading &&
          Array(rowsPerPage)
            .fill(0)
            .map(() => (
              <Box height={110} width="100%" borderRadius={2} overflow="hidden">
                <Skeleton variant="rectangular" height="100%" width="100%" />
              </Box>
            ))}
        {!loading &&
          videos?.map((video) => <SingleVideoRowDisplay video={video} />)}
      </Box>
    </Box>
  );
};

export default VideoRowDisplay;
