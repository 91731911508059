import { Close, NotificationsActiveRounded } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";

import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useParams } from "react-router-dom";
import nFormatter from "../utils/numberFormatter";

const ChannelHeader = ({ channelData }) => {
  const { id } = useParams();
  // const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [result, setResult] = useState(null);
  // const [classification, setClassification] = useState("Topics");
  // const [error, setError] = useState(null);
  // const debugMode = useContext(DebugModeContext);

  // const generateTopicsLoading = useSelector(
  //   (state) => state.channel.channelTopicInsights.loading
  // );

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: 180,
        position: "relative",
      }}
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message="Updated topics for the channel with custom prompt"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        }
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 3,
        }}
      >
        {channelData ? (
          <>
            <img
              style={{ height: 120, width: 120, borderRadius: 60 }}
              src={channelData?.channel?.thumbnail_url}
              alt="Channel / Video Name"
            />
            <Box>
              <Typography variant="h5">{channelData?.channel?.name}</Typography>
              {/* <Typography >{channelData?.channel?.handle}</Typography> */}
            </Box>
          </>
        ) : (
          <>
            <Skeleton variant="circular" width={120} height={120} />
            <Skeleton
              variant="rectangular"
              sx={{ borderRadius: 1 }}
              width={100}
              height={30}
            />
          </>
        )}
      </Box>
      {channelData ? (
        <Box
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography color="text.secondary" sx={{ opacity: 0.5 }}>
            General
          </Typography>
          <Typography variant="h6">
            {`${nFormatter(channelData?.channel?.video_count)} videos`}
          </Typography>
          <Typography variant="h6">{`${nFormatter(
            channelData?.channel?.subscriber_count
          )} subscribers`}</Typography>
          <Typography variant="h6">
            {`${nFormatter(channelData?.channel?.view_count)} views `}
          </Typography>
          <Typography variant="h6">
            {`${nFormatter(
              channelData?.averageTitleChanges
            )} average title changes / video`}
          </Typography>
          <Typography variant="h6">
            {`${nFormatter(
              channelData?.averageThumbnailChanges
            )} average thumbnail changes / video`}
          </Typography>
        </Box>
      ) : (
        <Box
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Skeleton count={3} height="33%" width={150} />
          <Skeleton count={3} height="33%" width={150} />
          <Skeleton count={3} height="33%" width={150} />
          <Skeleton count={3} height="33%" width={150} />
        </Box>
      )}
      {channelData ? (
        <Box
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography color="text.secondary" sx={{ opacity: 0.5 }}>
            Last 24 hours
          </Typography>
          <Typography variant="h6">{`${nFormatter(
            channelData?.short_views_count
          )} views last 24 hours for shorts`}</Typography>
          <Typography variant="h6">{`${nFormatter(
            channelData?.video_views_count
          )} views last 24 hours for videos`}</Typography>
          <Typography variant="h6">{`${nFormatter(
            channelData?.total_views_count
          )} total views last 24 hours`}</Typography>{" "}
        </Box>
      ) : (
        <Box
          height="100%"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Skeleton count={3} height="33%" width={150} />
          <Skeleton count={3} height="33%" width={150} />
          <Skeleton count={3} height="33%" width={150} />
          <Skeleton count={3} height="33%" width={150} />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* <CustomPromptModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setClassification={setClassification}
          error={error}
          result={result}
          generateTopicsLoading={generateTopicsLoading}
          channelData={channelData}
          setError={setError}
          setResult={setResult}
          regenerateChannelTopics={regenerateChannelTopics}
          classification={classification}
          setSnackbarOpen={setSnackbarOpen}
        /> */}
        {channelData ? (
          <>
            <Button
              href={`/channels/${id}/insights`}
              sx={{
                textTransform: "none",
                color: "text.primary",
              }}
            >
              <NotificationsActiveRounded />
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Insights
              </Typography>
            </Button>
            {/* {debugMode.debugMode && (
              <Button
                sx={{
                  textTransform: "none",
                  color: "text.primary",
                }}
                onClick={() => setModalOpen(true)}
              >
                <AutoFixHighIcon sx={{ marginRight: 1 }} />
                Custom classification prompt
              </Button>
            )} */}
            <Button
              target="_blank"
              href={`https://youtube.com/channel/${channelData?.channel?.channel_id}`}
              sx={{
                textTransform: "none",
                color: "text.primary",
              }}
            >
              <b>View channel</b>
              <LaunchIcon sx={{ marginLeft: 1, width: 16 }} />
            </Button>
          </>
        ) : (
          <>
            <Skeleton width={150} height={30} />
            <Skeleton width={150} height={30} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ChannelHeader;
