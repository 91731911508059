import { Box, Typography } from "@mui/material";
import { React, useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Changelog from "../components/Changelog";
import ChannelHeader from "../components/ChannelHeader";
import Chart from "../components/Chart";
import FilterBar from "../components/FilterBar";
import RecommendedVideosDisplayChannel from "../components/RecommendedVideosDisplayChannel";
import useChannelFilters from "../constants/channelFilters";
import useChannelData from "../hooks/useChannelData";
import useFilters from "../hooks/useFilters";
import {
  channelChangelogs,
  channelChartData,
  channelCompare,
  channelCompareShorts,
  channelCompareVideos,
  channelInfo,
} from "../redux/actions/channelActions";
import { allVideos as getAllVideos } from "../redux/actions/videoActions";
import { clearChannelCompare } from "../redux/channelSlice";
import Videos from "./Videos";

const GRAPHS = [
  { id: "view_count", name: "Views" },
  { id: "subscriber_count", name: "Subscribers" },
  { id: "video_count", name: "Videos" },
];

const selected = {
  backgroundColor: "#4952da",
  color: "white",
};

const notSelected = {
  backgroundColor: "background.paper",
  borderTopColor: "transparent",
};

const Channel = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedGraph, setSelectedGraph] = useState(GRAPHS[0].id);
  const [changelogLength, setChangelogLength] = useState(4);
  const [filterTwoWeeks, setFilterTwoWeeks] = useState(null);

  const {
    info,
    loading,
    rawChangelogs,
    changelogsLoading,
    allVideos,
    allVideosLoading,
    chartData,
    chartLoading,
    compareY,
    compareY2,
    compareY3,
  } = useSelector((state) => {
    return {
      info: state.channel.channelInfo.data,
      rawChangelogs: state.channel.changelogs.data,
      changelogsLoading: state.channel.changelogs.loading,
      loading: state.channel.channelInfo.loading,
      chartData: state.channel.channelChartData.data,
      chartLoading: state.channel.channelChartData.loading,
      allVideosLoading: state.video.allVideos.loading,
      allVideos: state.video.allVideos.videos,
      compareY: state.channel.channelCompare.data,
      compareY2: state.channel.channelCompareShorts.data,
      compareY3: state.channel.channelCompareVideos.data,
    };
  });

  const channelFilters = useChannelFilters();

  const { activeFilters, inactiveFilters, activeValues, setActiveValues } =
    useFilters(channelFilters);

  // useInterval({ setActiveValues, activeValues });

  const handleRangeChange = (range) => {
    setActiveValues((prev) => ({
      ...prev,
      range,
    }));
  };

  useEffect(() => {
    dispatch(clearChannelCompare());
    if (
      activeValues?.compare ||
      activeValues?.displayData === "videos_and_shorts"
    ) {
      dispatch(
        channelCompare({
          id: activeValues?.channelId || id,
          range:
            activeValues?.displayData === "videos_and_shorts"
              ? activeValues?.range
              : activeValues?.compareRange,
          interval: activeValues?.interval,
          type:
            activeValues?.displayData === "view_count"
              ? "view_count"
              : "shorts_view_count",
        })
      );
      if (
        activeValues?.displayData === "videos_and_shorts" &&
        activeValues?.compare
      ) {
        dispatch(
          channelCompareShorts({
            id: activeValues?.channelId || id,
            range: activeValues?.compareRange,
            interval: activeValues?.interval,
            type: "shorts_view_count",
          })
        );
        dispatch(
          channelCompareVideos({
            id: activeValues?.channelId || id,
            range: activeValues?.compareRange,
            interval: activeValues?.interval,
            type: "view_count",
          })
        );
      }
    }
  }, [
    dispatch,
    id,
    activeValues?.channelId,
    activeValues?.compareRange,
    activeValues?.interval,
    activeValues?.compare,
    activeValues?.displayData,
    activeValues?.range,
    selectedGraph,
  ]);

  useEffect(() => {
    if (!id) return;
    dispatch(channelInfo({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(channelChangelogs({ id, filterTwoWeeks }));
  }, [dispatch, id, filterTwoWeeks]);

  useEffect(() => {
    dispatch(getAllVideos({ channelId: id, range: activeValues?.range }));
  }, [dispatch, id, activeValues?.range]);

  useEffect(() => {
    dispatch(
      channelChartData({
        id,
        interval: activeValues?.interval,
        range: activeValues?.range,
        type:
          activeValues?.displayData === "videos_and_shorts"
            ? "view_count"
            : activeValues?.displayData,
      })
    );
  }, [
    dispatch,
    id,
    activeValues.interval,
    activeValues?.range,
    activeValues?.displayData,
    selectedGraph,
  ]);

  const { xData, yData, lines, changelogs } = useChannelData({
    channelData: chartData,
    rawChangelogs,
    range: activeValues?.range,
    videos: allVideos,
  });

  return (
    <Box
      sx={{
        padding: "20px",
        flexDirection: "column",
        display: "flex",
        gap: 3,
        position: "relative",
      }}
    >
      <ChannelHeader channelData={info} />
      <FilterBar
        activeFilters={activeFilters}
        inactiveFilters={inactiveFilters}
        activeValues={activeValues}
        setActiveValues={setActiveValues}
        type="channel"
      />
      <Box display="flex">
        {GRAPHS.map((graph) => (
          <>
            <Box
              onClick={() => setSelectedGraph(graph.id)}
              p={3}
              sx={{
                cursor: "pointer",
                width: 0,
                flex: "1 1 0",
                textAlign: "center",
                fontWeight: "bold",
                borderRadius: 2,
                position: "relative",
                overflow: "hidden",
                ...(selectedGraph === graph.id ? selected : notSelected),
              }}
            >
              {graph.name}
            </Box>
          </>
        ))}
      </Box>
      <Chart
        cumulative={!activeValues?.interval}
        x={xData}
        y={yData}
        compareY={compareY}
        compareY2={compareY2}
        compareY3={compareY3}
        lines={lines}
        setRange={handleRangeChange}
        loading={chartLoading}
        showTotal={activeValues?.showTotal}
      />
      <RecommendedVideosDisplayChannel id={id} />
      <Changelog
        loading={changelogsLoading}
        data={changelogs}
        showVideoLink
        changelogLength={changelogLength}
        setChangelogLength={setChangelogLength}
        filterTwoWeeks={filterTwoWeeks}
        setFilterTwoWeeks={setFilterTwoWeeks}
      />
      <Typography sx={{ marginTop: 1, marginBottom: 1 }} variant="h6">
        <b>Videos</b>
      </Typography>
      <Videos
        channelId={id}
        channelIdLoading={allVideosLoading || loading}
        showSavedFilters={false}
      />
    </Box>
  );
};

export default Channel;
