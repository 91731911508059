import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

const SingleVideoDisplay = ({ video }) => {
  return (
    <Link
      to={`/videos/${video?.video_id}`}
      onClick={() => window.location.reload()}
      style={{ textDecoration: "none", color: "unset" }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography sx={{ fontWeight: "bold" }} variant="bold" mr={1}>
          Comparing against:{" "}
        </Typography>
        <Typography mr={1}>{video?.title}</Typography>
        <img
          style={{ height: 50, width: 50 }}
          src={video?.thumbnail_url}
          alt="video / Video Name"
        />
      </Box>
    </Link>
  );
};

export default SingleVideoDisplay;
