import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import * as React from "react";

const displayNames = {
  likes: "Likes",
  comments: "Comments",
  views: "Views",
  view_count: "Views",
  video_count: "Video count",
  subscriber_count: "Subscriber count",
  title: "Title",
  thumbnail_title: "Thumbnail & title",
  thumbnail: "Thumbnail",
};

const Multiselect = ({
  values,
  setSelectedValues,
  selectedValues,
  title,
  sx,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <FormControl sx={{ minWidth: 150 }}>
        <InputLabel
          sx={{
            mt: -1.5,
            "&.Mui-focused": {
              mt: 0,
            },
            "&.MuiInputLabel-shrink": {
              mt: 0,
            },
          }}
          id="demo-multiple-checkbox-label"
        >
          {title}
        </InputLabel>
        <Select
          sx={{
            ...sx,
            "& .MuiSelect-select": {
              py: 0.5,
            },
          }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label="Values" />}
          renderValue={(selected) =>
            selected.map((s) => displayNames[s] || s).join(", ")
          }
        >
          {values.map((value) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={selectedValues.indexOf(value) > -1} />
              <ListItemText primary={displayNames[value]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Multiselect;
