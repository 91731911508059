import { Grid, Modal, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import {
    allRecommendedVideos
} from "../redux/actions/videoActions";

const RecommendationsModal = ({ id, open, setOpen }) => {
  const dispatch = useDispatch();

  const { recommendedVideos, loading } = useSelector((state) => ({
    recommendedVideos: state.video.allRecommendedVideos.data,
    loading: state.video.allRecommendedVideos.loading,
  }));

  useEffect(() => {
    dispatch(allRecommendedVideos({ id }));
  }, [dispatch, id]);

  const parsedVideos = useMemo(() => {
    if (!recommendedVideos) return;
    // from recommended videos which is an array that contains objects with video_id, index
    // we want to create an array with the one that appear the most times at the top and count and the indexes where it appears
    const videos = recommendedVideos?.reduce((acc, video) => {
      if (acc[video.url]) {
        acc[video.url].count += 1;
        acc[video.url].indexes.push(video.index);
      } else {
        acc[video.url] = {
          count: 1,
          indexes: [video.index],
          ...video,
        };
      }
      return acc;
    }, {});

    // we want to sort the videos by the count
    const sortedVideos = Object.values(videos).sort(
      (a, b) => b.count - a.count
    );

    return sortedVideos;
  }, [recommendedVideos]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90vw",
          minWidth: "90vw",
          maxHeight: "90vh",
          overflowY: "auto",
          alignItems: "center",
          justifyContent: "center",
        }}
        display="flex"
        flexDirection="row"
        gap={3}
        backgroundColor="background.paper"
        padding={2}
        borderRadius={2}
        boxShadow={3}
        flexWrap="wrap"
      >
        {loading &&
          Array.from(Array(6)).map((_, index) => (
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={index}>
              <Box display="flex" flexDirection="column" gap={1}>
                <Box
                  height={200}
                  width={300}
                  overflow="hidden"
                  borderRadius={2}
                >
                  <Skeleton variant="rectangular" height="100%" width="100%" />
                </Box>
                <Skeleton />
                <Skeleton />
              </Box>
            </Grid>
          ))}
        {!loading &&
          parsedVideos?.map((video) => (
            <a
              href={`https://www.youtube.com${video?.url}`}
              style={{
                textDecoration: "none",
                color: "unset",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    alignItems: "center",
                  }}
                  p={2}
                >
                  <img
                    style={{
                      height: 165,
                      width: 300,
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: 5,
                    }}
                    src={video?.thumbnail}
                    alt="video / Video Name"
                  />
                  <Box minWidth={0} pt={1.5} color="text.primary">
                    <Typography
                      maxWidth={300}
                      fontSize="18px"
                      fontWeight="bold"
                      noWrap
                      sx={{ textOverflow: "ellipsis" }}
                    >
                      {video?.title}
                    </Typography>
                  </Box>
                </Box>
                {/* display the number of times the video appears and the indexes where it appears */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography fontSize="14px">
                    <b>Number of times recommended:</b> {video.count}
                  </Typography>
                  <Typography fontSize="14px">
                    <b>Positions where it appears:</b>{" "}
                    {video.indexes.join(", ")}
                  </Typography>
                </Box>
              </Box>
            </a>
          ))}
      </Box>
    </Modal>
  );
};

export default RecommendationsModal;
