import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DebugModeContext } from "../App";
import Logo from "../img/logo.png";
import { mainMenuItems } from "../navigation/navItems";

const MainTopBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const debugMode = useContext(DebugModeContext);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
      backgroundColor="#2b2d4b"
    >
      <Toolbar>
        <Box
          padding={2}
          paddingLeft={0}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Button
              onClick={() => navigate("/channels")}
              sx={{ mr: 3, cursor: "pointer" }}
            >
              <img src={Logo} alt="logo" style={{ height: 40 }} />
            </Button>
            {mainMenuItems.map(
              ({ Icon, name, url, pathRegex, onClick, condition }) =>
                !condition?.() && (
                  <Link
                    key={name}
                    style={{ textDecoration: "none", color: "unset" }}
                    to={url}
                    onClick={onClick}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      backgroundColor={
                        pathRegex.test(location.pathname)
                          ? "#373b90"
                          : "transparent"
                      }
                      sx={{
                        padding: "11px",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                        borderRadius: 1.5,
                        paddingLeft: 4,
                        paddingRight: 4,
                      }}
                    >
                      <Icon sx={{ color: "white" }} />
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "12px",
                          fontWeight: 600,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "12px",
                            fontWeight: 600,
                          }}
                        >
                          {name}
                        </Typography>
                      </Typography>
                    </Box>
                  </Link>
                )
            )}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Select
              value=""
              sx={{
                height: 40,
                width: "fit-content",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                ".MuiSelect-icon": {
                  transform: "scale(1.3) !important",
                },
                ".MuiSelect-iconOpen": {
                  transform: "scale(1.25) !important",
                },
                ".MuiSelect-iconOutlined": {
                  color: "white",
                },
              }}
              IconComponent={AccountCircle}
            >
              {/* <MenuItem
                onClick={(e) => {
                  e.preventDefault();
                  // colorMode.toggleColorMode();
                }}
              >
                Dark mode
                <Switch checked={theme.palette.mode === "dark"} />
              </MenuItem> */}
              {localStorage.getItem("user") === "admin@zvoid.com" && (
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    debugMode.toggleDebugMode();
                  }}
                >
                  Debug mode
                  <Switch checked={debugMode.debugMode} />
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  localStorage.removeItem("accessToken");
                  navigate("/login");
                }}
              >
                Log out
              </MenuItem>
            </Select>
          </Box>
        </Box>
      </Toolbar>
      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
      <Divider />
    </Box>
  );
};

export default MainTopBar;
