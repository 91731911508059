import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Button, Skeleton, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  LabelList,
  Legend,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import getImageUrl from "../utils/getImageUrl";
import nFormatter from "../utils/numberFormatter";
import ChangeIcon from "./ChangeIcon";

const CustomLegend = ({ payload, isComparing }) => {
  const items = payload.filter((entry) => entry.dataKey !== "uv");

  const labels = {
    label: "Data",
    compareLabel: isComparing ? "Compare data" : "",
  };

  return (
    <ul>
      {items
        .filter((entry) => labels[entry.dataKey])
        .map((entry) => (
          <li key={entry.dataKey} style={{ color: entry.color }}>
            {labels[entry.dataKey]}
          </li>
        ))}
    </ul>
  );
};

const Chart = ({
  x,
  y,
  compareY: cY,
  compareY2: cY2,
  compareY3: cY3,
  lines,
  setRange,
  loading,
  cumulative,
  showTotal,
}) => {
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);

  const maxValue = useMemo(() => {
    return Math.max(...(y?.map((t) => parseInt(t)).filter((t) => t) || [0]));
  }, [y]);

  const minValue = useMemo(() => {
    return Math.min(...(y?.map((t) => parseInt(t)).filter((t) => t) || [0]));
  }, [y]);

  const data = useMemo(() => {
    const min = !showTotal
      ? Math.min(...(y?.map((t) => parseInt(t)).filter((t) => t) || [0]))
      : 0;
    const minCompare = !showTotal
      ? Math.min(...(cY?.map((t) => parseInt(t)).filter((t) => t) || [0]))
      : 0;
    const minCompare2 = !showTotal
      ? Math.min(...(cY2?.map((t) => parseInt(t)).filter((t) => t) || [0]))
      : 0;

    const minCompare3 = !showTotal
      ? Math.min(...(cY3?.map((t) => parseInt(t)).filter((t) => t) || [0]))
      : 0;

    return x
      ?.map((x, i) => ({
        name: Date.parse(x).valueOf(),
        label:
          cumulative || i === 0
            ? parseInt(y?.[i]) - (cumulative ? min : 0)
            : parseInt(y?.[i]) - parseInt(y?.[i - 1]),
        compareLabel:
          cY?.[i] &&
          (cumulative || i === 0
            ? parseInt(cY?.[i]) - (cumulative ? minCompare : 0)
            : parseInt(cY?.[i]) - parseInt(cY?.[i - 1])),
        ...(cY2?.[i]
          ? {
              compareLabel2:
                cumulative || i === 0
                  ? parseInt(cY2?.[i]) - (cumulative ? minCompare2 : 0)
                  : parseInt(cY2?.[i]) - parseInt(cY2?.[i - 1]),
            }
          : {}),
        ...(cY3?.[i]
          ? {
              compareLabel3:
                cumulative || i === 0
                  ? parseInt(cY3?.[i]) - (cumulative ? minCompare3 : 0)
                  : parseInt(cY3?.[i]) - parseInt(cY3?.[i - 1]),
            }
          : {}),
      }))
      .slice(!cumulative ? 1 : 0);
  }, [x, y, cY, cY2, cY3, cumulative, showTotal]);

  const handleMouseDown = (e) => {
    setStartPoint(e.activeLabel);
  };

  const renderCustomizedLabel = (props, color, i, minValue, maxValue) => {
    const { x, y, index } = props;

    if (index === tooltipData?.tooltipIndex) {
      // get percentage y of value
      const percentage0 =
        ((parseInt(tooltipData?.y?.[0]) - minValue) / (maxValue - minValue)) *
        100;
      const percentage1 =
        ((parseInt(tooltipData?.y?.[1]) - minValue) / (maxValue - minValue)) *
        100;

      // difference between the two values
      const difference =
        tooltipData?.y?.[1] &&
        ((tooltipData?.y?.[0] / tooltipData?.y?.[1]) * 100 - 100)?.toFixed(2);

      const improvedY = cumulative
        ? Math.abs(percentage0 - percentage1) < 10
          ? y - 10 + i * 30
          : y - 10
        : y;

      if (!tooltipData?.y?.[i]) return null;
      return (
        <g>
          <text
            x={x}
            y={improvedY || 100}
            fill={color}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              textShadow:
                "1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff",
            }}
          >
            {tooltipData?.y?.[i]?.toLocaleString() === "NaN"
              ? "No data"
              : `${tooltipData?.y?.[i]?.toLocaleString()}${
                  difference && i === 0 && !cY2
                    ? ` (
                ${difference > 0 ? "+" : ""}
                    ${difference}%)`
                    : ``
                }`}
          </text>
        </g>
      );
    } else {
      return null;
    }
  };

  const handleMouseMove = (e) => {
    setTooltipData({
      x: e?.activeLabel,
      tooltipIndex: e?.activeTooltipIndex,
      y: e?.activePayload?.map((i) => i?.value),
    });
    startPoint && setEndPoint(e?.activeLabel);
  };

  const handleMouseUp = (e) => {
    if (Math.abs(e.activeLabel - startPoint) > 1800000) {
      setRange([
        {
          startDate: new Date(Math.min(startPoint, e.activeLabel)),
          endDate: new Date(Math.max(startPoint, e.activeLabel)),
          key: "selection",
        },
      ]);
    }
    setStartPoint(null);
    setEndPoint(null);
  };

  if (loading) {
    return (
      <Box
        width="100%"
        height="300px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius={1}
        overflow="hidden"
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>
    );
  }

  if (y?.every((y) => y === 0)) {
    return (
      <Box
        // width="100%"
        height="200px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>
          No data for the selected dates. Please select a different date range.
        </Typography>
        <Button onClick={() => window.location.reload()}>
          <RestartAltIcon />
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 2,
        paddingRight: 3,
        borderRadius: 4,
        backgroundColor: "background.card",
      }}
    >
      {!!data?.length && (
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            margin={{ top: 50, right: 50, left: 0, bottom: 20 }}
            data={data}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4b54db" stopOpacity={1} />
                <stop offset="100%" stopColor="#4b54db" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="compareColorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3EB277" stopOpacity={1} />
                <stop offset="100%" stopColor="#3EB277" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="compareColorUv2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#f09b4d" stopOpacity={1} />
                <stop offset="100%" stopColor="#f09b4d" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="compareColorUv3" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ff7ab8" stopOpacity={1} />
                <stop offset="100%" stopColor="#ff7ab8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              axisLine={{
                stroke: "#e0e0e0",
                strokeWidth: 1,
                strokeDasharray: "3 5",
              }}
              type="number"
              scale="time"
              domain={["auto", "dataMax"]}
              tick={{ fontSize: "12px", strokeWidth: 1 }}
              dataKey="name"
              tickFormatter={(tick) =>
                format(new Date(tick), "dd-MM-yyy HH:mm")
              }
              style={{ userSelect: "none" }}
            />
            <YAxis
              axisLine={{
                stroke: "#e0e0e0",
                strokeWidth: 1,
                strokeDasharray: "3 5",
              }}
              domain={cumulative ? ["dataMin", "auto"] : [0, "auto"]}
              tickFormatter={(tick) => nFormatter(tick)}
              tick={{ fontSize: "12px" }}
              orientation="left"
              style={{ userSelect: "none" }}
            />
            {!cumulative ? (
              <Bar
                radius={[15, 15, 0, 0]}
                maxBarSize={20}
                // start from 0

                fill="#4049d8"
                dataKey="label"
                width={2}
              >
                <LabelList
                  dataKey="name"
                  content={(a) =>
                    renderCustomizedLabel(a, "#4b54db", 0, minValue, maxValue)
                  }
                />
              </Bar>
            ) : (
              <Area
                strokeWidth={2}
                strokeOpacity={0.8}
                stroke="#4b54db"
                fill="url(#colorUv)"
                fillOpacity={0.2}
                dataKey="label"
              >
                <LabelList
                  dataKey="name"
                  content={(a) =>
                    renderCustomizedLabel(a, "#4b54db", 0, minValue, maxValue)
                  }
                />
              </Area>
            )}
            <Area
              strokeOpacity={0.8}
              strokeWidth={2}
              stroke="#3EB277"
              fill="url(#compareColorUv)"
              fillOpacity={0.2}
              dataKey="compareLabel"
            >
              <LabelList
                dataKey="name"
                content={(a) =>
                  renderCustomizedLabel(a, "#3EB277", 1, minValue, maxValue)
                }
              />
            </Area>
            <Area
              strokeOpacity={0.8}
              strokeWidth={2}
              stroke="#f09b4d"
              fill="url(#compareColorUv)"
              fillOpacity={0.2}
              dataKey="compareLabel2"
            >
              <LabelList
                dataKey="name"
                content={(a) =>
                  renderCustomizedLabel(a, "#f09b4d", 2, minValue, maxValue)
                }
              />
            </Area>
            <Area
              strokeOpacity={0.8}
              strokeWidth={2}
              stroke="#ff7ab8"
              fill="url(#compareColorUv3)"
              fillOpacity={0.2}
              dataKey="compareLabel3"
            >
              <LabelList
                dataKey="name"
                content={(a) =>
                  renderCustomizedLabel(a, "#ff7ab8", 3, minValue, maxValue)
                }
              />
            </Area>
            <ReferenceLine x={tooltipData?.x}>
              <Label
                value={
                  tooltipData?.x
                    ? format(new Date(tooltipData?.x), "dd-MM-yyy HH:mm")
                    : ""
                }
                fill="black"
                position="insideBottom"
              />
            </ReferenceLine>
            {lines?.map((line) => (
              <ReferenceLine
                x={line.created_at}
                stroke="#4b54db"
                strokeDasharray="3 5"
                opacity={0.3}
                strokeWidth={2}
                label={
                  <Label
                    position="top"
                    content={(v) => renderContent(v, line)}
                  />
                }
              />
            ))}
            {startPoint && endPoint && (
              <ReferenceArea x1={startPoint} x2={endPoint} />
            )}
            <Legend
              verticalAlign="middle"
              align="right"
              layout="vertical"
              content={<CustomLegend isComparing={!!cY || !!cY2 || !!cY3} />}
              wrapperStyle={{ right: 30 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

const renderInnerContent = ({ type, value }) => {
  if (["thumbnail_title", "video"].includes(type)) {
    return (
      <Box display="flex" flexDirection="column" maxWidth={150}>
        <img
          style={{ paddingBottom: 5, paddingTop: 5 }}
          alt="changed"
          width={150}
          height={(150 * 9) / 16}
          src={getImageUrl(value.thumbnail)}
        />
        {value.title}
      </Box>
    );
  } else if (type === "thumbnail") {
    return (
      <img alt="changed" width={120} height={80} src={getImageUrl(value)} />
    );
  } else if (type === "title") {
    return value;
  } else if (type === "status") {
    return value;
  }
};

const renderContent = ({ viewBox: { x, y } }, value) => {
  const d = 40;
  const r = d / 2;

  const transform = `translate(${x - r} ${y - d - 130})`;

  return (
    <Tooltip
      placement="top"
      title={renderInnerContent({ type: value.action, value: value.new_value })}
    >
      <g transform={transform}>
        <svg viewBox="0 0 20 20" width="40">
          <circle fill="transparent" cx="10" cy="10" r="10"></circle>
          <g transform="translate(4 0)" viewBox="0 0 20 10">
            <Link to={`/videos/${value.videoId}`}>
              <ChangeIcon action={value.action} small={true} />
            </Link>
          </g>
        </svg>
      </g>
    </Tooltip>
  );
};

export default Chart;
