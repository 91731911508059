import {
  Alert,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../redux/actions/userActions";
import Container from "./Container";
import tracker from "../utils/tracker";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSignIn = async () => {
    setLoading(true);
    dispatch(loginUser({ email, password }))
      .unwrap()
      .then(async () => {
        tracker.setUserID(email);
        navigate("/channels");
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false);
      });
  };

  return (
    <Container>
      <Typography variant="h4">Welcome to 1 from 10</Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "13px",
          letterSpacing: "0em",
          textAlign: "left",
          opacity: "0.5",
        }}
      >
        Don't have an accont?{" "}
        <Link
          to="/signup"
          style={{
            color: "#65DC9F",
            textDecoration: "none",
            opacity: "1",
            fontWeight: "bold",
          }}
        >
          Click here
        </Link>
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "13px",
          letterSpacing: "0em",
          textAlign: "left",
          opacity: "0.5",
        }}
      >
        Email Address
      </Typography>
      <TextField
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        fullWidth
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid background.paper",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid background.paper",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid background.paper",
            },
        }}
      />
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "13px",
          letterSpacing: "0em",
          textAlign: "left",
          opacity: "0.5",
        }}
      >
        Password
      </Typography>
      <TextField
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
        fullWidth
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid  background.paper",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid  background.paper",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid  background.paper",
            },
        }}
      />
      {error && (
        <Alert severity="error">
          There was an error while logging in.
          <br />
          Please try again
        </Alert>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 3,
          marginBottom: 3,
          width: "100%",
        }}
      >
        <Button
          onClick={onSignIn}
          onKeyDown={(e) => e.key === "Enter" && onSignIn()}
          sx={{
            textTransform: "none",
            width: "100%",
            backgroundColor: "#65DC9F",
            padding: 1,
            color: "black",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#65DC9F",
            },
            "&:disabled": {
              backgroundColor: "#65DC9F",
              opacity: 0.5,
            },
          }}
          variant="contained"
          // set disabled to true if loading is true
          disabled={loading || !email || !password}
        >
          {loading ? <CircularProgress /> : "Login"}
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
