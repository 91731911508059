import { InfoRounded } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Button, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip"; 
import { format, formatDistanceToNow } from "date-fns";
import React from "react";
import getImageUrl from "../utils/getImageUrl";
import nFormatter from "../utils/numberFormatter";
import Label from "./Label";

const renderValue = (value) => {
  if (typeof value === "object") {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        {renderValue(value.thumbnail)}
        {renderValue(value.title)}
      </Box>
    );
  }
  if (/^\d+$/.test(value)) {
    return (
      <Tooltip title={value}>
        <Typography ml={1} noWrap>
          {nFormatter(value)}
        </Typography>
      </Tooltip>
    );
  }
  if (value.startsWith("data:image") || value.endsWith(".jpg")) {
    const src = getImageUrl(value);

    return (
      <Tooltip
        title={
          <img
            style={{
              width: 250,
              height: 140,
              objectFit: "cover",
              objectPosition: "center",
            }}
            alt="changed img"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "https://via.placeholder.com/70x50?text=Image+not+found";
            }}
            src={src}
          />
        }
      >
        <img
          style={{
            borderRadius: 2,
            overflow: "hidden",
            width: 70,
            height: 50,
            objectFit: "cover",
            objectPosition: "center",
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
              "https://via.placeholder.com/70x50?text=Image+not+found";
            currentTarget.style.pointerEvents = "none";
          }}
          alt="changed img"
          src={src}
        />
      </Tooltip>
    );
  }
  return (
    <Typography ml={1} noWrap>
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </Typography>
  );
};

const ChangelogStats = ({ change, showVideoLink }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "calc(100% - 100px)",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
      >
        {renderValue(change?.old_value)}
        <ArrowRightIcon />
        {renderValue(change?.new_value)}
      </Box>
      <Box display="flex" flexDirection="row">
        <Box>
          {change?.change && (
            <Tooltip title={change?.averagedChange}>
              <Box>
                <Label color="success">
                  <Typography variant="body2">{change?.change}</Typography>
                  <InfoRounded
                    sx={{ marginLeft: 0.5, height: 15, width: 15, padding: 0 }}
                  />
                </Label>
              </Box>
            </Tooltip>
          )}
        </Box>
        <Label
          sx={{
            overflow: "hidden",
            justifyContent: "right",
            marginLeft: 1,
          }}
        >
          <Tooltip
            title={format(new Date(change?.created_at), "yyyy-MM-dd HH:mm")}
          >
            <Typography noWrap>
              {formatDistanceToNow(new Date(change?.created_at))} ago
            </Typography>
          </Tooltip>
        </Label>
      </Box>
      {change?.videoId && showVideoLink && (
        <Button href={`/videos/${change?.videoId}`}>Go to video</Button>
      )}
    </Box>
  );
};

export default ChangelogStats;
