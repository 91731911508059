import { Add, ViewList, ViewModule } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Skeleton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { allChannels, getChannelId } from "../redux/actions/channelActions";
import user from "../User.jpeg";
import nFormatter from "../utils/numberFormatter";
const Channels = () => {
  const dispatch = useDispatch();

  const { channels } = useSelector((state) => ({
    channels: state.channel.allChannels.channels,
  }));

  const [open, setOpen] = useState(false);
  const [textInputValue, setTextInputValue] = useState("");
  const [view, setView] = useState("module"); // ['list', 'module']
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const searchChannel = (value) => {
    dispatch(getChannelId({ id: value }))
      .then(unwrapResult)
      .then((result) => {
        console.log(result);
        navigate(`/channels/${result}`);
      });
    setTextInputValue("");
  };

  const onChannelSearchSubmit = () => {
    searchChannel(textInputValue);
  };

  const onTextFieldKeyDown = async (e) => {
    if (e.key === "Enter") {
      searchChannel(textInputValue);
    }
  };

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  useEffect(() => {
    dispatch(allChannels());
  }, [dispatch]);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
          mb={2}
        >
          <Typography
            color="text.primary"
            variant="h5"
            sx={{
              fontWeight: "bold",
              //center vertically
              display: "flex",
              alignItems: "center",
            }}
          >
            Channels
          </Typography>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleChange}
            size="small"
            sx={{
              maxHeight: "30px",
              maxWidth: "40px",
            }}
          >
            <ToggleButton value="list" aria-label="list">
              <ViewList sx={{ fontSize: "20px" }} />
            </ToggleButton>
            <ToggleButton value="module" aria-label="module">
              <ViewModule sx={{ fontSize: "20px" }} />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {localStorage.getItem("user") === "admin@zvoid.com" && (
          <Button onClick={handleOpen}>
            <Typography color="text.primary" display="flex" alignItems="center">
              <Add />
              Add channel
            </Typography>
          </Button>
        )}
      </Box>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Add chanel</DialogTitle>
        <Box paddingLeft={2} paddingRight={2} paddingBottom={2} width={500}>
          <TextField
            value={textInputValue}
            onChange={(e) => setTextInputValue(e.target.value)}
            onKeyDown={onTextFieldKeyDown}
            size="small"
            sx={{ width: "100%" }}
            label="Channel URL"
            variant="outlined"
          />
          <Button
            onClick={onChannelSearchSubmit}
            sx={{ float: "right", marginTop: 1, color: "black" }}
          >
            Submit
          </Button>
        </Box>
      </Dialog>

      <Grid
        container
        spacing={{ xs: 2, xl: 4 }}
        columns={{ xs: 2, sm: 3, xl: 8 }}
        alignContent="flex-start"
      >
        {!channels &&
          [1, 2, 3, 4, 5].map((item) => (
            <Grid item xs={2} sm={3} xl={4} key={item}>
              <Box display="flex" flexDirection="row" gap={2}>
                <Skeleton width={150} height={150} />
                <Box flexGrow={1} p={2}>
                  <Skeleton width={100} />
                  <Skeleton count={5} width="100%" />
                </Box>
                <Box
                  width="290px"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                >
                  <Skeleton width={80} height={50} />
                  <Skeleton width={80} height={50} />
                  <Skeleton width={80} height={50} />
                </Box>
              </Box>
            </Grid>
          ))}
        {channels?.map((channel) => (
          <Grid
            item
            key={channel.id}
            xs={view === "list" ? 2 : 0.5}
            sm={view === "list" ? 3 : 1}
            md={view === "list" ? 3 : 2}
            lg={view === "list" ? 3 : 2}
            xl={view === "list" ? 4 : 2}
          >
            <Link
              to={`/channels/${channel?.channel_id}`}
              style={{ textDecoration: "none", color: "unset" }}
            >
              <Box
                backgroundColor="background.card"
                sx={{
                  display: "flex",
                  height: 150,
                  overflow: "hidden",
                  gap: 1,
                  justifyContent: "space-between",
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      height: 140,
                      width: 140,
                      minWidth: 140,
                      marginTop: 5,
                      marginLeft: 5,
                      borderRadius: 4,
                    }}
                    src={channel.thumbnail_url}
                    alt="Channel / Video Name"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = user;
                    }}
                  />
                  <Box minWidth={0} p={2}>
                    <Typography
                      noWrap
                      color="text.primary"
                      sx={{
                        textOverflow: "ellipsis",
                        marginBottom: 0.5,
                      }}
                      fontSize="18px"
                      fontWeight="bold"
                    >
                      {channel.name}
                    </Typography>
                    {view === "list" && (
                      <Typography
                        fontSize="15px"
                        color="text.secondary"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {channel.description}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {view === "list" && (
                  <Box
                    minWidth="350px"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    color="text.secondary"
                  >
                    <Divider
                      sx={{ marginRight: "10px" }}
                      orientation="vertical"
                      variant="middle"
                      flexItem
                    />
                    <Box
                      sx={{
                        margin: 1,
                        display: "flex",
                        flexDirection: "column",
                        flex: "1 1 0",
                        width: 0,
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h4">
                        {nFormatter(channel.subscriber_count, 1)}
                      </Typography>
                      <Typography fontSize="15px">Subscribers</Typography>
                    </Box>
                    <Box
                      sx={{
                        margin: 1,
                        display: "flex",
                        flexDirection: "column",
                        width: 0,
                        flex: "1 1 0",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h4">
                        {nFormatter(channel.view_count, 1)}
                      </Typography>
                      <Typography fontSize="15px">Views</Typography>
                    </Box>
                    <Box
                      sx={{
                        margin: 1,
                        display: "flex",
                        flexDirection: "column",
                        flex: "1 1 0",
                        width: 0,
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h4">
                        {nFormatter(channel.video_count, 1)}
                      </Typography>
                      <Typography fontSize="15px">Videos</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Channels;
