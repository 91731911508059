import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clockImage from "../img/Clock.png";
import dashboardImage from "../img/Dashboard.png";
import frame112 from "../img/Frame 112.png";
import Logo from "../img/logo.png";
import { signUpInfo } from "../redux/actions/userActions";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      sx={{
        background: "linear-gradient(180deg, #050505 63.04%, #2C3440 100%)",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth="1200px"
        width="100%"
        alignItems="center"
      >
        <Box
          p={2}
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
        >
          <Box>
            <img src={Logo} alt="logo" style={{ height: 40 }} />
          </Box>
          <Box display="flex">
            <Button href="#benefits" sx={{ color: "white" }}>
              Benefits
            </Button>
            <Button href="#pricing" sx={{ color: "white" }}>
              Pricing
            </Button>
          </Box>
          <Box display="flex">
            <Button
              variant="outlined"
              onClick={() => navigate("/login")}
              sx={{
                borderColor: "white",
                color: "white",
                opacity: 0.5,
                "&:hover": {
                  borderColor: "white",
                  color: "white",
                  opacity: 0.8,
                },
              }}
            >
              Log in
            </Button>
          </Box>
        </Box>
        <Typography
          sx={{
            padding: 2,
            paddingTop: 5,
            fontSize: "40px",
            fontWeight: "700",
            lineHeight: "60px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#FFFFFF",
          }}
        >
          Save hours of research when starting a new YouTube Channel
        </Typography>
        <Typography
          sx={{
            padding: 2,
            fontSize: "24px",
            fontWeight: "500",
            lineHeight: "36px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#FFFFFF",
            opacity: 0.5,
          }}
        >
          Analyse any channel on YouTube, as the big channels already do. Stop
          guessing, use data!
        </Typography>
        <Button
          sx={{
            marginTop: 5,
            marginBottom: 7,
            background: "#65DC9F",
            color: "black",
            padding: "30px 40px",
            width: "343px",
            height: "89px",
            "&:hover": {
              background: "#65DC9F",
              color: "black",
              opacity: 0.8,
            },
          }}
          onClick={() => navigate("/signup")}
        >
          Get Started
        </Button>
        <img
          style={{
            width: "100%",
            maxWidth: "1200px",
          }}
          alt=""
          src={dashboardImage}
        />
      </Box>
    </Box>
  );
};

const ClockSection = () => {
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      sx={{
        background: "#1B1A1A",
      }}
      id="benefits"
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        maxWidth={1200}
        width="100%"
      >
        <img alt="" src={clockImage} />
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          p={2}
        >
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: "700",
              lineHeight: "54px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "white",
              marginBottom: 3,
            }}
          >
            You are spending countless hours on competition research when
            launching a new channel?
          </Typography>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "500",
              lineHeight: "33px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "white",
              opacity: 0.5,
            }}
          >
            Find out what content works the best for your competition and follow
            their path!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ImproveContent = () => {
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      sx={{
        background: "#060606",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={1200}
        width="100%"
        paddingTop={10}
      >
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: "700",
            lineHeight: "54px",
            letterSpacing: "0em",
            color: "white",
            marginBottom: 3,
            textAlign: "center",
            px: 2,
          }}
        >
          Want to improve your content, but you don’t know what works and what
          doesn’t?
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "33px",
            letterSpacing: "0em",
            color: "white",
            opacity: 0.5,
            textAlign: "center",
            marginBottom: 8,
            px: 2,
          }}
        >
          You don’t understand what to post, when to post, what thumbnails to
          use. Remove the guess work, and use data to give you the best results!{" "}
        </Typography>
        <img
          style={{
            width: "100%",
            height: "auto",
            maxWidth: 1200,
          }}
          alt=""
          src={frame112}
        />
      </Box>
    </Box>
  );
};

const PricingSection = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => ({
    data: state.user.signUpInfo.data,
  }));

  useEffect(() => {
    dispatch(signUpInfo());
  }, [dispatch]);

  return (
    <Box
      padding={5}
      id="pricing"
      sx={{
        background: "#1B1A1A",
      }}
    >
      <Typography
        sx={{
          fontSize: "36px",
          fontWeight: "700",
          lineHeight: "54px",
          letterSpacing: "0em",
          color: "white",
          marginBottom: 3,
          textAlign: "center",
        }}
      >
        Pricing
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
        width="100%"
        justifyContent="center"
      >
        {data?.map((payment) => (
          <Card
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              cursor: "pointer",
              padding: 3,
              color: "white",
              background: "#1F1F1F",
            }}
          >
            <Typography variant="h6">{payment.nickname}</Typography>
            <Typography variant="h2" fontWeight="bold">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: payment.currency,
              }).format(payment.unit_amount_decimal / 100)}
            </Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              / {payment.recurring.interval}
            </Typography>
            <Typography variant="body2">{payment.description}</Typography>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

const Landing = () => {
  return (
    <>
      <Header />
      <ClockSection />
      <ImproveContent />
      <PricingSection />
    </>
  );
};

export default Landing;
