import {
  Bookmark,
  BookmarkBorderOutlined,
  InfoRounded,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Popover,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { addMinutes, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import {
  allFilters,
  createFilter,
  deleteFilter,
} from "../redux/actions/filterActions";
import { parseFilters, sanitizeFilters } from "../utils/sanitizeFilters";

const getValue = (filter, activeValues) => {
  if (filter.type === "dateRange") {
    const startDate = activeValues[filter.value]?.[0].startDate;
    const endDate = activeValues[filter.value]?.[0].endDate;
    return (
      activeValues[filter.value] &&
      `: ${format(startDate, "MMM dd, yyyy")} - ${format(
        endDate,
        "MMM dd, yyyy"
      )}`
    );
  }
  if (typeof activeValues[filter.value] === "object") {
    return `: ${activeValues[filter.value]?.text} (${
      filter?.options?.find(
        (option) => option.value === activeValues[filter.value]?.select
      )?.label
    })`;
  }

  if (filter.type === "boolean") return "";

  return `: 
      ${
        filter?.options?.find(
          (option) => option.value == activeValues[filter.value] // eslint-disable-line
        )?.label || activeValues[filter.value]
      }
    `;
};

const FilterBar = ({
  activeFilters,
  inactiveFilters,
  activeValues,
  setActiveValues,
  type,
  ignoredValues,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [editingFilter, setEditingFilter] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isBookmarkFilters, setIsBookmarkFilters] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const { data: bookmarkFilters } = useSelector(
    (state) => state.filters.allFilters
  );

  useEffect(() => {
    if (type) dispatch(allFilters({ type }));
  }, [type, dispatch]);

  const handleOpenModal = () => setOpenModal(true);

  const handleAddFilter = () => {
    const sanitizedFilters = sanitizeFilters(activeValues);
    ignoredValues?.forEach((value) => {
      delete sanitizedFilters[value];
    });

    dispatch(
      createFilter({
        type,
        filters: sanitizedFilters,
        name,
      })
    ).then(() => {
      setOpenModal(false);
      setName("");
      dispatch(allFilters({ type }));
      setIsBookmarkFilters(true);
    });
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        mb={0.5}
        minHeight={23}
        flexWrap="wrap"
      >
        {type &&
          bookmarkFilters?.map((filter) => (
            <Button
              sx={{
                textTransform: "none",
                fontWeight: 500,
                fontSize: 14,
                height: 23,
                color: "text.secondary",
                border: "1px solid lightgray",
                padding: "0 5px",
                "&.Mui-disabled": {
                  pointerEvents: "all",
                },
                "&:hover": {
                  borderColor: "lightgray",
                  "& .MuiIconButton-root": {
                    display: "flex",
                  },
                },
              }}
              onClick={() => {
                const sanitizedFilters = parseFilters(filter.filters);
                setOpenSnackbar(true);
                setActiveValues({
                  ...Object.keys(activeValues).reduce((acc, key) => {
                    if (ignoredValues?.includes(key)) {
                      acc[key] = activeValues[key];
                    }
                    return acc;
                  }, {}),
                  ...sanitizedFilters,
                });
                setIsBookmarkFilters(true);
              }}
              key={filter.value}
            >
              {filter.name}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(deleteFilter({ id: filter.id })).then(() => {
                    dispatch(allFilters({ type }));
                  });
                }}
                size="small"
                sx={{
                  // do not display
                  display: "none",
                  p: 0,
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Button>
          ))}
      </Box>
      <Box
        position="relative"
        display="flex"
        gap={1}
        alignItems="center"
        flexWrap="wrap"
        border="1px solid"
        borderColor="divider"
        borderRadius={2}
        padding={1}
        paddingRight={20}
        minHeight={45}
      >
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            display="flex"
            flexDirection="row"
            gap={1}
            backgroundColor="background.paper"
            padding={2}
            borderRadius={2}
            boxShadow={3}
          >
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              size="small"
              label="Filter Name"
            />
            <Button
              onClick={handleAddFilter}
              variant="contained"
              color="primary"
              disabled={!name}
            >
              Save
            </Button>
          </Box>
        </Modal>
        {activeFilters?.map((filter) => (
          <Box
            key={filter.value}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              borderRadius: 1,
              paddingLeft: 1,
              backgroundColor: "background.card",
            }}
          >
            <Typography
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setEditingFilter(filter.value);
              }}
              sx={{ marginRight: 1, fontSize: 15, cursor: "pointer" }}
            >
              <b>{filter.label}</b>
              {editingFilter !== filter.value && getValue(filter, activeValues)}
            </Typography>
            {editingFilter === filter.value && filter.type === "text" && (
              <TextField
                inputProps={{
                  style: {
                    padding: 0,
                    paddingLeft: 5,
                    width: 80,
                  },
                }}
                defaultOpen={true}
                autoFocus={true}
                size="small"
                onBlur={(e) => {
                  setEditingFilter(undefined);
                  setActiveValues({
                    ...activeValues,
                    [filter.value]: e.target.value,
                  });
                  setIsBookmarkFilters(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setEditingFilter(undefined);
                    setActiveValues({
                      ...activeValues,
                      [filter.value]: e.target.value,
                    });
                    setIsBookmarkFilters(false);
                  }
                }}
              />
            )}
            {editingFilter === filter.value && filter.type === "select" && (
              <Select
                onBlur={() => {
                  setEditingFilter(undefined);
                }}
                sx={{ height: 25 }}
                value={activeValues[filter.value]}
                onChange={(e) => {
                  setEditingFilter(undefined);
                  setActiveValues({
                    ...activeValues,
                    [filter.value]: e.target.value,
                  });
                  setIsBookmarkFilters(false);
                }}
                onClose={() => {
                  setEditingFilter(undefined);
                }}
                defaultOpen={editingFilter === filter.value}
              >
                {filter?.options?.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
            {editingFilter === filter.value && filter.type === "dateRange" && (
              <>
                <Box />
                <Popover
                  open={true}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={() => {
                    setEditingFilter(undefined);
                  }}
                >
                  <DateRangePicker
                    className={
                      theme.palette.mode === "dark" && "darkModeDatePicker"
                    }
                    onChange={(item) => {
                      let range = [];
                      if (item?.range1?.endDate?.getHours() === 0) {
                        range = [
                          {
                            startDate: item.range1.startDate,
                            endDate: addMinutes(item.range1.endDate, 1439),
                          },
                        ];
                      } else if (item?.range1) {
                        range = [
                          {
                            startDate: item.range1.startDate,
                            endDate: item.range1.endDate,
                          },
                        ];
                      } else {
                        range = [
                          {
                            startDate: item.selection.startDate,
                            endDate: item.selection.endDate,
                          },
                        ];
                      }

                      setActiveValues({
                        ...activeValues,
                        [filter.value]: range,
                      });
                      setIsBookmarkFilters(false);
                    }}
                    showSelectionPreview
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={activeValues[filter.value]}
                    direction="horizontal"
                    staticRanges={filter?.staticRanges}
                  />
                </Popover>
              </>
            )}
            {filter?.help && (
              <Tooltip title={filter.help} placement="top">
                <InfoRounded sx={{ width: 15, marginRight: 1 }} />
              </Tooltip>
            )}
            {!filter?.required && (
              <IconButton
                size="small"
                sx={{ height: 25, width: 20, border: "none" }}
                onClick={() => {
                  const newActiveValues = { ...activeValues };
                  delete newActiveValues[filter.value];
                  setActiveValues(newActiveValues);
                  setIsBookmarkFilters(false);
                }}
              >
                <CloseIcon sx={{ width: 20 }} />
              </IconButton>
            )}
          </Box>
        ))}
        <Box sx={{ position: "absolute", right: 5, display: "flex" }}>
          <IconButton onClick={handleOpenModal}>
            {isBookmarkFilters ? <Bookmark /> : <BookmarkBorderOutlined />}
          </IconButton>
          {inactiveFilters?.length > 0 && (
            <>
              <Typography
                sx={{
                  fontSize: 15,
                  cursor: "pointer",
                  p: 1.5,
                }}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setOpenFilters(true);
                }}
              >
                Add new filter
              </Typography>
              <Menu
                open={openFilters}
                anchorEl={anchorEl}
                onClose={() => {
                  setAnchorEl(null);
                  setOpenFilters(false);
                }}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  ".MuiSelect-iconOpen": {
                    transform: "scale(0.9) !important",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: theme.palette.mode === "dark" ? "white" : "black",
                  },
                }}
                variant="outlined"
                value={""}
                size="small"
              >
                {inactiveFilters?.map((filter) => (
                  <MenuItem
                    value={filter.value}
                    key={filter.value}
                    disabled={filter.disabled}
                    sx={{
                      "&.Mui-disabled": {
                        pointerEvents: "all",
                      },
                    }}
                    onClick={(e) => {
                      if (filter.disabled) return;
                      setActiveValues({
                        ...activeValues,
                        [filter.value]:
                          filter.type === "text"
                            ? ""
                            : filter?.options?.[0]?.value || true,
                      });
                      setEditingFilter(filter.value);
                      setIsBookmarkFilters(false);
                    }}
                  >
                    {filter.label}
                    {filter.disabled && (
                      <Tooltip title={filter.disabledMessage} placement="top">
                        <InfoRounded sx={{ width: 15, marginLeft: 0.5 }} />
                      </Tooltip>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            onClose={() => setOpenSnackbar(false)}
          >
            Filters updated from bookmark
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default FilterBar;
