import { ContentCopy } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Snackbar } from "@mui/material";
import React, { useState } from "react";
import copyToClipboard from "../utils/copyToClipboard";

const CopyUrl = () => {
  const [open, setOpen] = useState(false);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{ fontSize: 10, height: 30, color: "grey" }}
        endIcon={<ContentCopy sx={{ width: 14 }} />}
        onClick={() => {
          copyToClipboard(window.location.href);
          setOpen(true);
        }}
      >
        Copy URL
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        message="URL copied to clipboard"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

export default CopyUrl;
