import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import user from "../User.jpeg";
import LoadMoreButton from "./LoadMoreButton";

const CommentsDisplay = ({ comments }) => {
  const [commentsLength, setCommentsLength] = useState(12);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {!comments &&
        Array.from(Array(2)).map((_, index) => <Skeleton height={50} />)}
      {comments &&
        comments?.slice(0, commentsLength)?.map((comment) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "background.card",
              borderRadius: 2,
            }}
          >
            <img
              alt="comment_profile_picture"
              style={{ height: 50, borderRadius: 25, margin: 10 }}
              src={
                comment?.snippet?.topLevelComment?.snippet
                  ?.authorProfileImageUrl || comment?.author_img
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = user;
              }}
            />
            <Box sx={{ overflow: "hidden", p: 1 }}>
              <Typography variant="body2">
                {comment?.snippet?.topLevelComment?.snippet
                  ?.authorDisplayName || comment?.author_name}
              </Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      comment?.snippet?.topLevelComment?.snippet
                        ?.textOriginal || comment?.comment,
                  }}
                />
              </Typography>
            </Box>
          </Box>
        ))}
      {comments?.length > commentsLength && (
        <LoadMoreButton onClick={() => setCommentsLength(commentsLength + 5)} />
      )}
    </Box>
  );
};

export default CommentsDisplay;
