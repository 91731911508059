import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

export const allFilters = createAsyncThunk(
  "filters/all",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL_V2}filters`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const createFilter = createAsyncThunk(
  "filters/create",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_V2}filters`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const deleteFilter = createAsyncThunk(
  "filters/delete",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL_V2}filters/${params.id}`
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);
