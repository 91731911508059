import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axios";
import Container from "./Container";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { setSession } from "../utils/jwt";
import { useDispatch, useSelector } from "react-redux";
import { signUpInfo } from "../redux/actions/userActions";

const SignUp = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data } = useSelector((state) => ({
    data: state.user.signUpInfo.data,
    loading: state.user.signUpInfo.loading,
  }));

  useEffect(() => {
    dispatch(signUpInfo());
  }, [dispatch]);

  const handleSubmitSub = async (event) => {
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_V2}sub`,
        {
          payment_method: result.paymentMethod.id,
          email: email,
          password: password,
          price: selectedPlan,
        }
      );
      // eslint-disable-next-line camelcase
      const { client_secret, status, token } = res.data;

      if (status === "requires_action") {
        stripe.confirmCardPayment(client_secret).then(function (result) {
          if (!result.error) {
            setSession(token);
            navigate("/channels");
          }
        });
      } else {
        setSession(token);
        navigate("/channels");
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4">Sign up</Typography>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "13px",
          letterSpacing: "0em",
          textAlign: "left",
          opacity: "0.5",
        }}
      >
        Already have an account?{" "}
        <Link
          to="/login"
          style={{
            color: "#65DC9F",
            textDecoration: "none",
            opacity: "1",
            fontWeight: "bold",
          }}
        >
          Click here
        </Link>
      </Typography>
      <Typography
        sx={{
          marginTop: "15px",
          fontSize: "12px",
          lineHeight: "13px",
          letterSpacing: "0em",
          textAlign: "left",
          opacity: "0.5",
          fontWeight: "bold",
        }}
      >
        Email Address
      </Typography>
      <TextField
        placeholder="Enter your Email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        fullWidth
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid  background.paper",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid  background.paper",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid  background.paper",
            },
        }}
      />
      <Typography
        sx={{
          marginTop: "15px",
          fontSize: "12px",
          lineHeight: "13px",
          letterSpacing: "0em",
          textAlign: "left",
          opacity: "0.5",
          fontWeight: "bold",
        }}
      >
        Password
      </Typography>
      <TextField
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        fullWidth
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid  background.paper",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid  background.paper",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid  background.paper",
            },
        }}
      />
      <Typography
        sx={{
          marginTop: "15px",
          fontSize: "12px",
          lineHeight: "13px",
          letterSpacing: "0em",
          textAlign: "left",
          opacity: "0.5",
          fontWeight: "bold",
        }}
      >
        Repeat Password
      </Typography>
      <TextField
        error={repeatPassword !== password && repeatPassword !== ""}
        type="password"
        onChange={(e) => setRepeatPassword(e.target.value)}
        value={repeatPassword}
        fullWidth
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid  background.paper",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid  background.paper",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "1px solid  background.paper",
            },
        }}
      />
      <Typography
        sx={{
          marginTop: "15px",
          fontSize: "12px",
          lineHeight: "13px",
          letterSpacing: "0em",
          textAlign: "left",
          opacity: "0.5",
          fontWeight: "bold",
        }}
      >
        Choose plan
      </Typography>
      <Box display="flex" flexDirection="row" gap={2}>
        {data?.map((payment) => (
          <Card
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              backgroundColor:
                selectedPlan === payment.id
                  ? "primary.main"
                  : "background.card",
              cursor: "pointer",
              padding: 3,
              color: selectedPlan === payment.id ? "white" : "text.primary",
            }}
            onClick={() => setSelectedPlan(payment.id)}
          >
            <Typography variant="h6">{payment.nickname}</Typography>
            {payment.unit_amount === 0 ? (
              <Typography variant="h2" fontWeight="bold">
                Free
              </Typography>
            ) : (
              <>
                <Typography variant="h2" fontWeight="bold">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: payment.currency,
                  }).format(payment.unit_amount_decimal / 100)}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  / {payment?.recurring?.interval}
                </Typography>
                <Typography variant="body2">{payment.description}</Typography>
              </>
            )}
          </Card>
        ))}
      </Box>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <Typography
        sx={{
          marginTop: "15px",
          fontSize: "12px",
          lineHeight: "13px",
          letterSpacing: "0em",
          textAlign: "left",
          opacity: "0.5",
          fontWeight: "bold",
        }}
      >
        Payment information
      </Typography>
      <Box
        sx={{
          border: "1px solid #E5E5E5",
          borderRadius: "5px",
          padding: 2,
        }}
      >
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
              },
              invalid: {
                color: "#9e2146",
              },
            },
            hidePostalCode: true,
          }}
        />
      </Box>
      <Button
        sx={{
          textTransform: "none",
          width: "100%",
          marginTop: "25px",
          backgroundColor: "#65DC9F",
          padding: 1,
          color: "black",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#65DC9F",
          },
          "&:disabled": {
            backgroundColor: "#65DC9F",
            opacity: "0.3",
          },
        }}
        variant="contained"
        onClick={handleSubmitSub}
        disabled={
          !stripe ||
          !email ||
          !password ||
          !repeatPassword ||
          password !== repeatPassword
        }
      >
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          "Coming Soon"
        )}
      </Button>
    </Container>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <SignUp />
    </Elements>
  );
}

export default App;
