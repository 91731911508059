import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import MainPane from "./components/MainPane";
import "./App.css";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

export const DebugModeContext = React.createContext({
  toggleDebugMode: () => {},
  debugMode: false,
});

function App(props) {
  const [mode, setMode] = React.useState("light");
  const [dbgMode, setDebugMode] = React.useState(true);
  const debugMode = React.useMemo(
    () => ({
      toggleDebugMode: () => {
        setDebugMode((prevMode) => !prevMode);
      },
      debugMode: dbgMode,
    }),
    [dbgMode]
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const nextMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("colorMode", nextMode);
          return nextMode;
        });
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          background: {
            card: mode === "light" ? "#f8f8ff" : "#171717",
            paper: mode === "light" ? "#fff" : "#121212",
            default: mode === "light" ? "#fff" : "#121212",
          },
          text: {
            primary: mode === "light" ? "#000" : "#fff",
            secondary:
              mode === "light"
                ? "rgba(0, 0, 0, 0.6)"
                : "rgba(255, 255, 255, 0.7)",
          },
          divider:
            mode === "light"
              ? "rgba(0, 0, 0, 0.12)"
              : "rgba(255, 255, 255, 0.12)",
        },
      }),
    [mode]
  );

  React.useEffect(() => {
    // const colorMode = localStorage.getItem("colorMode");
    setMode("light");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ColorModeContext.Provider value={colorMode}>
        <DebugModeContext.Provider value={debugMode}>
          <BrowserRouter>
            <CssBaseline />
            <MainPane />
          </BrowserRouter>
        </DebugModeContext.Provider>
      </ColorModeContext.Provider>
    </ThemeProvider>
  );
}

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default App;
