import { createStaticRanges } from "react-date-range";
import {
  last12Hours,
  last2Hours,
  last5Hours,
  lastDay,
  lastHour,
  lastWeek,
} from "./timeRanges";

const videoFilters = [
  {
    required: true,
    label: "Date range",
    value: "range",
    type: "dateRange",
    staticRanges: [
      ...createStaticRanges([
        {
          label: "Last hour",
          range: () => lastHour,
        },
        {
          label: "Last 2 hours",
          range: () => last2Hours,
        },
        {
          label: "Last 5 hours",
          range: () => last5Hours,
        },
        {
          label: "Last 12 hours",
          range: () => last12Hours,
        },
        {
          label: "Last day",
          range: () => lastDay,
        },
        {
          label: "Last week",
          range: () => lastWeek,
        },
      ]),
    ],
  },
  {
    label: "Interval",
    value: "interval",
    type: "select",
    options: [
      { value: 10, label: "10 minutes" },
      { value: 60, label: "hour" },
      { value: 720, label: "12 hours" },
      { value: 1440, label: "day" },
    ],
  },
  {
    label: "View non-cumulative",
    value: "viewNonCumulative",
    type: "boolean",
  },
  {
    label: "Compare",
    value: "compare",
    type: "select",
    options: [
      { value: "moving_average", label: "moving average" },
      {
        value: "average_video_compare",
        label: "Channel videos average performance",
      },
      {
        value: "same_day_video_compare",
        label: "Videos published on the same day",
      },
      { value: "video_compare", label: "Specific video" },
    ],
  },
  {
    required: { compare: "moving_average" },
    label: "Moving average",
    value: "movingAverage",
    type: "select",
    options: [
      { value: 60, label: "1 hour" },
      { value: 720, label: "12 hours" },
      { value: 1440, label: "1 day" },
      { value: 4320, label: "3 days" },
      { value: 10080, label: "1 week" },
    ],
  },
  {
    label: "Show total views",
    value: "showTotal",
    defaultValue: true,
    type: "boolean",
  },
];

export default videoFilters;
