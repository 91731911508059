import { Box } from "@mui/system";
import bg from "../img/Login background.png";

const Container = ({ children }) => {
  return (
    <Box width="100wh" display="flex" height="100vh">
      <img
        alt=""
        src={bg}
        style={{
          height: "100%",
          maxWidth: "50%",
          objectFit: "cover",
          objectPosition: "left",
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "50%",
          height: "100%",
          minWidth: 300,
          backgroundColor: "background.paper",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: "60px",
            textAlign: "left",
            minWidth: 300,
            width: "100%",
            overflow: "scroll",
            maxHeight: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Container;
