import { Badge, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { insights as insightsConstant } from "../constants/insights";
import Label from "./Label";

const InsightPickers = ({ setInsight, insight }) => {
  return (
    <Select
      sx={{
        height: 40,
        width: "fit-content",
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        ".MuiSelect-iconOpen": {
          transform: "scale(0.9) !important",
        },
      }}
      onChange={(e) => setInsight(e.target.value)}
      value={insight}
    >
      {insightsConstant?.map((insight) => (
        <MenuItem key={insight.key} value={insight.key}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginRight={1}
          >
            {<insight.icon sx={{ marginRight: 1 }} />}
            <Typography variant="h6">
              <b> {insight.label}</b>
            </Typography>
            {insight.beta && (
              <Label
                badgeContent="Coming soon"
                variant="info"
                sx={{ marginLeft: 1 }}
              >
                BETA
              </Label>
            )}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

export default InsightPickers;
