import { createSlice } from "@reduxjs/toolkit";
import { getApiUse, getCount } from "./actions/apikeyActions";

const apikeySlice = createSlice({
  name: "apikey",
  initialState: {
    apiKeys: {
      loading: false,
      count: null,
    },
    use: {
      loading: false,
      data: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCount.pending, (state) => {
      state.apiKeys.loading = true;
    });
    builder.addCase(getCount.fulfilled, (state, action) => {
      state.apiKeys.loading = false;
      state.apiKeys.count = action.payload;
    });
    builder.addCase(getCount.rejected, (state) => {
      state.apiKeys.loading = false;
    });
    builder.addCase(getApiUse.pending, (state) => {
      state.use.loading = true;
    });
    builder.addCase(getApiUse.fulfilled, (state, action) => {
      state.use.loading = false;
      state.use.data = action.payload;
    });
    builder.addCase(getApiUse.rejected, (state) => {
      state.use.loading = false;
    });
  },
});

export default apikeySlice.reducer;
