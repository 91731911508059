import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Popover,
  Select,
  Skeleton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { getRecommendedChannels } from "../redux/actions/channelActions";
import { DateRangePicker } from "react-date-range";
import { addMinutes } from "date-fns";

const RecommendedChannelDisplayChannelModal = ({ open, setOpen, id }) => {
  const dispatch = useDispatch();
  const [range, setRange] = useState([
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(),
    },
  ]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "count",
    direction: "desc",
  });

  const anchorEl = React.useRef(null);

  const { recommendations, loading } = useSelector((state) => ({
    recommendations: state.channel.recommendedChannels.data,
    loading: state.channel.recommendedChannels.loading,
  }));

  useEffect(() => {
    dispatch(
      getRecommendedChannels({
        id,
        date: range[0]?.startDate?.toISOString(),
      })
    );
  }, [dispatch, id, range]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "90vw",
          minWidth: "90vw",
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          padding: 2,
          minWidth: "90vw",
          minHeight: "90vh",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            gap: 2,
            display: "flex",
            flexDirection: "column",
            maxHeight: "90vh",
          }}
        >
          <Box display="flex" gap={2} alignItems="center" mb={2}>
            <Typography variant="h5" fontWeight="bold">
              Most Recommended Channels
            </Typography>
            <Button
              ref={anchorEl}
              onClick={() => setPopoverOpen(true)}
              variant="outlined"
            >
              {range[0]?.startDate?.toLocaleDateString()}
            </Button>
            <Popover
              open={popoverOpen}
              anchorEl={anchorEl.current}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => {
                setPopoverOpen(false);
              }}
            >
              <DateRangePicker
                onChange={(item) => {
                  let range = [];
                  if (item?.range1?.endDate?.getHours() === 0) {
                    range = [
                      {
                        startDate: item.range1.startDate,
                        endDate: addMinutes(item.range1.endDate, 1439),
                      },
                    ];
                  } else if (item?.range1) {
                    range = [
                      {
                        startDate: item.range1.startDate,
                        endDate: item.range1.endDate,
                      },
                    ];
                  } else {
                    range = [
                      {
                        startDate: item.selection.startDate,
                        endDate: item.selection.endDate,
                      },
                    ];
                  }

                  setRange(range);
                  setPopoverOpen(false);
                }}
                staticRanges={[]}
                inputRanges={[]}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={range}
                direction="horizontal"
                editableDateInputs={true}
              />
            </Popover>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{ overflowX: "auto" }}
          >
            {loading ? (
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableRow>
                      <TableCell>Channel Name</TableCell>
                      {Array.from(Array(7)).map((_, index) => (
                        <TableCell>
                          <Skeleton height={30} width="100%" />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from(Array(7)).map((_, index) => (
                      <TableRow key={index}>
                        {Array.from(Array(3)).map((_, index) => (
                          <TableCell>
                            <Skeleton height={30} width="100%" />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer
                component={Paper}
                sx={{ flexGrow: 1, overflowY: "auto" }}
              >
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "#f5f5f5",
                      position: "sticky",
                      top: 0,
                    }}
                  >
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === "channel_name"}
                          direction={
                            sortConfig.key === "channel_name"
                              ? sortConfig.direction
                              : "asc"
                          }
                          onClick={() => handleSort("channel_name")}
                        >
                          Channel Name
                        </TableSortLabel>
                      </TableCell>
                      {/* <TableCell>Number of videos</TableCell> */}
                      {/*                       
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === "count"}
                          direction={
                            sortConfig.key === "count"
                              ? sortConfig.direction
                              : "asc"
                          }
                          onClick={() => handleSort("count")}
                        >
                          Number of videos
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === "averagePosition"}
                          direction={
                            sortConfig.key === "averagePosition"
                              ? sortConfig.direction
                              : "asc"
                          }
                          onClick={() => handleSort("averagePosition")}
                        >
                          Average Position
                        </TableSortLabel>
                      </TableCell> */}
                      {Array.from(Array(7)).map((_, index) => (
                        // 3 day before to 3 days after date
                        <TableCell>
                          <Typography variant="body2">
                            {index - 3} days
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recommendations?.map((video) => (
                      <TableRow key={video?.id}>
                        <TableCell component="th" scope="row">
                          <a
                            href={`https://www.youtube.com${video?.url}`}
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            {video?.channelName || "Channel Name"}
                          </a>
                        </TableCell>
                        {Array.from(Array(7)).map((_, index) => (
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {(() => {
                              const startDate = range[0]?.startDate;
                              if (!startDate) return "N/A"; // Check if startDate is valid

                              const key = new Date(
                                new Date(startDate).setDate(
                                  new Date(startDate).getDate() + index - 3
                                )
                              )
                                .toISOString()
                                .split("T")[0];

                              const count = video?.dailyStats?.[key]?.count;
                              const averagePosition =
                                video?.dailyStats?.[key]?.averagePosition;
                              const rank = video?.dailyStats?.[key]?.rank;

                              return count != null // Check if count is not null or undefined
                                ? `#${rank} | ${count} (${averagePosition.toFixed(
                                    2
                                  )})` // Format averagePosition to two decimal places
                                : "N/A";
                            })()}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Box>
        {/* {JSON.stringify(
        recommendations?.sortedChannelRecommendations?.map(
          (channel) => channel?.channel_name
        )
      )} */}
      </Box>
    </Modal>
  );
};

export default RecommendedChannelDisplayChannelModal;
