import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  maxChannels as getMaxChannels,
  listChannels,
  requestChannel
} from "../redux/actions/channelActions";
import { updateUserChannels } from "../redux/actions/userActions";

const ChooseChannel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedChannels, setSelectedChannels] = React.useState([]);

  const [textInputValue, setTextInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { channels, maxChannels } = useSelector((state) => ({
    channels: state.channel.allChannels.channels,
    maxChannels: state.channel.allChannels.max,
  }));

  const handleSelectChannels = () => {
    dispatch(updateUserChannels({ selectedChannels })).then(() => {
      navigate("/channels");
    });
  };

  useEffect(() => {
    dispatch(listChannels());
    dispatch(getMaxChannels());
  }, [dispatch]);

  const addRequest = (value) => {
    dispatch(requestChannel({ url: value })).then(() => {
      dispatch(listChannels());
      setOpen(false);
      setLoading(false);
    });
    setLoading(true);
    setTextInputValue("");
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">
        {maxChannels === 1 ? "Choose a channel" : "Choose channels"} to track
      </Typography>
      <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
        {channels?.map((channel) => (
          <Card
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              backgroundColor: selectedChannels?.includes(channel?.id)
                ? "primary.main"
                : "background.card",
              cursor: "pointer",
            }}
            onClick={() => {
              if (selectedChannels?.includes(channel?.id)) {
                setSelectedChannels(
                  selectedChannels?.filter((id) => id !== channel?.id)
                );
              } else {
                if (selectedChannels?.length === maxChannels || !maxChannels) {
                  return;
                }
                setSelectedChannels([...selectedChannels, channel?.id]);
              }
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              m={2}
              alignItems="center"
            >
              <img
                src={channel?.thumbnail}
                alt="channel"
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 50,
                }}
              />
              <Typography
                sx={{
                  color: selectedChannels?.includes(channel?.id)
                    ? "white"
                    : "text.primary",
                }}
              >
                {channel?.name}
              </Typography>
            </Box>
          </Card>
        ))}
        <Card
          display="flex"
          onClick={() => setOpen(true)}
          sx={{
            backgroundColor: "background.card",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            padding: 3,
          }}
        >
          <Typography color="text.primary" display="flex" alignItems="center">
            <Add /> Request
          </Typography>
        </Card>
      </Box>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Request chanel</DialogTitle>
        <Box paddingLeft={2} paddingRight={2} paddingBottom={2} width={500}>
          <TextField
            value={textInputValue}
            onChange={(e) => setTextInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addRequest(e.target.value);
              }
            }}
            size="small"
            sx={{ width: "100%" }}
            label="Channel URL"
            variant="outlined"
            placeholder="https://www.youtube.com/@MSA.official"
          />
          <Button
            onClick={() => addRequest(textInputValue)}
            sx={{ float: "right", marginTop: 1, color: "text.primary" }}
          >
            {loading ? "Loading..." : "Request"}
          </Button>
        </Box>
      </Dialog>
      <Button
        variant="contained"
        sx={{
          height: 50,
          mt: 2,
          "&:disabled": {
            opacity: 0.2,
            backgroundColor: "success.main",
          },
        }}
        color="success"
        disabled={selectedChannels?.length === 0}
        onClick={handleSelectChannels}
      >
        <Typography display="flex" alignItems="center" fontWeight="bold">
          Select Channels
        </Typography>
      </Button>
      <Typography variant="caption" color="text.secondary">
        Requested channels will be added to the list after being approved by the
        admin
      </Typography>
    </Box>
  );
};

export default ChooseChannel;
