import { Box, Typography } from "@mui/material";
import React from "react";

const withPageTitle = (title, Component) => {
  return (
    <Box>
      <Typography
        color="text.primary"
        fontSize="22.5px"
        fontWeight="bold"
        marginBottom={2}
      >
        {title}
      </Typography>
      <Component />
    </Box>
  );
};

export default withPageTitle;
