import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../utils/axios";

export const allVideos = createAsyncThunk("videos/all", async (params) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL_V2}videos/all`,
    {
      params,
    }
  );
  return res.data;
});

export const videoList = createAsyncThunk("videos/all/list", async (params) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL_V2}videos/list`,
    { params }
  );
  return res.data;
});

export const videoInfo = createAsyncThunk("videos/get/info", async (params) => {
  const res = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL_V2}videos/${params.id}/info`,
    { params }
  );
  return res.data;
});

export const videoChartData = createAsyncThunk(
  "videos/get/chartData",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}videos/${params.id}/chartData`,
      { params }
    );
    return res.data;
  }
);

export const compareVideo = createAsyncThunk(
  "videos/get/compare",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}video/${params.id}/compareData`,
      { params }
    );
    return res.data;
  }
);

export const publishDatePerformanceInsights = createAsyncThunk(
  "videos/get/insights",
  async (params, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}videos/insights`,
      { params, cancelToken: source.token }
    );
    return res.data;
  }
);

export const compareChannelViews = createAsyncThunk(
  "videos/get/channelViews",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}videos/average`,
      { params }
    );
    return res.data;
  }
);

export const getClassifications = createAsyncThunk(
  "videos/get/classifications",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}insights/classifications`,
      { params }
    );
    return res.data;
  }
);

export const createVideoTitleRecommendations = createAsyncThunk(
  "videos/get/titleRecommendations",
  async (body) => {
    const res = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL_V2}videos/addRecommendedTitles`,
      body
    );
    return res.data;
  }
);

export const searchVideos = createAsyncThunk(
  "videos/search",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}videos/search`,
      { params }
    );
    return res.data;
  }
);

export const recommendedVideos = createAsyncThunk(
  "videos/recommended",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}videos/${params.id}/recommended`,
      { params }
    );
    return res.data;
  }
);

export const allRecommendedVideos = createAsyncThunk(
  "videos/allRecommended",
  async (params) => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_V2}videos/${params.id}/recommended`,
      { params }
    );
    return res.data;
  }
);
