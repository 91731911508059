import {
  Grid,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { allChannels } from "../redux/actions/channelActions";
import getImageUrl from "../utils/getImageUrl";

import Label from "./Label";
import LoadMoreButton from "./LoadMoreButton";

const VideoDisplay = ({
  videos,
  limited,
  onVideoClick,
  loading,
  setTextFilter,
  hideChannelFilter,
}) => {
  const [videosLength, setVideosLength] = useState(10);
  const [videoFilter, setVideoFilter] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("all_channels");
  const [sort, setSort] = useState("published_at");
  const dispatch = useDispatch();
  const v = videos
    ?.filter(
      (video) =>
        video.title.toLowerCase().includes(videoFilter.toLowerCase()) &&
        video.views !== 0 &&
        (selectedChannel === "all_channels" ||
          video.channel_id === selectedChannel)
    )
    .sort((a, b) => {
      if (sort === "published_at") {
        return new Date(b.published_at) - new Date(a.published_at);
      } else {
        //if (sort === "view count") {
        return b.views - a.views;
      }
    });

  const { channels } = useSelector((state) => ({
    channels: state.channel.allChannels.channels,
  }));

  const handleFilterChange = (e) => {
    setVideoFilter(e.target.value);
    setTextFilter(e.target.value);
  };

  useEffect(() => {
    dispatch(allChannels());
  }, [dispatch]);

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box display="flex" width="100%">
          <Label
            sx={{
              padding: 0.75,
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: 1,
            }}
            variant="success"
          >
            {v?.length} videos
          </Label>
          <TextField
            onChange={handleFilterChange}
            value={videoFilter}
            sx={{ marginLeft: 1, marginBottom: 2, height: 30 }}
            size="small"
            inputProps={{
              style: {
                padding: 2,
                paddingLeft: 5,
                height: 20,
              },
            }}
            fullWidth
            variant="outlined"
          />
          {!hideChannelFilter && (
            <Select
              value={selectedChannel}
              sx={{ marginLeft: 1, height: 25, fontSize: 12 }}
              onChange={(e) => setSelectedChannel(e.target.value)}
            >
              <MenuItem value="all_channels">All Channels</MenuItem>
              {channels?.map((channel) => (
                <MenuItem value={channel.channel_id}>{channel.name}</MenuItem>
              ))}
            </Select>
          )}
          <Select
            value={sort}
            sx={{ marginLeft: 1, height: 25, fontSize: 12 }}
            onChange={(e) => setSort(e.target.value)}
          >
            <MenuItem value="published_at">publish date</MenuItem>
            <MenuItem value="view count">view count</MenuItem>
          </Select>
        </Box>
        <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap">
          {loading &&
            Array.from(Array(6)).map((_, index) => (
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={index}>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box
                    height={200}
                    width={300}
                    overflow="hidden"
                    borderRadius={2}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="100%"
                      width="100%"
                    />
                  </Box>
                  <Skeleton />
                  <Skeleton />
                </Box>
              </Grid>
            ))}
          {!loading &&
            v
              ?.filter((_, i) => !limited || i < videosLength)
              .map((video, index) => (
                <Link
                  onClick={(e) => {
                    if (onVideoClick) {
                      e.preventDefault();
                      onVideoClick(video);
                    }
                  }}
                  to={`/videos/${video?.video_id}`}
                  style={{
                    textDecoration: "none",
                    color: "unset",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    backgroundColor="background.card"
                    sx={{
                      display: "flex",
                      overflow: "hidden",
                      gap: 1,
                      justifyContent: "space-between",
                      borderRadius: 2,
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        alignItems: "center",
                      }}
                      p={2}
                    >
                      <img
                        style={{
                          height: 165,
                          width: 300,
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: 5,
                        }}
                        src={getImageUrl(video?.thumbnail)}
                        alt="video / Video Name"
                      />
                      <Box minWidth={0} pt={1.5} color="text.primary">
                        <Typography
                          maxWidth={300}
                          fontSize="18px"
                          fontWeight="bold"
                          noWrap
                          sx={{ textOverflow: "ellipsis" }}
                        >
                          {video?.title}
                        </Typography>
                        <Typography
                          noWrap
                          sx={{ textOverflow: "ellipsis" }}
                          maxWidth={300}
                        >
                          {video?.description}
                        </Typography>
                        <Typography
                          noWrap
                          sx={{ textOverflow: "ellipsis" }}
                          maxWidth={300}
                        >
                          <b>Views:</b> {video?.views.toLocaleString()}
                        </Typography>
                        <Typography
                          noWrap
                          sx={{ textOverflow: "ellipsis" }}
                          maxWidth={300}
                        >
                          <b>Published at:</b>{" "}
                          {new Date(video?.published_at).toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Link>
              ))}
        </Box>
        {v?.length > videosLength && (
          <LoadMoreButton onClick={() => setVideosLength(videosLength + 5)} />
        )}
      </Box>
      <Box width="100%" display="flex" flexWrap="wrap" gap={3}></Box>
    </Box>
  );
};

export default VideoDisplay;
