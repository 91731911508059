import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, verifyUser } from "../redux/actions/userActions";
import { getRequestedChannels } from "../redux/actions/channelActions";

const Verify = () => {
  const isAdmin = localStorage.getItem("user") === "admin@zvoid.com";
  const dispatch = useDispatch();

  const { allUsers, requestedChannels } = useSelector((state) => ({
    allUsers: state.user.allUsers.data,
    loading: state.user.allUsers.loading,
    requestedChannels: state.channel.requestedChannels.data,
  }));

  React.useEffect(() => {
    if (isAdmin) {
      dispatch(getAllUsers({ verified: false }));
      dispatch(getRequestedChannels());
    }
  }, [dispatch, isAdmin]);

  const handleVerify = (id) => {
    dispatch(verifyUser({ id })).then(() => {
      dispatch(getAllUsers({ verified: false }));
    });
  };

  if (!isAdmin)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        pt={10}
      >
        <Typography variant="h6" align="center">
          You need to wait to access the system until your account is verified.
          <br />
          This doesn't usually take more than 6 hours.
        </Typography>
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" gap={2} justifyContent="center">
      <Typography my={2} variant="h4">
        Verify users
      </Typography>
      {allUsers?.map((user) => (
        <Typography key={user.id}>
          {user.email}{" "}
          <Button onClick={() => handleVerify(user.id)} variant="contained">
            Verify
          </Button>
        </Typography>
      ))}
      <Typography my={2} variant="h4">
        Requested channels
      </Typography>
      {requestedChannels?.map((channel) => (
        <Typography key={channel.id}>
          <b>{channel.url}</b> requested by {channel.user.email}
        </Typography>
      ))}
    </Box>
  );
};

export default Verify;
