import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { channelsChartData } from "../redux/actions/channelActions";
import ChannelCompareChart, {
  channelCompareColors,
} from "./ChannelCompareChart";
import { DateRangePicker } from "react-date-range";
import { addMinutes } from "date-fns";

const ChannelCompareInsights = () => {
  const dispatch = useDispatch();
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [sum, setSum] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const anchorEl = useRef(null);
  const [interval, setInterval] = useState(1440);
  const [range, setRange] = useState([
    {
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
    },
  ]);
  const { channels, chartData, loading } = useSelector((state) => ({
    channels: state.channel.allChannels.channels,
    chartData: state.channel.channelsChartData.data,
    loading: state.channel.channelsChartData.loading,
  }));

  useEffect(() => {
    const abortController = new AbortController();

    dispatch(
      channelsChartData({
        ids: selectedChannels,
        range,
        interval,
        abortController,
      })
    );

    return () => {
      abortController.abort();
    };
  }, [dispatch, selectedChannels, range, interval]);

  return (
    <Box px={2} display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={sum}
              onChange={(e) => setSum(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Sum all channels"
        />
        <Box display="flex" gap={1} alignItems="center">
          <Select
            sx={{
              height: 40,
            }}
            value={interval}
            onChange={(e) => setInterval(e.target.value)}
          >
            <MenuItem value={60}>1 hour</MenuItem>
            <MenuItem value={720}>12 hours</MenuItem>
            <MenuItem value={1440}>1 day</MenuItem>
            <MenuItem value={4320}>3 days</MenuItem>
            <MenuItem value={10080}>1 week</MenuItem>
          </Select>
          <Button
            ref={anchorEl}
            onClick={() => setPopoverOpen(true)}
            variant="outlined"
          >
            {range[0]?.startDate?.toLocaleDateString()} -{" "}
            {range[0]?.endDate?.toLocaleDateString()}
          </Button>
          <Popover
            open={popoverOpen}
            anchorEl={anchorEl.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={() => {
              setPopoverOpen(false);
            }}
          >
            <DateRangePicker
              onChange={(item) => {
                let range = [];
                if (item?.range1?.endDate?.getHours() === 0) {
                  range = [
                    {
                      startDate: item.range1.startDate,
                      endDate: addMinutes(item.range1.endDate, 1439),
                    },
                  ];
                } else if (item?.range1) {
                  range = [
                    {
                      startDate: item.range1.startDate,
                      endDate: item.range1.endDate,
                    },
                  ];
                } else {
                  range = [
                    {
                      startDate: item.selection.startDate,
                      endDate: item.selection.endDate,
                    },
                  ];
                }

                setRange(range);
              }}
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={range}
              direction="horizontal"
            />
          </Popover>
        </Box>
      </Box>
      <Box
        position="relative"
        display="flex"
        gap={1}
        alignItems="center"
        flexWrap="wrap"
        border="1px solid"
        borderColor="divider"
        borderRadius={2}
        padding={1}
        minHeight={45}
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" width={0} gap={1} flexGrow={1} flexWrap="wrap">
          {selectedChannels.map((channel, i) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() =>
                setSelectedChannels(
                  selectedChannels.filter((c) => c !== channel)
                )
              }
              sx={{
                borderRadius: 1,
                paddingLeft: 1,
                backgroundColor: channelCompareColors?.[i] || "#4b54db",
              }}
            >
              <Typography
                sx={{
                  marginRight: 1,
                  fontSize: 15,
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  color: "#fff",
                }}
              >
                {channels.find((c) => c.channel_id === channel)?.name}
              </Typography>
            </Box>
          ))}
        </Box>
        {selectedChannels.length < channels.length && (
          <Select
            sx={{
              height: 0,
              "& .MuiSelect-select": {
                opacity: 0,
                width: 0,
                height: 0,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            onChange={(e) => {
              if (e.target.value === "all") {
                setSelectedChannels(
                  channels.map((channel) => channel.channel_id)
                );
                return;
              }
              setSelectedChannels([...selectedChannels, e.target.value]);
            }}
          >
            <MenuItem value="all">Select all</MenuItem>
            {channels
              ?.filter(
                (channel) => !selectedChannels.includes(channel.channel_id)
              )
              ?.map((channel) => (
                <MenuItem value={channel.channel_id}>{channel.name}</MenuItem>
              ))}
          </Select>
        )}
      </Box>
      <ChannelCompareChart
        sum={sum}
        loading={loading}
        x={chartData?.map((ch) => ch?.labels)}
        y={chartData?.map((ch) => ch?.data)}
      />
    </Box>
  );
};

export default ChannelCompareInsights;
