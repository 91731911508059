import {
  Button,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getOwnChannelRecommendations,
  getRecommendedVideos,
} from "../redux/actions/channelActions";
import { ChevronLeft, ChevronRight, InfoRounded } from "@mui/icons-material";
import RecommendedChannelDisplayChannelModal from "./RecommendedChannelDisplayChannel";

const RecommendedVideosDisplayChannel = ({ id }) => {
  const dispatch = useDispatch();
  const [daysAgo, setDaysAgo] = useState(0);
  const [daysAgo2, setDaysAgo2] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const {
    recommendations,
    loading,
    ownChannelRecommendations,
    ownChannelRecommendationsLoading,
  } = useSelector((state) => ({
    recommendations: state.channel.recommendedVideos.data,
    loading: state.channel.recommendedVideos.loading,
    ownChannelRecommendations: state.channel.ownChannelRecommendations.data,
    ownChannelRecommendationsLoading:
      state.channel.ownChannelRecommendations.loading,
  }));

  useEffect(() => {
    dispatch(getRecommendedVideos({ id, daysAgo }));
  }, [dispatch, id, daysAgo]);

  useEffect(() => {
    dispatch(getOwnChannelRecommendations({ id, daysAgo: daysAgo2 }));
  }, [dispatch, id, daysAgo2]);

  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? "Show" : "Hide"} Recommended Videos Insights
      </Button>
      {!collapsed && (
        <Box>
          <RecommendedChannelDisplayChannelModal
            id={id}
            open={openModal}
            setOpen={setOpenModal}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h5" fontWeight="bold">
                Most Recommended Videos
              </Typography>
              <IconButton onClick={() => setOpenModal(true)}>
                <InfoRounded />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                onClick={() => setDaysAgo(daysAgo - 1)}
                disabled={daysAgo === 0}
              >
                <ChevronLeft />
              </IconButton>
              <Typography variant="body1">
                {daysAgo === 0
                  ? "Today"
                  : daysAgo === 1
                  ? "Yesterday"
                  : `${daysAgo} days ago`}
              </Typography>
              <IconButton onClick={() => setDaysAgo(daysAgo + 1)}>
                <ChevronRight />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              sx={{
                overflowX: "auto",
              }}
            >
              {loading &&
                Array.from(Array(6)).map((_, index) => (
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={index}>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Box
                        height={200}
                        width={300}
                        overflow="hidden"
                        borderRadius={2}
                      >
                        <Skeleton
                          variant="rectangular"
                          height="100%"
                          width="100%"
                        />
                      </Box>
                      <Skeleton />
                      <Skeleton />
                    </Box>
                  </Grid>
                ))}
              {!loading &&
                recommendations?.sortedVideoRecommendations?.map((video) => (
                  <a
                    href={`https://www.youtube.com${video?.url}`}
                    style={{
                      textDecoration: "none",
                      color: "unset",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      backgroundColor="background.card"
                      sx={{
                        display: "flex",
                        overflow: "hidden",
                        gap: 1,
                        justifyContent: "space-between",
                        borderRadius: 2,
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          overflow: "hidden",
                          alignItems: "center",
                        }}
                        p={2}
                      >
                        <img
                          style={{
                            height: 165,
                            width: 300,
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: 5,
                          }}
                          src={video?.thumbnail}
                          alt="video / Video Name"
                        />
                        <Box minWidth={0} pt={1.5} color="text.primary">
                          <Typography
                            maxWidth={300}
                            fontSize="18px"
                            fontWeight="bold"
                            noWrap
                            sx={{ textOverflow: "ellipsis" }}
                          >
                            {video?.title}
                          </Typography>
                          <Box display="flex" flexDirection="row" gap={2}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                              sx={{ textOverflow: "ellipsis" }}
                            >
                              <b>Count: </b>
                              {video?.count}
                            </Typography>
                            <Divider orientation="vertical" flexItem />
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                              sx={{ textOverflow: "ellipsis" }}
                            >
                              <b>Average position: </b>
                              {(1 + video?.averagePosition)?.toFixed(2)}
                              {video?.positions && (
                                <Tooltip title={video?.positions?.join(", ")}>
                                  <InfoRounded
                                    sx={{
                                      mt: 1,
                                      ml: 1,
                                      width: "15px",
                                      height: "15px",
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </a>
                ))}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h5" fontWeight="bold">
                Channel Videos Recommended in Other channels
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                onClick={() => setDaysAgo2(daysAgo2 - 1)}
                disabled={daysAgo2 === 0}
              >
                <ChevronLeft />
              </IconButton>
              <Typography variant="body1">
                {daysAgo2 === 0
                  ? "Today"
                  : daysAgo2 === 1
                  ? "Yesterday"
                  : `${daysAgo2} days ago`}
              </Typography>
              <IconButton onClick={() => setDaysAgo2(daysAgo2 + 1)}>
                <ChevronRight />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              sx={{
                overflowX: "auto",
              }}
            >
              {ownChannelRecommendationsLoading &&
                Array.from(Array(6)).map((_, index) => (
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={index}>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Box
                        height={200}
                        width={300}
                        overflow="hidden"
                        borderRadius={2}
                      >
                        <Skeleton
                          variant="rectangular"
                          height="100%"
                          width="100%"
                        />
                      </Box>
                      <Skeleton />
                      <Skeleton />
                    </Box>
                  </Grid>
                ))}
              {!ownChannelRecommendationsLoading &&
                ownChannelRecommendations?.map((video) => (
                  <a
                    href={`https://www.youtube.com${video?.url}`}
                    style={{
                      textDecoration: "none",
                      color: "unset",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      backgroundColor="background.card"
                      sx={{
                        display: "flex",
                        overflow: "hidden",
                        gap: 1,
                        justifyContent: "space-between",
                        borderRadius: 2,
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          overflow: "hidden",
                          alignItems: "center",
                          maxWidth: 310,
                        }}
                        p={2}
                      >
                        <img
                          style={{
                            height: 165,
                            width: 300,
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: 5,
                          }}
                          src={video?.thumbnail}
                          alt="video / Video Name"
                        />
                        <Box minWidth={0} pt={1.5} color="text.primary">
                          <Typography
                            maxWidth={300}
                            fontSize="18px"
                            fontWeight="bold"
                            noWrap
                            sx={{ textOverflow: "ellipsis" }}
                          >
                            {video?.title}
                          </Typography>
                          <Box display="flex" flexDirection="row" gap={2}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                              sx={{ textOverflow: "ellipsis" }}
                            >
                              <b>Count: </b>
                              {video?.count}
                            </Typography>
                            <Divider orientation="vertical" flexItem />
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              noWrap
                              sx={{ textOverflow: "ellipsis" }}
                            >
                              <b>Average position: </b>
                              {(1 + video?.averagePosition)?.toFixed(2)}
                              {video?.positions && (
                                <Tooltip title={video?.positions?.join(", ")}>
                                  <InfoRounded
                                    sx={{
                                      mt: 1,
                                      ml: 1,
                                      width: "15px",
                                      height: "15px",
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </a>
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RecommendedVideosDisplayChannel;
