import { ArrowDropDown, Close, FilterAlt } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DebugModeContext } from "../App";
import {
  channelDebugTopicInsights,
  channelTopicInsights as getChannelTopicInsights,
} from "../redux/actions/channelActions";
import DebugWordInsights from "./DebugWordInsights";

const ChannelInsights = () => {
  const { id, classification } = useParams();

  const dispatch = useDispatch();
  const debugMode = useContext(DebugModeContext);

  const [orderBy, setOrderBy] = React.useState("percentageIncrease");
  const [editingMinWordCount, setEditingMinWordCount] = React.useState(false);
  const [minWordCount, setMinWordCount] = React.useState(10);
  const [editingSearch, setEditingSearch] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [search, setSearch] = React.useState(null);

  const { channelTopicInsights, channelTopicInsightsLoading, debugWord } =
    useSelector((state) => ({
      channelTopicInsights: state.channel.channelTopicInsights.data,
      channelTopicInsightsLoading: state.channel.channelTopicInsights.loading,
      debugWord: state.channel.channelDebugClassificationInsight.data,
    }));

  const handleDebugClick = (word) => {
    dispatch(channelDebugTopicInsights({ id: id, word, classification }));
    setModalOpen(true);
  };

  useEffect(() => {
    dispatch(
      getChannelTopicInsights({
        id: id,
        orderBy,
        minWordCount,
        classification,
      })
    );
  }, [orderBy, minWordCount, id, classification, dispatch]);

  return (
    <Box>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            transform: "translate(5%, 5%)",
            width: "90%",
            height: "90%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Box position="absolute" height={0} width={0} top={0} right={50}>
            <IconButton
              onClick={() => setModalOpen(false)}
              sx={{ color: "text.primary" }}
              size="large"
            >
              <Close />
            </IconButton>
          </Box>
          <DebugWordInsights
            location={`/insights?insight=average_views&channelId=${id}&excludeShorts=true&excludeUnlisted=true&excludeDaysUnder=30&${
              classification === "title"
                ? `includeWord=${encodeURIComponent(debugWord?.data?.[0].word)}`
                : `includeClassification=${encodeURIComponent(
                    classification
                  )}&classificationValue=${encodeURIComponent(
                    debugWord?.data?.[0].word
                  )}`
            }
                      `}
            debugWord={debugWord}
          />
        </Box>
      </Modal>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            mb={2}
            variant="h4"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {channelTopicInsights?.displayName || (
              <Skeleton sx={{ marginRight: 1, width: 200 }} />
            )}{" "}
            Insights
          </Typography>
        </Box>
      </Box>
      <Box
        position="relative"
        display="flex"
        gap={1}
        alignItems="center"
        flexWrap="wrap"
        border="1px solid"
        borderColor="divider"
        borderRadius={2}
        padding={1}
        minHeight={43}
        mb={2}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            backgroundColor: "divider",
            borderRadius: 1,
            paddingLeft: 1,
            paddingRight: 1,
            alignItems: "center",
            fontSize: 13,
            height: 25,
          }}
          onClick={() => setEditingMinWordCount(true)}
        >
          Minimum number of appearances:
          {editingMinWordCount ? (
            <TextField
              type="number"
              variant="outlined"
              value={minWordCount}
              size="small"
              sx={{ marginLeft: 1, width: 50 }}
              onBlur={(e) => {
                setEditingMinWordCount(false);
                setMinWordCount(e.target.value);
              }}
              onChange={(e) => setMinWordCount(e.target.value)}
              autoFocus={true}
              focused={true}
              inputProps={{
                style: {
                  padding: 0,
                  paddingLeft: 5,
                  height: "25px",
                  fontSize: 13,
                },
              }}
            />
          ) : (
            <Box sx={{ cursor: "pointer", marginLeft: 1 }}>{minWordCount}</Box>
          )}
        </Box>
        {search !== null && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              backgroundColor: "divider",
              borderRadius: 1,
              paddingLeft: 1,
              paddingRight: 1,
              alignItems: "center",
              fontSize: 13,
              height: 25,
            }}
            onClick={() => setEditingSearch(true)}
          >
            Filter word:
            {editingSearch ? (
              <TextField
                sx={{ marginLeft: 1 }}
                onBlur={(e) => {
                  if (!e.relatedTarget) {
                    setEditingSearch(false);
                  }
                  setSearch(e.target.value);
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
                inputRef={(input) => input && input.focus()}
                autoFocus={true}
                focused={true}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearch(e.target.value);
                    setEditingSearch(false);
                  }
                }}
                inputProps={{
                  style: {
                    padding: 0,
                    paddingLeft: 5,
                    height: "25px",
                    fontSize: 13,
                  },
                }}
              />
            ) : (
              <Box sx={{ cursor: "pointer", marginLeft: 1 }}>{search}</Box>
            )}
            <IconButton
              size="small"
              sx={{ height: 25, width: 15, border: "none", marginLeft: 1 }}
              onClick={() => setSearch(null)}
            >
              <Close sx={{ width: 20 }} />
            </IconButton>
          </Box>
        )}
        {search === null && (
          <Select
            sx={{
              position: "absolute",
              right: 5,
              height: 20,
              width: 30,
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              ".MuiSvgIcon-root ": {
                fill: "lightgray !important",
              },
              ".MuiSelect-iconOpen": {
                transform: "scale(0.9) !important",
              },
            }}
            onChange={(e) => {
              e.preventDefault();
              if (e.target.value === "filterWord") {
                setSearch("");
                setEditingSearch(true);
              }
            }}
            value={""}
            size="small"
            IconComponent={FilterAlt}
          >
            {!search && <MenuItem value="filterWord">Filter word</MenuItem>}
          </Select>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {[
                { name: "Word", key: "word" },
                { name: "Count", key: "count" },
                { name: "Average Views", key: "averageViews" },
                { name: "Median Views", key: "medianViews" },
                {
                  name: "Median percentage Increase",
                  key: "medianPercentageIncrease",
                },
                {
                  name: "Average percentage Increase",
                  key: "percentageIncrease",
                },
              ].map((header, i) => (
                <TableCell
                  key={header.key}
                  align={i === 0 ? "left" : i === 5 ? "right" : "center"}
                  onClick={() => {
                    setOrderBy(header.key);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography>
                    {header.name}
                    {orderBy === header.key ? (
                      <ArrowDropDown
                        sx={{ width: 20, verticalAlign: "bottom" }}
                      />
                    ) : (
                      <ArrowDropDown
                        sx={{
                          width: 20,
                          color: "transparent",
                          verticalAlign: "bottom",
                        }}
                      />
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {channelTopicInsightsLoading &&
              Array(10)
                .fill(0)
                .map((_, i) => (
                  <TableRow>
                    {Array(6)
                      .fill(0)
                      .map(() => (
                        <TableCell>
                          <Skeleton variant="text" height={30} />
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            {channelTopicInsights?.data
              ?.filter(
                (row) =>
                  !search ||
                  row?.word?.toLowerCase().includes(search?.toLowerCase())
              )
              ?.map((row) => (
                <TableRow
                  onClick={(e) => {
                    handleDebugClick(row?.word);
                  }}
                  key={row?.name}
                  sx={{
                    cursor: "pointer",
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">{row?.word}</TableCell>
                  <TableCell align="center">{row?.count}</TableCell>
                  <TableCell align="center">
                    {row.medianViews?.toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    {row.averageViews?.toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {parseFloat(row?.medianPercentageIncrease).toFixed(2)}%
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {parseFloat(row?.percentageIncrease).toFixed(2)}%
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ChannelInsights;
