import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import React from "react";

const LoadMoreButton = ({onClick}) => {
  return (
    <Box
      width="100%"
      mt={2}
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={0.25}
      onClick={onClick}
      sx={{ fontWeight: "bold", cursor: "pointer" }}
      color="text.primary"
    >
      Load more
      <ExpandMoreIcon />
    </Box>
  );
};

export default LoadMoreButton;
