import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { createStaticRanges } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { allChannels } from "../redux/actions/channelActions";
import {
  last30Days,
  last3Months,
  last60Days,
  last6Months,
  lastMonth,
  lastWeek,
  previous30days,
  previous3MonthsPeriod,
  previous60days,
  previous6Months,
  previousMonth,
  previousWeek,
  sameDay2WeeksAgo,
  sameDayLastWeek,
  today,
  yesterday,
  yesterdayLastWeek,
} from "./timeRanges";

const channelFilters = [
  {
    required: true,
    label: "Date range",
    value: "range",
    type: "dateRange",
    priority: 1,
    defaultValue: [
      {
        startDate: subDays(new Date(), 60),
        endDate: new Date(),
      },
    ],
    staticRanges: [
      ...createStaticRanges([
        {
          label: "Today",
          range: () => today,
        },
        {
          label: "Yesterday",
          range: () => yesterday,
        },
        {
          label: "Last week",
          range: () => lastWeek,
        },
        {
          label: "Last 30 days",
          range: () => last30Days,
        },
        {
          label: "Last 60 days",
          range: () => last60Days,
        },
        {
          label: "Last Month",
          range: () => lastMonth,
        },
        {
          label: "Last 3 Months",
          range: () => last3Months,
        },
        {
          label: "Last 6 Months",
          range: () => last6Months,
        },
      ]),
    ],
  },
  {
    label: "View data",
    value: "displayData",
    type: "select",
    priority: 2,
    required: true,
    options: [
      { value: "view_count", label: "videos" },
      { value: "shorts_view_count", label: "shorts" },
      { value: "videos_and_shorts", label: "videos and shorts" },
    ],
  },
  {
    label: "View non cumulative",
    value: "interval",
    defaultValue: 1440,
    priority: 3,
    type: "select",
    options: [
      { value: 10, label: "10 minutes" },
      { value: 60, label: "hour" },
      { value: 720, label: "12 hours" },
      { value: 1440, label: "day" },
      { value: 10080, label: "week" },
    ],
  },
  {
    label: "Compare",
    value: "compare",
    // disabled: { displayData: "videos_and_shorts" },
    // disabledMessage:
    //   "Compare is not available when view data is set to videos and shorts",
    type: "select",
    priority: 5,
    options: [
      { value: "same_channel", label: "another time period" },
      { value: "other_channel", label: "another channel" },
    ],
  },
  {
    required: { compare: true },
    label: "Compare date range",
    value: "compareRange",
    type: "dateRange",
    priority: 6,
    defaultValue: [
      {
        startDate: subDays(new Date(), 60),
        endDate: new Date(),
      },
    ],
    staticRanges: [
      ...createStaticRanges([
        {
          label: "Yesterday",
          range: () => yesterday,
        },
        {
          label: "Today the previous week",
          range: () => sameDayLastWeek,
        },
        {
          label: "Yesterday the previous week",
          range: () => yesterdayLastWeek,
        },
        {
          label: "Today 2 weeks ago",
          range: () => sameDay2WeeksAgo,
        },
        {
          label: "Previous week",
          range: () => previousWeek,
        },
        {
          label: "Previous 30 days",
          range: () => previous30days,
        },
        {
          label: "Previous 60 days",
          range: () => previous60days,
        },
        {
          label: "Previous Month",
          range: () => previousMonth,
        },
        {
          label: "Previous 3 Months period",
          range: () => previous3MonthsPeriod,
        },
        {
          label: "Previous 6 Months",
          range: () => previous6Months,
        },
      ]),
    ],
  },
  {
    required: { compare: "other_channel" },
    label: "Compare channel",
    value: "channelId",
    priority: 7,
    type: "select",
    options: [],
  },
  {
    label: "Show total views",
    value: "showTotal",
    priority: 8,
    defaultValue: true,
    type: "boolean",
  },
];

const useChannelFilters = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(channelFilters);

  const { channels } = useSelector((state) => ({
    channels: state.channel.allChannels.channels,
  }));

  useEffect(() => {
    dispatch(allChannels());
  }, [dispatch]);

  useEffect(() => {
    if (channels) {
      const newFilters = [...filters];
      const channelFilter = newFilters.find((f) => f.value === "channelId");
      channelFilter.options = channels.map((c) => ({
        value: c.channel_id,
        label: c.name,
      }));
      setFilters(newFilters);
    }
  }, [channels]); // eslint-disable-line react-hooks/exhaustive-deps

  return filters;
};

export default useChannelFilters;
