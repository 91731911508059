import { Box, MenuItem, Select, Skeleton } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import nFormatter from "../utils/numberFormatter";
import VideoDisplay from "./VideoDisplay";

const colors = [
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#3366cc",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
  "#668d1c",
  "#bea413",
  "#0c5922",
  "#743411",
];

const PerformanceInsight = ({ insights, loading }) => {
  const [selected, setSelected] = useState(null);
  const [minutes, setMinutes] = useState(48);
  const [tooltipData, setTooltipData] = useState(null);

  const xData = Array.from(Array(minutes).keys()).map((item) => item * 60);
  const yData = insights?.chart?.map(
    (insight) => insight?.chart?.views_chart_data
  );

  const renderCustomizedLabel = (props, i) => {
    const { x, y, index } = props;

    if (index === tooltipData?.tooltipIndex) {
      return (
        <g key={i}>
          <text
            x={x}
            y={y - 15}
            fill={colors[i]}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {tooltipData?.y?.[i]}
          </text>
        </g>
      );
    } else {
      return null;
    }
  };

  const handleSelected = (value) => {
    if (selected === value) {
      setSelected(null);
    } else {
      setSelected(value);
    }
  };

  const handleMouseMove = (e) => {
    setTooltipData({
      x: e?.activeLabel,
      tooltipIndex: e?.activeTooltipIndex,
      y: e?.activePayload?.map((i) => i?.value?.toLocaleString()),
    });
  };

  const data = useMemo(() => {
    return xData
      ?.map((x, ii) => ({
        name: x,
        ...yData?.reduce((prev, y, i) => {
          return { ...prev, [`label${i}`]: y?.[ii] };
        }, {}),
      }))
      .filter((obj, i) => {
        return obj[`label${i}`] !== null;
      });
  }, [xData, yData]);

  return (
    <Box width="100%">
      {loading && (
        <Box height="600px" width="100%" borderRadius={2} overflow="hidden">
          <Skeleton variant="rectangular" height="100%" width="100%" />
        </Box>
      )}
      {!loading && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="end"
            marginRight={8}
          >
            <Select
              sx={{ height: 25 }}
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
            >
              <MenuItem value={6}>6 hours</MenuItem>
              <MenuItem value={12}>12 hours</MenuItem>
              <MenuItem value={24}>day</MenuItem>
              <MenuItem value={48}>2 days</MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              padding: 4,
              paddingRight: 0,
              borderRadius: 4,
              marginTop: 2,
              backgroundColor: "background.card",
            }}
          >
            <ResponsiveContainer width="100%" height={600}>
              <ComposedChart data={data} onMouseMove={handleMouseMove}>
                <XAxis
                  type="number"
                  scale="time"
                  domain={["auto", "dataMax"]}
                  tick={{ fontSize: "12px", strokeWidth: 1 }}
                  dataKey="name"
                  tickFormatter={(tick) => `${Math.round(tick / 60)}:00`}
                  style={{ userSelect: "none" }}
                />
                <YAxis
                  tickLine={false}
                  tickCount={10}
                  axisLine={false}
                  type="number"
                  domain={["auto", "auto"]}
                  tickFormatter={(tick) => nFormatter(tick)}
                  tick={{ fontSize: "12px" }}
                  orientation="right"
                  style={{ userSelect: "none" }}
                />
                <CartesianGrid vertical={false} stroke="rgb(235, 235, 235)" />
                {yData?.map((_, i) => (
                  <Area
                    isAnimationActive={false}
                    strokeWidth={selected === i ? 4 : 3}
                    strokeOpacity={selected === i ? 1 : 0.5}
                    stroke={colors[i]}
                    fillOpacity={0}
                    dataKey={`label${i}`}
                  >
                    <LabelList
                      dataKey="name"
                      content={(a) => renderCustomizedLabel(a, i)}
                    />
                  </Area>
                ))}
                <Legend
                  formatter={(_, _1, i) => (
                    <div
                      style={{
                        // underline the text if selected
                        textDecoration: selected === i ? "underline" : "none",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelected(i)}
                    >
                      {insights?.keys[i]}
                    </div>
                  )}
                  verticalAlign="top"
                  height={90}
                />
                <ReferenceLine x={tooltipData?.x} strokeDasharray="3 3" />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </>
      )}
      {!loading && selected !== null && (
        <Box marginTop={2} marginBottom={2}>
          <VideoDisplay
            limited
            videos={insights?.videos?.[selected]}
            loading={loading}
          />
        </Box>
      )}
    </Box>
  );
};

export default PerformanceInsight;
