import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Button, Skeleton, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Area,
  Bar,
  ComposedChart,
  Label,
  LabelList,
  Legend,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import getImageUrl from "../utils/getImageUrl";
import nFormatter from "../utils/numberFormatter";
import ChangeIcon from "./ChangeIcon";

export const channelCompareColors = [
  "#FF7F50",
  "#7CFC00",
  "#778899",
  "#000000",
  "#D2B48C",
  "#E9967A",
  "#8A2BE2",
  "#EEE8AA",
  "#FFA07A",
  "#ADD8E6",
  "#FFE4C4",
  "#00FF00",
  "#922B3E",
  "#BEBD7F",
  "#412227",
  "#734222",
  "#3E3B32",
  "#2D572C",
  "#6D3F5B",
  "#316650",
  "#1B5583",
  "#ED760E",
  "#C35831",
  "#1E5945",
  "#7D7F7D",
  "#293133",
  "#EC7C26",
  "#CBD0CC",
  "#EA899A",
  "#4E3B31",
  "#8B8C7A",
  "#FAD201",
];

const ChannelCompareChart = ({ x, y, sum, loading, cumulative, showTotal }) => {
  const [tooltipData, setTooltipData] = useState(null);

  const maxValue = useMemo(() => {
    return Math.max(...(y?.map((t) => parseInt(t)).filter((t) => t) || [0]));
  }, [y]);

  const minValue = useMemo(() => {
    return Math.min(...(y?.map((t) => parseInt(t)).filter((t) => t) || [0]));
  }, [y]);

  const data = useMemo(() => {
    const min = !showTotal
      ? Math.min(...(y?.map((t) => parseInt(t)).filter((t) => t) || [0]))
      : 0;

    const dates = x?.[0]?.map((x) => Date.parse(x).valueOf());

    return dates
      ?.map((date, i) => {
        const obj = {
          name: date,
        };

        y?.forEach((y, j) => {
          obj[`label${j}`] =
            cumulative || j === 0
              ? parseInt(y?.[i]) - (cumulative ? min : 0)
              : parseInt(y?.[i]) - parseInt(y?.[i - 1]);
        });

        return obj;
      })
      .slice(1)
      .map((obj) => {
        if (!sum) return obj;
        const { name, ...rest } = obj;
        return {
          name,
          sum: Object.values(rest).reduce((a, b) => a + b, 0),
        };
      });
  }, [x, y, cumulative, showTotal]);

  const renderCustomizedLabel = (props, color, i, minValue, maxValue) => {
    const { x, y, index } = props;

    if (index === tooltipData?.tooltipIndex) {
      // get percentage y of value
      const percentage0 =
        ((parseInt(tooltipData?.y?.[0]) - minValue) / (maxValue - minValue)) *
        100;
      const percentage1 =
        ((parseInt(tooltipData?.y?.[1]) - minValue) / (maxValue - minValue)) *
        100;

      // difference between the two values
      const difference =
        tooltipData?.y?.[1] &&
        ((tooltipData?.y?.[0] / tooltipData?.y?.[1]) * 100 - 100)?.toFixed(2);

      const improvedY = cumulative
        ? Math.abs(percentage0 - percentage1) < 10
          ? y - 10 + i * 30
          : y - 10
        : y;

      if (!tooltipData?.y?.[i]) return null;
      return (
        <g>
          <text
            x={x}
            y={improvedY || 100}
            fill={color}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              textShadow:
                "1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff",
            }}
          >
            {tooltipData?.y?.[i]?.toLocaleString() === "NaN"
              ? "No data"
              : `${tooltipData?.y?.[i]?.toLocaleString()}${
                  difference && i === 0
                    ? ` (
                ${difference > 0 ? "+" : ""}
                    ${difference}%)`
                    : ``
                }`}
          </text>
        </g>
      );
    } else {
      return null;
    }
  };

  if (loading) {
    return (
      <Box
        width="100%"
        height="500px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius={1}
        overflow="hidden"
      >
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>
    );
  }

  if (y?.every((y) => y === 0)) {
    return (
      <Box
        // width="100%"
        height="200px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>
          No channel selected. Please select a channel to compare.
        </Typography>
        <Button onClick={() => window.location.reload()}>
          <RestartAltIcon />
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: 2,
        paddingRight: 3,
        borderRadius: 4,
        backgroundColor: "background.card",
      }}
    >
      {!!data?.length && (
        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart
            margin={{ top: 50, right: 50, left: 0, bottom: 20 }}
            data={data}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#4b54db" stopOpacity={1} />
                <stop offset="100%" stopColor="#4b54db" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="compareColorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#3EB277" stopOpacity={1} />
                <stop offset="100%" stopColor="#3EB277" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="compareColorUv2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#f09b4d" stopOpacity={1} />
                <stop offset="100%" stopColor="#f09b4d" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="compareColorUv3" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ff7ab8" stopOpacity={1} />
                <stop offset="100%" stopColor="#ff7ab8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              axisLine={{
                stroke: "#e0e0e0",
                strokeWidth: 1,
                strokeDasharray: "3 5",
              }}
              type="number"
              scale="time"
              domain={["auto", "dataMax"]}
              tick={{ fontSize: "12px", strokeWidth: 1 }}
              dataKey="name"
              tickFormatter={(tick) => {
                return format(new Date(tick), "dd-MM-yyy HH:mm");
              }}
              style={{ userSelect: "none" }}
            />
            <YAxis
              axisLine={{
                stroke: "#e0e0e0",
                strokeWidth: 1,
                strokeDasharray: "3 5",
              }}
              domain={cumulative ? ["dataMin", "auto"] : [0, "auto"]}
              tickFormatter={(tick) => nFormatter(tick)}
              tick={{ fontSize: "12px" }}
              orientation="left"
              style={{ userSelect: "none" }}
            />
            {sum && (
              <Bar
                radius={[15, 15, 0, 0]}
                maxBarSize={20}
                fill={"#4b54db"}
                dataKey={"sum"}
                width={2}
              >
                <LabelList
                  dataKey="name"
                  content={(a) =>
                    renderCustomizedLabel(a, "#4b54db", 0, minValue, maxValue)
                  }
                />
              </Bar>
            )}
            {!sum &&
              x?.map((x, i) => (
                <Bar
                  radius={[15, 15, 0, 0]}
                  maxBarSize={20}
                  fill={channelCompareColors?.[i] || "#4b54db"}
                  dataKey={`label${i}`}
                  width={2}
                >
                  <LabelList
                    dataKey="name"
                    content={(a) =>
                      renderCustomizedLabel(a, "#4b54db", 0, minValue, maxValue)
                    }
                  />
                </Bar>
              ))}
            <ReferenceLine x={tooltipData?.x}>
              <Label
                value={
                  tooltipData?.x
                    ? format(new Date(tooltipData?.x), "dd-MM-yyy HH:mm")
                    : ""
                }
                fill="black"
                position="insideBottom"
              />
            </ReferenceLine>
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

const renderInnerContent = ({ type, value }) => {
  if (["thumbnail_title", "video"].includes(type)) {
    return (
      <Box display="flex" flexDirection="column" maxWidth={150}>
        <img
          style={{ paddingBottom: 5, paddingTop: 5 }}
          alt="changed"
          width={150}
          height={(150 * 9) / 16}
          src={getImageUrl(value.thumbnail)}
        />
        {value.title}
      </Box>
    );
  } else if (type === "thumbnail") {
    return (
      <img alt="changed" width={120} height={80} src={getImageUrl(value)} />
    );
  } else if (type === "title") {
    return value;
  } else if (type === "status") {
    return value;
  }
};

const renderContent = ({ viewBox: { x, y } }, value) => {
  const d = 40;
  const r = d / 2;

  const transform = `translate(${x - r} ${y - d - 130})`;

  return (
    <Tooltip
      placement="top"
      title={renderInnerContent({ type: value.action, value: value.new_value })}
    >
      <g transform={transform}>
        <svg viewBox="0 0 20 20" width="40">
          <circle fill="transparent" cx="10" cy="10" r="10"></circle>
          <g transform="translate(4 0)" viewBox="0 0 20 10">
            <Link to={`/videos/${value.videoId}`}>
              <ChangeIcon action={value.action} small={true} />
            </Link>
          </g>
        </svg>
      </g>
    </Tooltip>
  );
};

export default ChannelCompareChart;
