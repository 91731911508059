import Box from "@mui/material/Box";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Navigation from "../navigation/Navigation";
import Landing from "../pages/Landing";
import Login from "../pages/Login";
import Register from "../pages/SignUp";
import AppBar from "./AppBar";
import Search from "../pages/Search";

const MainPane = () => {
  return (
    <Routes>
      <Route path="/search" element={<Search />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Register />} />
      {localStorage.getItem("accessToken") && (
        <Route path="/" element={<Navigate to="/channels" replace />} />
      )}
      <Route path="/" element={<Landing />} />
      <Route
        path="*"
        element={
          <Box display="flex">
            <AppBar />
            <Box
              backgroundColor="background.default"
              sx={{
                paddingLeft: "25px",
                paddingBottom: "25px",
                paddingRight: "25px",
                paddingTop: "100px",
                width: "100%",
              }}
            >
              <Navigation />
            </Box>
          </Box>
        }
      />
    </Routes>
  );
};

export default MainPane;
