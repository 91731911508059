import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allChannels } from "../redux/actions/channelActions";
import { getClassifications } from "../redux/actions/videoActions";
import {
  last12Hours,
  last2Hours,
  last5Hours,
  lastDay,
  lastHour,
  lastWeek,
} from "./timeRanges";
import { createStaticRanges } from "react-date-range";

const staticFilters = [
  {
    label: "Group by",
    value: "groupBy",
    type: "select",
    priority: 4,
    options: [
      { value: "release_time", label: "release time" },
      { value: "video_length", label: "video length" },
      { value: "comments_to_views_ratio", label: "comments to views ratio" },
      { value: "likes_to_views_ratio", label: "likes to views ratio" },
      { value: "words", label: "words" },
      { value: "time_ranges", label: "time ranges" },
    ],
  },
  {
    required: { groupBy: "video_length" },
    label: "Group by video length",
    value: "groupByVideoLength",
    type: "select",
    priority: 5,
    options: [
      { value: "1", label: "1 second" },
      { value: "2", label: "2 seconds" },
      { value: "5", label: "5 seconds" },
      { value: "10", label: "10 seconds" },
      { value: "30", label: "30 seconds" },
      { value: "60", label: "1 minute" },
    ],
  },
  {
    required: { groupBy: "time_ranges" },
    label: "Group by time ranges",
    value: "groupByTimeRanges",
    type: "select",
    priority: 5,
    options: [
      { value: "days", label: "days" },
      { value: "weeks", label: "weeks" },
      { value: "months", label: "months" },
    ],
  },
  {
    required: { groupBy: "release_time" },
    label: "Publish day time",
    value: "groupByTimeUnit",
    type: "select",
    priority: 5,
    options: [
      { value: "minutes", label: "minutes" },
      { value: "hours", label: "hours" },
      { value: "days", label: "days" },
      { value: "months", label: "months" },
      { value: "year", label: "year" },
      { value: "month_year", label: "month and year" },
    ],
  },
  {
    required: { groupBy: "words" },
    label: "Words",
    value: "groupByWords",
    type: "text",
    help: "Enter words separated by comma",
    priority: 5,
  },
  {
    label: "Exclude percentage",
    value: "excludePercentage",
    type: "select",
    options: [
      { value: 2, label: "2%" },
      { value: 5, label: "5%" },
      { value: 10, label: "10%" },
    ],
  },
  {
    label: "Exclude published under",
    value: "excludeDaysUnder",
    type: "select",
    options: [
      { value: 1, label: "1 day ago" },
      { value: 2, label: "2 days ago" },
      { value: 7, label: "1 week ago" },
      { value: 14, label: "2 weeks ago" },
      { value: 30, label: "1 month ago" },
      { value: 90, label: "3 month ago" },
    ],
  },
  {
    label: "Exclude published over",
    value: "excludeDaysOver",
    type: "select",
    options: [
      { value: 7, label: "1 week ago" },
      { value: 14, label: "2 weeks ago" },
      { value: 30, label: "1 month ago" },
      { value: 90, label: "3 month ago" },
      { value: 180, label: "6 month ago" },
      { value: 365, label: "1 year ago" },
    ],
  },
  {
    label: "Include num videos",
    value: "includeVideosNumber",
    type: "select",
    options: [
      { value: 5, label: "last 5 videos" },
      { value: 10, label: "last 10 videos" },
      { value: 25, label: "last 25 videos" },
      { value: 50, label: "last 50 videos" },
      { value: 100, label: "last 100 videos" },
    ],
  },
  {
    label: "Channel",
    value: "channelId",
    type: "select",
    priority: 1,
    options: [],
    required: true,
  },
  {
    label: "Include word",
    value: "includeWord",
    type: "text",
  },
  {
    label: "Include classification",
    value: "includeClassification",
    type: "select",
    priority: 10,
    options: [{ value: "topics", label: "topics" }],
  },
  {
    required: { includeClassification: true },
    label: "With value",
    value: "classificationValue",
    type: "text",
    priority: 11,
  },
  {
    label: "Exclude shorts",
    value: "excludeShorts",
    type: "boolean",
  },
  {
    label: "Exclude unlisted",
    value: "excludeUnlisted",
    type: "boolean",
  },
  {
    required: { data: "views_during_range" },
    label: "Range",
    value: "range",
    type: "dateRange",
    priority: 3,
    staticRanges: [
      ...createStaticRanges([
        {
          label: "Last hour",
          range: () => lastHour,
        },
        {
          label: "Last 2 hours",
          range: () => last2Hours,
        },
        {
          label: "Last 5 hours",
          range: () => last5Hours,
        },
        {
          label: "Last 12 hours",
          range: () => last12Hours,
        },
        {
          label: "Last day",
          range: () => lastDay,
        },
        {
          label: "Last week",
          range: () => lastWeek,
        },
      ]),
    ],
  },
];

const useInsightsFilters = (insight) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(staticFilters);

  const { channels, classifications } = useSelector((state) => ({
    channels: state.channel.allChannels.channels,
    classifications: state.video.classifications,
  }));

  useEffect(() => {
    dispatch(allChannels());
    dispatch(getClassifications());
  }, [dispatch]);

  useEffect(() => {
    if (channels) {
      const newFilters = [...filters];
      const channelFilter = newFilters.find((f) => f.value === "channelId");
      channelFilter.options = channels.map((c) => ({
        value: c.channel_id,
        label: c.name,
      }));
      setFilters(newFilters);
    }
  }, [channels]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (classifications) {
      const newFilters = [...filters];
      const classificationFilter = newFilters.find(
        (f) => f.value === "includeClassification"
      );
      classificationFilter.options = classifications.map((c) => ({
        value: c.classification,
        label: c.displayName,
      }));
      setFilters(newFilters);
    }
  }, [classifications]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (insight === "data_insight") {
      // get data and measure from query params using location
      const url = new URL(window.location.href);
      const data = url.searchParams.get("data");
      const measure = url.searchParams.get("measure");

      const dataFilters = [
        {
          required: true,
          label: "Data",
          value: "data",
          type: "select",
          defaultValue: data || "views",
          priority: 2,
          options: [
            { value: "views", label: "views" },
            { value: "views_during_range", label: "views during time range" },
            { value: "last_24_h_views", label: "last 24h views" },
            { value: "last_1_h_views", label: "last 1h views" },
            { value: "comments", label: "comments" },
            { value: "likes", label: "likes" },
            { value: "duration", label: "duration" },
          ],
        },
        {
          required: { data: true },
          label: "Measure",
          value: "measure",
          defaultValue: measure || "median",
          type: "select",
          priority: 3,
          options: [
            { value: "median", label: "median" },
            { value: "average", label: "average" },
            { value: "sum", label: "sum" },
            { value: "max", label: "max" },
            { value: "min", label: "min" },
          ],
        },
      ];
      setFilters([...filters, ...dataFilters]);
    } else {
      setFilters(
        filters.filter((f) => ["data", "measure"].indexOf(f.value) === -1)
      );
    }
  }, [insight]); // eslint-disable-line react-hooks/exhaustive-deps

  return filters;
};

export default useInsightsFilters;
