import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import VideoDisplay from "./VideoDisplay";

const viewNameMap = {
  views: "views",
  last_24_h_views: "views (last 24 hours)",
  last_1_h_views: "views (last hour)",
  comments: "comments",
  likes: "likes",
  duration: "seconds",
};

const formatNumber = (num, data) => {
  if (data === "duration") {
    // num is in seconds. Convert to mm:ss, minutes, seconds
    const minutes = Math.floor((num % 3600) / 60);
    const seconds = Math.floor(num % 60);
    return (
      <>
        <b>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </b>
      </>
    );
  }
  return (
    <>
      <b>{num?.toLocaleString()}</b> {viewNameMap[data]}
    </>
  );
};

const SingleViewsInsight = ({ data, insights, loading }) => {
  return (
    <>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {loading && (
          <Box
            width={800}
            maxWidth="100%"
            height={200}
            overflow="hidden"
            borderRadius={2}
            margin={10}
          >
            <Skeleton width="100%" height="100%" variant="rectangular" />
          </Box>
        )}
        <>
          {!loading && (
            <Box
              sx={{
                padding: 6,
                borderRadius: 4,
                backgroundColor: "background.card",
                margin: 10,
              }}
            >
              <Typography width="max-content" variant="h1">
                {formatNumber(insights?.dailyViews?.[0], data)}
              </Typography>
            </Box>
          )}
          <Box width="100%" marginTop={2} marginBottom={2}>
            <VideoDisplay videos={insights?.videos?.[0]} limited />
          </Box>
        </>
      </Box>
    </>
  );
};

export default SingleViewsInsight;
